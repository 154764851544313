import React from "react";
import RequestGridCard from "./RequestGridCard";

const App = (props: any) => {
  return props?.request
    ? props?.data?.map((d: any) => <RequestGridCard data={d} />)
    : props?.data?.map((d: any) => {
        return (
          <RequestGridCard data={d} limit={props?.limit} page={props?.page} />
        );
      });
};

export default App;
