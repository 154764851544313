import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Terms and Conditions States",
  initialState: {
    isFetching: false,
    all: { data: [] }
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const myTermsAndConditionsActions = mySlice.actions;

export default mySlice.reducer;
