import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const searchCartItemBySerialNumber = async (
  token: any,
  serialNumber: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem/${serialNumber}`, token);
};

export const requestRepair = async (token: any, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/repair/`, data, token);
};

export const getAllRepairsService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair${query}`, token);
};

export const getRepairService = async (itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair/any/${itemId}`);
};

export const getOneRepairService = async (itemId: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair/one/${itemId}`, token);
};

export const updateOneRepairService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(`${SERVER_URL}/repair/one/${itemId}`, data, token);
};
