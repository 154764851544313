import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
import POSStockTable from "../../../components/tables/POSStockTable";

const POSStock = () => {
  return (
    <div className="flex flex-col text-black p-1 space-y-4 h-full">
      <ProductFilter />
      <POSStockTable />
    </div>
  );
};

export default POSStock;
