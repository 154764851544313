import React from "react";
import ProductCardGridPOS from "./ProductCardGridPOS";
import { useSelector } from "react-redux";

const App = (props: any) => {
  const { product } = useSelector((state: any) => state);

  return props?.data?.map((d: any) => (
    <ProductCardGridPOS
      data={d}
      handleSelected={props?.handleSelected}
      types={product?.attributes?.types}
    />
  ));
};

export default App;
