import React from "react";
import { Input } from "antd";

const SearchInput = (props: any) => {
  return (
    <Input.Search
      placeholder="input search text"
      onSearch={props?.onSearch}
      onChange={props?.onChange}
      // enterButton
      style={{ width: 250 }}
    />
  );
};

export default SearchInput;
