import { Divider, Tag } from "antd";
import React from "react";
import { searchValue } from "../../../utils/setColor";
import { useNavigate } from "react-router";

const RepairCard: React.FC<{ repairRequest: any }> = ({ repairRequest }) => {
  const navigate = useNavigate();

  const datetime = new Date(repairRequest?.createdAt);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1; // Add 1 to get the correct month (January is 0)
  const day = datetime.getDate();
  const dateOnly = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  return (
    <div
      onClick={() => navigate(`/repair?repairItem=${repairRequest?._id}`)}
      className="relative flex-grow shadow xsm:max-w-[430px] xsm:min-w-[390px] bg-white rounded-lg p-4 text-black space-y-3 h-48"
    >
      <div className="flex flex-col">
        <span className="text-gray-400 text-lg font-[400]">
          {repairRequest?.cartItemId?.priceProduct?.product?.model}
        </span>
        <span className="text-gray-400 text-sm">
          {repairRequest?.cartItemId?.warehouseProduct?.serialNumber}
        </span>
      </div>
      <Divider className="!m-1 !p-0" />

      <div className="flex justify-between">
        <div className="flex gap-x-2 space-x-2s">
          {repairRequest?.icon && (
            <div className="flex w-fit h-fit p-3 rounded-md bg-[#0000FF]">
              {repairRequest?.icon}
            </div>
          )}
          <div className="flex flex-col flex-1">
            <span className="">{repairRequest?.repairedBy?.names ?? "-"}</span>
          </div>
        </div>
        {/* <div className="flex items-center w-fit min-w-[70px] min-h-[30px] text-lg text-[#0FA958] rounded-xl px-2">
          <ArrowUpwardOutlinedIcon className="" />
          {"12.4%"}
        </div> */}
      </div>
      <p className="absolute bottom-2.5 right-6 text-[#6F767E]">{dateOnly}</p>
      <div className="absolute right-2 top-1">
        <Tag
          color={searchValue(repairRequest?.status)}
          className="capitalize min-w-[50px] text-center"
        >
          {repairRequest?.status}
        </Tag>
      </div>
    </div>
  );
};

export default RepairCard;
