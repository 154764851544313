import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import { listManages } from "../../assets/data/pages";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";
import ProfileButton from "./ProfileButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { authActions } from "../../store/auth";
import { myShopActions } from "../../store/shop";
import SwitchShopButton from "./SwitchShopButton";
import { getActiveShop } from "../../utils/converter";
import { myAppSettings } from "../../store/appSettings";

const IconMenu: React.FC<{
  selected?: number;
  isOpen?: boolean;
  setIsOpen?: (sate: boolean) => void;
}> = ({ selected }) => {
  const { auth } = useSelector((state: any) => state);
  const [selectedTabIndex, setSelectedTabIndex] = useState(selected);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasMoreShops = getActiveShop(auth?.user?.shop?.assigned)?.length > 1;

  return (
    <div
      className={`flex flex-col justify-between items-center bg-white h-full  pt-4 rounded-tr-lg rounded-br-lg z-50`}
    >
      <div className="flex flex-col items-center">
        {listManages.map((manage: any, index: number) => (
          <>
            <li
              className={`flex justify-between items-center border rounded-lg mt-2 w-full mx-2 ${
                selectedTabIndex === index ? "bg-white" : ""
              } hover:text-blue-500 hover:bg-white space-x-5`}
              onClick={() => {
                setSelectedTabIndex(index);
                navigate(manage.path);
              }}
            >
              <div
                className={`SideNav flex flex-col items-center justify-center w-full text-xs py-2 text-[#3f3f6c] ${
                  selectedTabIndex === index ? "text-blue-600" : "text-gray-500"
                }`}
              >
                {manage.icon}
              </div>
            </li>
          </>
        ))}
      </div>
      <div className="flex flex-col items-center space-y-2">
        <Divider className="!m-0" />
        {hasMoreShops && <SwitchShopButton />}
        <Divider className="!m-0" />
        <ProfileButton />
        <Divider className="!m-0" />
        <div
          className="py-2"
          onClick={() => {
            dispatch(authActions.logout());
            dispatch(myShopActions.setMyShop(null));
            dispatch(myAppSettings.setSelectedShopNumber(0));
            localStorage.removeItem("selectedShopIndex");
          }}
        >
          <ListItemIcon
            className={`SideNav flex flex-col items-center text-xs `}
          >
            <PowerSettingsNewIcon className="SamIcon" />
            {"Logout"}
          </ListItemIcon>
        </div>
      </div>
    </div>
  );
};

export default IconMenu;
