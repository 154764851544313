import React from "react";
import SamphonePayments from "../../../components/dashboard/Payments/SamphonePayments";
import {
  calculatePaymentTotalRevenue,
  calculatePercentageChange,
} from "../../../utils/converter";
import { useSelector } from "react-redux";

const data02 = [
  {
    name: "Momo Pay",
    value: 10,
    yesterday: 235543,
    today: 54332353,
    percentage: 4.6,
  },
  {
    name: "Bank Deposit",
    value: 30,
    yesterday: 5431212,
    today: 456729,
    percentage: 4.6,
  },
  {
    name: "POS",
    value: 8,
    yesterday: 56324,
    today: 2334542,
    percentage: 4.6,
  },
];
const PaymentMode = (props: any) => {
  const { posOverView } = useSelector((state: any) => state);

  const currentTotalRevenue = calculatePaymentTotalRevenue(
    posOverView?.samphonePaymentStats?.data,
    "currentDate"
  );

  const samphonePayment = posOverView?.samphonePaymentStats?.data?.map(
    (item: any) => {
      return {
        name:
          item?.mode === "Samphone_POS"
            ? "POS"
            : item?.mode === "Samphone_Bank_Deposit"
            ? "Bank Deposit"
            : item?.mode?.split("_")?.join(" "),
        today: parseInt(item?.revenue?.currentDate?.revenueTotal),
        yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
        value:
          currentTotalRevenue !== 0 &&
          (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
            currentTotalRevenue) *
            100,
        percentage: calculatePercentageChange(
          item?.revenue?.currentDate?.revenueTotal,
          item?.revenue?.comparedDate?.revenueTotal
        ),
      };
    }
  );

  const otherPayment = posOverView?.otherPaymentStats?.data?.map(
    (item: any) => {
      return {
        name: item?.mode,
        today: parseInt(item?.revenue?.currentDate?.revenueTotal),
        yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
        value:
          currentTotalRevenue !== 0 &&
          (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
            currentTotalRevenue) *
            100,
        percentage: calculatePercentageChange(
          item?.revenue?.currentDate?.revenueTotal,
          item?.revenue?.comparedDate?.revenueTotal
        ),
      };
    }
  );

  const mtnPayment = posOverView?.mtnPaymentStats?.data?.map((item: any) => {
    return {
      name:
        item?.mode === "MTN_POS"
          ? "POS"
          : item?.mode === "MTN_Bank_Deposit"
          ? "Bank Deposit"
          : "Requisition",
      today: parseInt(item?.revenue?.currentDate?.revenueTotal),
      yesterday: parseInt(item?.revenue?.comparedDate?.revenueTotal),
      value:
        currentTotalRevenue !== 0 &&
        (parseFloat(item?.revenue?.currentDate?.revenueTotal) /
          currentTotalRevenue) *
          100,
      percentage: calculatePercentageChange(
        item?.revenue?.currentDate?.revenueTotal,
        item?.revenue?.comparedDate?.revenueTotal
      ),
    };
  });

  return (
    <div className="flex flex-col xl:h-1/2 bg-white bg-opacity-50 shadow rounded-md pr-2 space-y-1">
      <h3 className="font-semibold text-lg pt-2 pl-4">Methods Of Payments</h3>
      <div className="cflex bgf-red-400 dflex-wrap grid gap-1 grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 overflow-y-scroll overflow-x-hidden justify-between h-full bg-white rounded-md p-42 divide-y lg:divide-y-0 lg:divide-x">
        <SamphonePayments
          title="MTN Payments"
          OverAllPercentage={50}
          amount={599908}
          yesterdayAmount={400530}
          data={mtnPayment}
          pieces={3}
          yesterdayPieces={2}
        />
        <SamphonePayments
          title="Samphone Payments"
          OverAllPercentage={53}
          amount={599908}
          yesterdayAmount={400530}
          data={samphonePayment}
          pieces={3}
          yesterdayPieces={2}
        />
        <SamphonePayments
          title="Other Payments"
          OverAllPercentage={23}
          amount={599908}
          yesterdayAmount={400530}
          data={otherPayment}
          pieces={3}
          yesterdayPieces={2}
        />
      </div>
    </div>
  );
};

export default PaymentMode;
