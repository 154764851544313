import * as React from "react";
// import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { authActions } from "../../store/auth";
import { useSelector, useDispatch } from "react-redux";
import { myShopActions } from "../../store/shop";
import { Avatar, Form, Input, Modal } from "antd";
import {
  getAgentsAssignedToShop,
  loginAction,
} from "../../store/auth/authActions";
import { getActiveShop } from "../../utils/converter";
import { myAppSettings } from "../../store/appSettings";

const propsObj = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

export default function AccountMenu(props: any) {
  const dispatch = useDispatch();
  const { auth, appSettings } = useSelector((state: any) => state);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [selectedAgent, setSelectedAgent] = React.useState<any>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [form] = Form.useForm();

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
    setAnchorElNotification(null);
  };
  const onFinish = async () => {
    auth?.token && (await loginAction({ email, password })(dispatch));
    setShowPasswordModal(false);
  };

  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  React.useEffect(() => {
    auth?.token &&
      shopId &&
      getAgentsAssignedToShop(auth?.token, shopId)(dispatch);
  }, [
    appSettings.selectedShopNumber,
    auth?.token,
    auth.user.shop,
    dispatch,
    shopId,
  ]);

  return (
    <React.Fragment>
      <Modal
        title="Enter Password "
        open={showPasswordModal}
        onCancel={() => {
          setShowPasswordModal(false);
        }}
        onOk={onFinish}
        okButtonProps={{ loading: auth?.isFetching, className: "bg-blue-500" }}
        okText="Switch"
        cancelButtonProps={{ loading: auth?.isFetching }}
      >
        <div className="flex flex-col items-center w-full mb-5">
          <img
            src={selectedAgent?.user?.picture}
            className="w-36 rounded-full"
            alt=""
          />
          <p className="text-xl font-semibold">{selectedAgent?.user?.names}</p>
        </div>
        <Form
          form={form}
          name="register"
          initialValues={props.data || {}}
          scrollToFirstError
          layout="vertical"
          className="text-center "
        >
          <Form.Item
            name="password"
            tooltip="Please enter  correct password"
            rules={[
              {
                required: true,
                message: "Channel is required!",
                whitespace: true,
              },
            ]}
          >
            <Input
              onChange={(e: any) => setPassword(e?.target?.value)}
              placeholder="Input password here"
              className="max-w-[300px]"
              type="password"
            />
          </Form.Item>
        </Form>
      </Modal>
      <div className="flex flex-col items-center">
        <Avatar.Group
          maxCount={1}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {auth?.agents
            ?.filter((agent: any) => agent?.user?._id !== auth?.user?._id)
            .map((agent: any) => (
              <Tooltip title={agent?.user?.names} placement="top">
                <Avatar
                  src={agent?.user?.picture}
                  key={agent?.user?.email}
                  onClick={(e: any) => {
                    setEmail(agent?.user?.email);
                    setShowPasswordModal(true);
                    setSelectedAgent(agent);
                  }}
                >
                  {agent?.user?.names?.charAt(0)}
                </Avatar>
              </Tooltip>
            ))}
        </Avatar.Group>
        <p className="text-[10px]">Switch User</p>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={propsObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={props?.handleFullScreenClick}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(authActions.logout());
            dispatch(myShopActions.setMyShop(null));
            dispatch(myAppSettings.setSelectedShopNumber(0));
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
