import React, { useEffect } from "react";
import { useMediaQuery, Stack } from "@mui/material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllPosts,
  getCategories,
  getPricelist,
  getAgents,
} from "../redux/actions";
import NoSelectedImage from "../assets/images/knowledge.png";
import Binoculars from "../assets/images/Binoculars.jpg";
import NoImageOnPost from "../assets/images/NoImageOnPost.jpg";
import NetworkIcon from "../assets/images/Network.png";
import { Menu, Carousel, Skeleton } from "antd";
import { KnowledgeActions } from "../redux";
import {
  Battery,
  Calendar,
  Camera,
  Os,
  Ram,
  Rom,
  ScreenSize,
  Thickness,
} from "../assets/icons";

const KnowledgeHome = () => {
  const { knowledge } = useSelector((state: any) => state);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width: 960px)");
  const [searchKey, setSearchKey] = useState("");

  return (
    <div className={`${!isMobile && "ml-80 mr-10"}`}>
      <div
        className={`px-6 py-10 mx-auto flex flex-col ${
          !knowledge?.activeSubCategory ||
          (knowledge?.pageType === "knowledge" &&
            knowledge?.allPosts?.length === 0) ||
          (knowledge?.pageType === "agents" &&
            knowledge?.allAgents?.length === 0) ||
          (knowledge?.pageType === "pricelist" &&
            knowledge?.allPricelist?.length === 0)
            ? "justify-center items-center"
            : ""
        } `}
      >
        {!knowledge?.activeSubCategory ? (
          <>
            <img className="h-[50%] w-[50%]" src={NoSelectedImage} alt="" />
            <p className="text-2xl font-semibold text-gray-700 mt-6">
              Select a topic to view content
            </p>
          </>
        ) : (
          <div className="flex flex-row justify-between items-center w-[100%]">
            <h1 className="text-center">{knowledge?.listTitle}</h1>
            <div className="flex flex-row justify-between items-center border-2 border-gray-300 bg-white h-10 pl-4 pr-6 rounded-lg text-sm focus:outline-none">
              <input
                className="text-sm focus:outline-none bg-white h-9"
                type="search"
                name="search"
                placeholder="Search"
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <button type="submit" className="mr-1">
                <svg
                  className="text-gray-600 h-4 w-4 fill-current"
                  x="0px"
                  y="0px"
                  viewBox="0 0 56.966 56.966"
                  width="512px"
                  height="512px"
                >
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                </svg>
              </button>
            </div>
          </div>
        )}

        {/* Page Type: Knowledge  */}

        {knowledge?.pageType === "knowledge" &&
          knowledge?.allPosts?.length === 0 &&
          knowledge?.activeSubCategory &&
          !knowledge?.isFetching && (
            <>
              <img src={Binoculars} className="h-[50%] w-[50%]" alt="" />
              <p className="text-2xl font-semibold text-gray-700 mt-6">
                No content found
              </p>
            </>
          )}
        {knowledge?.pageType === "knowledge" &&
          knowledge?.allPosts
            ?.filter((item: any) =>
              item?.title?.toLowerCase()?.includes(searchKey)
            )
            ?.map((post: any, index: number) => (
              <div
                className={`mt-8 lg:-mx-6 lg:flex lg:items-center pb-3 ${
                  index === 0 ? "" : "border-t-gray-500 border-t-2 pt-8"
                }`}
              >
                <img
                  className="object-cover w-50 lg:mx-6 lg:min-w-100 rounded-xl h-72 lg:h-60 border "
                  src={post?.picture || NoImageOnPost}
                  alt=""
                />
                <div className="mt-6 lg:mt-0 lg:mx-6">
                  <NavLink
                    to={`/knowledge/view/${post?._id}`}
                    className="block mt-4 text-2xl font-semibold text-gray-800 hover:underline md:text-3xl"
                  >
                    {post?.title}
                  </NavLink>

                  <p className="mt-3 text-sm text-gray-500 md:text-sm">
                    {post?.description.substring(0, 200)}
                    {post?.description?.length > 200 && "..."}
                  </p>

                  <NavLink
                    to={`/knowledge/view/${post?._id}`}
                    className="inline-block mt-2 text-blue-500 underline hover:text-blue-400"
                  >
                    Read more
                  </NavLink>

                  <div className="flex items-center mt-6">
                    <img
                      className="object-cover object-center w-10 h-10 rounded-full"
                      src={post?.createdBy?.picture}
                      alt=""
                    />
                    <div className="mx-4">
                      <h1 className="text-sm text-gray-700 ">
                        {post?.createdBy?.names}
                      </h1>
                      <p className="text-sm capitalize text-gray-500 ">
                        {post?.createdBy?.role}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

        {knowledge?.pageType === "knowledge" && knowledge?.isFetching && (
          <>
            <div className={`mt-6 lg:-mx-6 lg:flex lg:items-center pb-3 pt-8`}>
              <Skeleton.Avatar
                size={250}
                shape="square"
                active
                className="object-cover w-50 lg:mx-6 lg:min-w-100 rounded-xl h-72 lg:h-60"
              />
              <div className="mt-6 lg:mt-0 lg:mx-6">
                <Skeleton
                  title={true}
                  paragraph={true}
                  active
                  className="mt-4 sm:w-[10rem] lg:w-[50rem]"
                />
                {!isMobile && (
                  <div className="flex items-center mt-6">
                    <Skeleton.Avatar
                      className="object-cover object-center w-10 h-10 rounded-full"
                      active
                    />
                    <div className="mx-4">
                      <Skeleton.Input className="text-sm text-gray-700 " />
                      <Skeleton.Input className="text-sm capitalize text-gray-500 " />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {/* Page Type: Pricelist  */}
        <div className="flex flex-row self-center justify-between flex-wrap lg:w-[65rem] gap-5">
          {knowledge?.pageType === "pricelist" &&
            knowledge?.allPricelist
              ?.filter((item: any) =>
                item?.product?.model?.toLowerCase()?.includes(searchKey)
              )
              .map((item: any) => (
                <div className="flex flex-col lg:w-[30rem] pb-3 mt-4 border-2 border-gray-800 rounded-lg">
                  <div className="flex flex-row justify-between bg-gray-800 p-2">
                    <h1 className="text-white text-xl font-semibold">
                      {item?.product?.model}
                    </h1>
                    <div className="flex flex-row justify-around items-center">
                      <img
                        className="object-cover object-center w-6 h-6 hidden"
                        src={NetworkIcon}
                        alt=""
                      />
                      <h1 className="text-white text-xl font-semibold ml-2 hidden">
                        5G
                      </h1>
                    </div>
                    <div className="flex flex-row justify-around items-center">
                      <h1 className="text-white text-xl font-semibold ml-2">
                        RWF{" "}
                        {item?.prices
                          ?.find((price: any) => price.isActive)
                          ?.value?.toLocaleString()}
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between pl-2 p-3 mt-2">
                    <div className="flex flex-row gap-5">
                      <div className="flex flex-col">
                        <Carousel autoplay className="w-[12rem]">
                          {item?.product?.images?.length > 0 &&
                            item?.product?.images.map(
                              (image: any, key: number) => {
                                console.log(image, "image");
                                return (
                                  <div>
                                    <img
                                      src={image}
                                      className="w-60 h-60 object-cover object-center"
                                    />
                                  </div>
                                );
                              }
                            )}
                        </Carousel>
                      </div>
                      <div className="flex flex-col justify-between">
                        <div className=" flex-col gap-2 hidden">
                          <div className="flex flex-row items-center">
                            <img src={Calendar} className="w-5 h-4" />
                            <p className="text-sm text-gray-800 ml-1">
                              Released 2022, February 25
                            </p>
                          </div>
                          <div className="flex flex-row items-center">
                            <img src={Thickness} className="w-5 h-4" />
                            <p className="text-sm text-gray-800 ml-1">
                              8.9mm Thickness
                            </p>
                          </div>
                          <div className="flex flex-row items-center">
                            <img src={Os} className="w-5 h-4" />
                            <p className="text-sm text-gray-800 ml-1">
                              Android 13 One UI 5.1
                            </p>
                          </div>
                        </div>
                        <div className=" flex-row gap-6 hidden">
                          <div className="flex flex-col">
                            <img src={ScreenSize} className="w-8 h-8" />
                            <p className="text-xl text-gray-800 font-bold ml-1">
                              6.8"
                            </p>
                            <p className="text-sm text-gray-800 ml-1">
                              1440x3200
                            </p>
                          </div>
                          <div className="flex flex-col">
                            <img src={Camera} className="w-8 h-8" />
                            <p className="text-xl text-gray-800 font-bold ml-1">
                              108MP
                            </p>
                            <p className="text-sm text-gray-800 ml-1">4320p</p>
                          </div>
                          <div className="flex flex-col">
                            <img src={Battery} className="w-8 h-8" />
                            <p className="text-xl text-gray-800 font-bold ml-1">
                              5000mAh
                            </p>
                            <p className="text-sm text-gray-800 ml-1">Li-ion</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between pr-2 items-end">
                      <div className="flex flex-col gap-5">
                        {item &&
                          item?.specification?.map(
                            (data: any, _index: number) =>
                              data[1] &&
                              `${data[0]}`?.toLowerCase()?.includes("rom") ? (
                                <div className="flex flex-row items-center">
                                  <img src={Rom} className="w-10 h-8" />
                                  <p className="text-xl font-bold text-gray-800 ml-2">
                                    {`${data[1]}`}
                                  </p>
                                </div>
                              ) : (
                                `${data[0]}`
                                  ?.toLowerCase()
                                  ?.includes("ram") && (
                                  <div className="flex flex-row items-center">
                                    <img src={Ram} className="w-10 h-8" />
                                    <p className="text-xl font-bold text-gray-800 ml-2">
                                      {`${data[1]}`}
                                    </p>
                                  </div>
                                )
                              )
                          )}
                      </div>
                      <div className="flex flex-col gap-2 items-end">
                        <p className="text-xl font-bold text-[#52c41a] ml-2">
                          RWF{" "}
                          {item?.prices
                            ?.find((price: any) => price.isActive)
                            ?.value?.toLocaleString()}
                        </p>
                        <p className="text-m font-medium text-gray-800 ml-2">
                          Extendend Warranty:{" "}
                          {item?.extendedWarranty > 0
                            ? item?.extendedWarranty + "%"
                            : 0}
                        </p>
                        <p className="text-xl font-bold text-[#52c41a] ml-2">
                          RWF{" "}
                          {(
                            item?.prices?.find((price: any) => price.isActive)
                              ?.value +
                            item?.prices?.find((price: any) => price.isActive)
                              ?.value *
                              (item?.extendedWarranty / 100)
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 ml-4">
                    <div className="flex flex-row">
                      <p className="text-m font-bold text-gray-800 ml-1">
                        Colors:
                      </p>
                      {item &&
                        item?.product?.specs?.map(
                          (data: any, _index: number) => {
                            if (
                              data?.label?.toLowerCase()?.includes("colors")
                            ) {
                              return (
                                <div className="flex flex-row">
                                  <p className="text-m text-gray-800 ml-1">
                                    {data?.value?.join(", ")}
                                  </p>
                                </div>
                              );
                            }
                          }
                        )}
                    </div>
                  </div>
                  <div className="flex-row items-center mt-2 ml-20 gap-1 flex-wrap hidden">
                    <div className="w-5 h-5 bg-[#547548] rounded-full"></div>
                    <div className="w-5 h-5 bg-[#432322] rounded-full"></div>
                    <div className="w-5 h-5 bg-[#000000] rounded-full"></div>
                  </div>
                </div>
              ))}
        </div>

        {/* Page Type: Agent  */}

        {knowledge?.pageType === "agents" &&
          knowledge?.allAgents
            .map((item: any) => {
              return {
                ...item,
                agents: item?.agents?.filter((subitem: any) =>
                  subitem?.names.toLowerCase()?.includes(searchKey)
                ),
              };
            })
            .map((shop: any) => (
              <>
                <div className="flex flex-row flex-wrap w-[100%] mt-4">
                  {shop?.agents?.length > 0 && (
                    <div className="flex w-[100%] mt-4">
                      <h1 className="text-center uppercase mt-1 text-2xl font-semibold text-gray-800 md:text-3xl">
                        {shop?._id?.shopName}
                      </h1>
                    </div>
                  )}
                  <div className="flex flex-row flex-wrap w-[100%] mt-4">
                    {shop?.agents?.map((agent: any) => (
                      <div
                        className={
                          isMobile
                            ? "p-4 border border-gray-800 rounded-lg mb-3 w-[100%]"
                            : `mx-2 lg:flex py-3 mb-3 sm:p-6 md:p-6 lg:items-center lg:w-[34rem] border border-gray-800 rounded-lg`
                        }
                      >
                        <img
                          className="object-cover w-40 lg:mx-4 lg:min-w-65 rounded-full h-55 lg:h-55 border"
                          src={agent?.picture}
                          alt=""
                        />
                        <div className="mt-2 lg:w-1/2 lg:mt-0 lg:mx-1 ">
                          <h1 className="block mt-1 text-2xl font-semibold text-gray-800">
                            {agent?.names}
                          </h1>

                          <div className="flex flex-row gap-3 mt-2">
                            <h1 className="text-sm font-semibold text-gray-800 md:text-sm">
                              Role:
                            </h1>
                            <p className="text-sm text-gray-500 md:text-sm capitalize">
                              {agent?.role}
                            </p>
                          </div>
                          <div className="flex flex-row gap-3 mt-2">
                            <h1 className="text-sm font-semibold text-gray-800 md:text-sm">
                              Phone:
                            </h1>
                            <p className="text-sm text-gray-500 md:text-sm">
                              {agent?.phone}
                            </p>
                          </div>
                          <div className="flex flex-row gap-3 mt-2">
                            <h1 className="text-sm font-semibold text-gray-800 md:text-sm">
                              Email:
                            </h1>
                            <p className="text-sm text-gray-500 md:text-sm">
                              {agent?.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ))}
      </div>
    </div>
  );
};

export default KnowledgeHome;
