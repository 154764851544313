import { notification } from "antd";
import { myWareHouseActions } from ".";
import {
  addStockItemService,
  createShopStockCart,
  getAllDeliveryNoteService,
  getAllShopStock,
  getOneDeliveryNoteService,
  getShopStockCart,
  getStockStats,
  removeCartItem,
  searchCartItemBySerialNumber,
  updateOneDeliveryItemService,
  updateOneDeliveryNoteService,
  updateShopStockCart,
  updateShopStockCartDetails,
  updateShopStockCartItemDetails,
  updatewarehouseDataActivationService,
} from "./services";

export const AddOneStockItemAction = (
  token: string,
  delivNoteId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await addStockItemService(data, token);
      const resAll = await getOneDeliveryNoteService(token, delivNoteId);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        // dispatch(myWareHouseActions.setSelected(res));
        dispatch(myWareHouseActions.setSelectedDeliveryNote(resAll));
        dispatch(myWareHouseActions.setIsFetching(false));
        notification.success({ message: "Product Added Successfully." });
        return { check: true, data: res.data };
      } else {
        dispatch(myWareHouseActions.setIsFetching(false));
        return { check: false, data: res };
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: "Adding product failed." });
    }
  };
};

export const getAllDeliveryNoteAction = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getAllDeliveryNoteService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setAll(res));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneDeliveryNoteAction = (
  token: string,
  delivNoteId: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getOneDeliveryNoteService(token, delivNoteId);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setSelectedDeliveryNote(res));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message: "An error ocurred while fetching delivery note data.",
      });
    }
  };
};

export const updateOneDeliveryNoteAction = (
  token: string,
  delivNoteId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updateOneDeliveryNoteService(token, delivNoteId, data);
      const resAll = await getOneDeliveryNoteService(token, delivNoteId);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        // dispatch(myWareHouseActions.setSelected(res));
        dispatch(myWareHouseActions.setSelectedDeliveryNote(resAll));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
      notification.success({ message: "Delivery Note status updated." });
    } catch (err) {
      console.log(err);
      notification.error({ message: "updating delivery note status failed." });
    }
  };
};

export const updateOneDeliveryItemAction = (
  token: string,
  delivNoteItemId: string,
  delivNoteId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updateOneDeliveryItemService(
        token,
        delivNoteItemId,
        data
      );
      const resAll = await getOneDeliveryNoteService(token, delivNoteId);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        // dispatch(myWareHouseActions.setSelected(res));
        dispatch(myWareHouseActions.setSelectedDeliveryNote(resAll));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
      notification.success({ message: "Delivery item status updated." });
    } catch (err) {
      console.log(err);
      notification.error({ message: "updating delivery item status failed." });
    }
  };
};

export const getStockStatsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getStockStats(token, query);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setAllStockStats(res));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getStockStatsByIdAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getStockStats(token, query);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setAllStockProductStats(res));
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllShopStockAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getAllShopStock(token, query);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setSearchResult(res));
        dispatch(myWareHouseActions.setIsFetching(false));
        dispatch(myWareHouseActions.setSearchError({}));
      } else {
        dispatch(myWareHouseActions.setSearchError(res?.response));
        dispatch(myWareHouseActions.setSearchResult({}));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createShopStockCartAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await createShopStockCart(token, data);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 201) {
        dispatch(myWareHouseActions.setCreatedCart(res));
        dispatch(myWareHouseActions.setIsFetching(false));
        dispatch(myWareHouseActions.setIsCartCreated(true));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopStockCartAction = (token: string, cartId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await getShopStockCart(token, cartId);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setCreatedCart(res));
        dispatch(myWareHouseActions.setIsFetching(false));
        dispatch(myWareHouseActions.setIsCartCreated(false));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateShopStockCartAction = (
  token: string,
  cartId: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updateShopStockCart(token, cartId, data);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setCreatedCart(res));
        dispatch(myWareHouseActions.setIsFetching(false));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateShopStockCartDetailsAction = (
  token: string,
  cartId: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updateShopStockCartDetails(token, cartId, data);
      // console.log(">>>>>>>:: W/Out", res);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setCreatedCart(res));
        dispatch(myWareHouseActions.setIsFetching(false));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateShopStockCartItemDetailsAction = (
  token: string,
  cartItemId: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updateShopStockCartItemDetails(token, cartItemId, data);
      if (res?.status === 200) {
        const resCart = await getShopStockCart(token, res?.data?.cart?._id);
        dispatch(myWareHouseActions.setCreatedCart(resCart));
        dispatch(myWareHouseActions.setIsFetching(false));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const removeCartItemAction = (token: string, cartId: any, data: any) => {
//   return async (dispatch: any) => {
//     try {
//       dispatch(myWareHouseActions.setIsFetching(true));
//       const res = await removeCartItem(token, cartId, data);
//       if (res?.status === 200) {
//         const resCart = await getShopStockCart(token, cartId);
//         dispatch(myWareHouseActions.setCreatedCart(resCart));
//         dispatch(myWareHouseActions.setIsFetching(false));
//         // dispatch(myWareHouseActions.setSearchError({}))
//       }
//       dispatch(myWareHouseActions.setIsFetching(false));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

export const removeCartItemAction = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await removeCartItem(token, itemId);
      if (res?.status === 200) {
        dispatch(myWareHouseActions.setIsFetching(false));
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateWareHouseDetailsAction = (
  token: string,
  itemId: any,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myWareHouseActions.setIsFetching(true));
      const res = await updatewarehouseDataActivationService(
        token,
        itemId,
        data
      );
      if (res?.status === 200) {
        // const resCart = await getShopStockCart(token, res?.data?.cart?._id);
        // dispatch(myWareHouseActions.setCreatedCart(resCart));
        dispatch(myWareHouseActions.setIsFetching(false));
        // dispatch(myWareHouseActions.setSearchError({}))
      }
      dispatch(myWareHouseActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
