import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Steps } from "antd";
import { useSelector, useDispatch } from "react-redux";
import WitnessForm from "./subscriptionCustomerForms/WitnessForm";
import PersonnelDetailsForm from "./subscriptionCustomerForms/PersonnelDetailsForm";
import UploadDocs from "./subscriptionCustomerForms/UploadDocs";
import AddressDetailsForm from "./subscriptionCustomerForms/AddressDetailsForm";
import ConfirmPage from "./subscriptionCustomerForms/ConfirmPage";
import { handleNextStep } from "../../store/layout/actions";

const NewCustomerWithSubscription = (props: any) => {
  const dispatch = useDispatch();
  const { layout, wareHouse } = useSelector((state: any) => state);
  const [current, setCurrent] = useState(layout?.isNext);

  const steps = [
    {
      title: "Personnel Details",
      content: <PersonnelDetailsForm removeCustomer={props?.onCancel} />,
    },
    {
      title: "Address",
      content: <AddressDetailsForm removeCustomer={props?.onCancel} />,
    },
    {
      title: "Witness Info",
      content: <WitnessForm removeCustomer={props?.onCancel} />,
    },
    {
      title: "Documents",
      content: <UploadDocs removeCustomer={props?.onCancel} />,
    },
    {
      title: "Confirm",
      content: <ConfirmPage removeCustomer={props?.onCancel} />,
    },
  ];

  const items = steps?.map((item) => ({ key: item.title, title: item.title }));

  const contentStyle: React.CSSProperties = {
    height: "38vh",
    marginTop: 2,
  };

  useEffect(() => {
    setCurrent(layout?.isNext);
  }, [layout?.isNext]);

  useEffect(() => {
    wareHouse?.createdCart?.data?.isSubscriptionSubmitted &&
      handleNextStep(3)(dispatch);
  }, [dispatch, wareHouse?.createdCart?.data?.isSubscriptionSubmitted]);

  return (
    <>
      <Steps current={current} items={items} className="border-b pb-5" />
      <div style={contentStyle}>{steps[current]?.content}</div>
    </>
  );
};

export default NewCustomerWithSubscription;
