import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getRevenueServices = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/stats/salesbyday${query}`, token);
};

export const getPaymentStats = async (
  data: any,
  token: string,
  query?: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/stats/payments${query}`,
    data,
    token
  );
};

export const overviewStatServices = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/overview/pos${query}`, token);
};
