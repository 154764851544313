import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createChannel = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/channel`, data, token);
};

export const createShop = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/shop`, data, token);
};

export const createAgent = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/agent`, data, token);
};

export const getAllChannel = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/channel?${query || ""}`, token);
};

export const getAllShop = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shop?${query || ""}`, token);
};

export const getAllAgentInChannel = async (token: string, itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign?channel=${itemId}`, token);
};

export const getAllAgent = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/assign?${query || ""}`, token);
};

export const assignAgentToShop = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/assign/agent/${itemId}`,
    data,
    token
  );
};

export const getOneChannel = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/channel/one/${id}`, token);
};

export const updateChannel = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/channel/one/${itemId}`,
    data,
    token
  );
};

export const updateShop = async (itemId: string, data: any, token: string) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shop/one/${itemId}`,
    data,
    token
  );
};

export const deleteAgentService = async (itemId: string, token: string) => {
  // return await HttpRequest.delete(`${SERVER_URL}/agent/one/${itemId}`, token);
  return await HttpRequest.delete(`${SERVER_URL}/user/one/${itemId}`, token);
};

export const deleteShop = async (itemId: string, data: any, token: string) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shop/one/${itemId}`,
    data,
    token
  );
};

export const updateServiceAgent = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/agent/one/${itemId}`,
    data,
    token
  );
};

// Prices
export const getPriceLists = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelist${query}`, token);
};
export const getPriceListItem = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
};
export const addPriceListItem = async (
  token: string,
  priceListId: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/pricelist/additem/${priceListId}`,
    data,
    token
  );
};

export const deletePriceListItem = async (
  token: string,
  priceListItemId: any
) => {
  return HttpRequest.delete(
    `${SERVER_URL}/pricelistitem/one/${priceListItemId}`,
    token
  );
};

export const updatePriceListItem = async (
  token: string,
  priceListItemId: any,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/pricelistitem/price/${priceListItemId}`,
    data,
    token
  );
};
export const createServiceRequest = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/stockreq`, data, token);
};
export const getAllServiceRequest = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/stockreq${query}`, token);
};

export const addOneItemServiceRequest = async (
  id: string,
  data: any,
  token: string
) => {
  return HttpRequest.update(
    `${SERVER_URL}/stockreq/additem/${id}`,
    data,
    token
  );
};

export const sendRequestService = async (
  id: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/stockreq/one/${id}`,
    data,
    token
  );
};

export const updateIsDeleteService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/channel/one/${itemId}`,
    data,
    token
  );
};

export const getProductsByReqItem = async (token: string, query: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem?${query || ""}`, token);
};

export const deleteStockRequestService = async (
  id: string,
  // data: any,
  token: string
) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/stockreq/one/${id}`,
    // data,
    token
  );
};

export const searchByAgentService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/user/users${query}`, token);
};

export const getPriceListsSearchService = async (token: string, query: any) => {
  return HttpRequest.get(
    `${SERVER_URL}/pricelistitem/searchPriceList${query}`,
    token
  );
};

export const getAllStocktoTransferService = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stockreqitem${query}`, token);
};

export const createTransferService = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/shopstock`, data, token);
};

export const getAllServiceStockOut = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstock${query}`, token);
};

export const getAllWarehouseOutItem = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem?${query}`, token);
};

export const updateStockOutService = async (
  itemId: any,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shopstock/additem/${itemId}`,
    data,
    token
  );
};

export const updateOneStockOutDetailsService = async (
  itemId: any,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    data,
    token
  );
};

export const updateStockReqItemService = async (
  itemId: any,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/stockreqitem/one/${itemId}`,
    data,
    token
  );
};
