import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PosTableView from "../../../../components/tables/pos/PosViewTableView";
import { getAllWarehouseOutAction } from "../../../../store/wareHouseOut/actions";
import DetailsHeaderActionBar from "../../../../components/cards/DetailsPage/DetailsHeaderActionBar";
const ViewSingleRecent = () => {
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { rfsId } = useParams();

  useEffect(() => {
    auth?.token &&
      getAllWarehouseOutAction(
        auth?.token,
        `?warehouseOutId=${rfsId}`
      )(dispatch);
  }, [auth?.token, dispatch, rfsId]);

  return (
    <>
      <div className="text-gray-900 mt-10">
        <DetailsHeaderActionBar pageName={"POS"} title={rfsId} />
      </div>

      <div className="p-5 mt-2 bg-white rounded-lg space-y-6 h-[750px] overflow-y-auto">
        <PosTableView />
      </div>
    </>
  );
};

export default ViewSingleRecent;
