import React from "react";
import { Line, LineChart, ResponsiveContainer } from "recharts";

const DataActivationAndTrafficCard: React.FC<{
  title: any;
  amount: number;
  chartData: any;
}> = ({ title, amount, chartData }) => {
  return (
    <div>
      <p className="capitalize">{title}</p>
      <span className="font-semibold text-lg">{amount}</span>
      <div className="h-5 w-28">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={300} height={100} data={chartData}>
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#2943D6"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DataActivationAndTrafficCard;
