import { myCustomerActions } from ".";
import {
  createServiceCustomer,
  getAllServiceCustomer,
  updateServiceCustomer,
  getOneSelectedService,
  getOneCustomerByQueryService,
  updateIsDeleteService,
  getCustomerPuchased,
  getAllRepairService,
} from "./services";
import { SessionExpired } from "../auth/AutoLogout";
import { notification } from "antd";

export const createCustomerAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await createServiceCustomer(data, token);

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myCustomerActions.setNew(res));
        dispatch(myCustomerActions.setIsFetching(false));
      } else if (res?.response?.status === 409) {
        console.log("In Here::::::::::");
        dispatch(myCustomerActions.setIsFetching(false));
        notification.warning({
          message: "Customer with this phone number already exists!",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAllServiceCustomer(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setAll(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));

      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneSelectedService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneCustomerByQueryAction = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getOneCustomerByQueryService(query, token);
      // console.log(">>>>!!!>>>::)) ", res?.data);
      if (!res?.data?.length)
        notification.warning({ message: "Customer not found!" });
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSearchResult(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCustomerAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));

      const res = await updateServiceCustomer(itemId, data, token);

      if (res?.status === 200) {
        dispatch(myCustomerActions.setUpdated(res));
        dispatch(myCustomerActions.setNew(res));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      // For Anauthorized Error
      if (res.name === "AxiosError") {
        return await SessionExpired(
          dispatch,
          res.response.statusText,
          res.response.data.error
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteCustomerAction = (
  itemId: string,
  token: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await updateIsDeleteService(itemId, data, token);

      // console.log("dispatching the action.....");
      // console.log(">>>>!!!>>>::deleted)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setSelected(res?.data));
        const resAll = await getAllServiceCustomer(token, "");
        dispatch(myCustomerActions.setAll(resAll));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const getCustomerPurchaseAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getCustomerPuchased(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setPurchased(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllCustomerRepairsAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myCustomerActions.setIsFetching(true));
      const res = await getAllRepairService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myCustomerActions.setCustomerRepair(res?.data));
        dispatch(myCustomerActions.setIsFetching(false));
      }
      dispatch(myCustomerActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
