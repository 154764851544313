import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const createServiceCustomer = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/customer`, data, token);
};
export const getAllServiceCustomer = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/customer${query}`, token);
};

export const getOneServiceCustomer = async (query: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/customer${query}`, token);
};

export const getOneSelectedService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/customer/one/${id}`, token);
};

export const getOneCustomerByQueryService = async (
  query: string,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/customer${query}`, token);
};

export const updateServiceCustomer = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/customer/one/${itemId}`,
    data,
    token
  );
};

export const deleteServiceCustomer = async (itemId: string, token: string) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/customer/one/${itemId}`,
    token
  );
};

export const updateIsDeleteService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/customer/one/${itemId}`,
    data,
    token
  );
};

export const getCustomerPuchased = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/cart${query}`, token);
};

export const getAllRepairService = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/repair/byCustomerId/${id}`, token);
};
