import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "layout",
  initialState: {
    paymentMode: null,
    isNext: 0,
    isDisabledMomo: false,
    isMissingRRAcode: false,
  },
  reducers: {
    setPaymentMode(state, action) {
      state.paymentMode = action.payload;
    },
    setIsNext(state, action) {
      state.isNext = action.payload;
    },
    setDisabledMomo(state, action) {
      state.isDisabledMomo = action.payload;
    },
    setIsMissingRRAcode(state, action) {
      state.isMissingRRAcode = action.payload;
    },
  },
});

export const myLayoutActions = mySlice.actions;

export default mySlice.reducer;
