import { notification } from "antd";
import { myPosActions } from ".";
import { myWareHouseActions } from "../wareHouse";
import {
  getAllServiceByTransactionId,
  checkoutCart,
  getAllCarts,
  getAllServiceStock,
  getAllServiceTransaction,
  deleteCart,
  getPriceLists,
  getAllServiceStockByStatus,
  getRecentPosService,
  createNosaleService,
  getAllNosaleService,
  deleteNosales,
  updateReceivedProductStatus,
  updateRRACodeService,
  getOnePriceListItem,
  createAccountService,
  generateFirstInvoiceService,
  customerSignatureService,
  dataActivationService,
  uploadContractService,
  payWithMomo,
  checkMomoStatusService,
  getShopCommission,
  getShopCommissionBalance,
  generateEBMInvoiceService,
  updateStockReceivedService,
} from "./services";
import { getShopStockCart } from "../wareHouse/services";

export const getAllTransactionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceTransaction(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setTransation(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStoclAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceStock(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setStock(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllStockActionByStatus = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceStockByStatus(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setStockByStatus(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopCommissionAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getShopCommission(token, query);
      if (res?.status === 200) {
        dispatch(myPosActions.setShopCommission(res));
        dispatch(myPosActions.setIsFetching(false));
        return true;
      }
      dispatch(myPosActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};
export const getShopCommissionBalanceAction = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getShopCommissionBalance(itemId, token);
      if (res?.status === 200) {
        dispatch(myPosActions.setCommisssionBalance(res));
        dispatch(myPosActions.setIsFetching(false));
        return true;
      } else if (res?.status === 404) {
        console.log("Shop doesn't have commission");
        return false;
      }
      dispatch(myPosActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllByTransactionIdAction = (token: string, itemId: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllServiceByTransactionId(itemId, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setSelectedTransation(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllCartsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllCarts(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setCarts(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// here again
export const checkoutCartAction = (
  token: string,
  cartId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await checkoutCart(token, cartId, data);
      // console.log(">>>>>>>:: ", res?.response);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setIsCartok(true));
        dispatch(myWareHouseActions.setCreatedCart({}));
        dispatch(myPosActions.setCheckoutResponseStatus(res?.response?.status));
        notification.success({ message: "Sold successfully!" });
        return true;
      } else if (res?.response?.status === 400) {
        dispatch(myPosActions.setIsCartok(false));
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setCheckoutResponseStatus(res?.response?.status));
        notification.error({ message: res?.response?.data?.error });
        return false;
      } else if (res?.response?.status === 409) {
        // console.log("here.........");
        dispatch(myPosActions.setIsCartok(false));
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setCheckoutResponseStatus(res?.response?.status));
        notification.warning({ message: res?.response?.data?.error });
        return false;
      }
      dispatch(myPosActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
      notification.error({
        message: "There was error while checking out the cart. Try again later",
      });
    }
  };
};

export const deleteCartAction = (token: string, cartId: any, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await deleteCart(token, cartId);
      if (res?.status === 200) {
        // const resCart = await getAllCarts(token, query);
        // dispatch(myPosActions.setCarts(resCart));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getPriceLists(token, query);
      // console.log(">>>>>>>:: channels", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setPriceList(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getRecentPosOrdersAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getRecentPosService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setRecent(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message: "something went wrong whle fetching recent activities",
      });
    }
  };
};

export const createNosaleAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await createNosaleService(data, token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myPosActions.setNew(res));
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "No Sale added successfully" });
      } else if (res?.response?.data?.status === 400) {
        notification.info({ message: res?.response?.data?.error });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getAllNosaleAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getAllNosaleService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setNosale(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteNosaleAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await deleteNosales(token, itemId, data);
      // console.log(" delete res.......>", res)
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "deleted Succesfully" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateReceivedProductAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updateReceivedProductStatus(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "well confirmed!" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateStockReceivedAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updateStockReceivedService(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateRRACodeActions = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await updateRRACodeService(token, itemId, data);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        notification.success({ message: "Saved Successfully !" });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOnePriceListItemAction = (token: string, itemId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await getOnePriceListItem(token, itemId);

      if (res?.status === 200) {
        dispatch(myPosActions.setOnePriceListItem(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createAccountAction = (token: string, cartId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await createAccountService(cartId, token);
      if (res?.status === 201) {
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const generateFirstInvoiceAction = (token: string, cartId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await generateFirstInvoiceService(cartId, token);
      if (res?.status === 201) {
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const customerSignatureAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await customerSignatureService(data, token);
      if (res?.status === 200) {
        dispatch(myPosActions.setCustomerSignature(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const customerProfileAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await customerSignatureService(data, token);
      if (res?.status === 200) {
        dispatch(myPosActions.setCustomerProfile(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const dataActivationction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await dataActivationService(data, token);
      if (res?.status === 201) {
        dispatch(myPosActions.setDataActivated(res));
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const payWithMoMoAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await payWithMomo(token, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setPaymentInitiationStatus(true));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const uploadContactction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await uploadContractService(token, itemId, data);
      if (res?.status === 200) {
        dispatch(myPosActions.setIsFetching(false));
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const checkPaymentAction = (
  token: string,
  cartId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await checkoutCart(token, cartId, data);

      // if (res?.status?.toLowerCase() === "pending") {
      //   dispatch(myPosActions.setCheckPaymentAgainHelper(Math.random()));
      // } else
      if (res?.status === 200) {
        dispatch(myPosActions.setPaymentDoneStatus(true));
      } else if (res?.res === 409) {
        notification.warning({ message: "Cart already paid!" });
        dispatch(myPosActions.setPaymentDoneStatus(true));
      } else if (res?.res === 204) {
        notification.warning({ message: "Cart already paid!" });
        dispatch(myPosActions.setPaymentDoneStatus(true));
      } else {
        notification.error({ message: "Payment failed..." });
      }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message: "There was error while checking out the cart. Try again later",
      });
    }
  };
};

export const resetPaymentRelatedStates = () => {
  return async (dispatch: any) => {
    dispatch(myPosActions.resetPaymentRelatedStatesService());
  };
};

export const checkPaymentStatusAction = (token: string, cartId: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      const res = await checkMomoStatusService(token, cartId);
      // console.log("payment status", res?.data?.status);
      if (res?.status === 200) {
        // dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setStatusResult(res));
      } else if (res?.status === 204) {
        dispatch(myPosActions.setStatusResult(res));
      } else if (res?.data?.status === "fail") {
        notification.error({ message: "Request has been cancelled" });
      }
      // if (res?.status === "PENDING") {
      //   dispatch(myPosActions.setCheckPaymentAgainHelper(Math.random()));
      // } else if (res?.status === 200) {
      //   dispatch(myPosActions.setPaymentDoneStatus(true));
      // } else {
      //   notification.error({ message: "Payment failed" });
      // }
      dispatch(myPosActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      notification.error({
        message: "There was error while checking out the cart. Try again later",
      });
    }
  };
};

export const generateEBMInvoiceAction = (
  token: string,
  data: any,
  itemId?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosActions.setIsFetching(true));
      dispatch(myPosActions.setEbmIsFetching(true));
      const res = await generateEBMInvoiceService(token, data);
      if (res?.status === 201) {
        const resCart = await getAllServiceByTransactionId(itemId, token);
        const resAll = await getShopStockCart(token, itemId);
        dispatch(myPosActions.setSelectedTransation(resCart));
        dispatch(myWareHouseActions.setCreatedCart(resAll));
        dispatch(myPosActions.setIsFetching(false));
        dispatch(myPosActions.setEbmIsFetching(false));
        dispatch(myPosActions.setCheckoutResponseStatus(res?.response?.status));
      }
      dispatch(myPosActions.setIsFetching(false));
      dispatch(myPosActions.setEbmIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
