import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "Knowledge",
  initialState: {
    isFetching: false,
    allPosts: [],
    allAgents: [],
    allPricelist: [],
    adminPosts: [],
    listTitle: "",
    pageType: "",
    singlePost: null,
    dataChanged: false,
    categories: [],
    singleCategory: null,
    activeCategory: null,
    activeSubCategory: null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllPosts(state, action) {
      state.allPosts = action.payload;
    },
    setAllAgents(state, action) {
      state.allAgents = action.payload;
    },
    setAllPricelist(state, action) {
      state.allPricelist = action.payload;
    },
    setAdminPosts(state, action) {
      state.adminPosts = action.payload;
    },
    setPageType(state, action) {
      state.pageType = action.payload;
    },
    setSinglePost(state, action) {
      state.singlePost = action.payload;
    },
    setSingleCategory(state, action) {
      state.singleCategory = action.payload;
    },
    setDataChanged(state, action) {
      state.dataChanged = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setActiveCategory(state, action) {
      state.activeCategory = action.payload;
    },
    setActiveSubCategory(state, action) {
      state.activeSubCategory = action.payload;
      state.allPosts = [];
      state.allAgents = [];
      state.allPricelist = [];
      state.singlePost = null;
    },
    setListTitle(state, action) {
      state.listTitle = action.payload;
    },
  },
});

export const KnowledgeActions = mySlice.actions;

export default mySlice.reducer;
