import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import NoImageOnPost from "../assets/images/NoImageOnPost.jpg";
import { getSinglePost } from "../redux/actions";
import moment from "moment";
import parse from "html-react-parser";
import { useMediaQuery } from "@mui/material";

const ViewKnowledgePost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth, knowledge } = useSelector((state: any) => state);
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (auth?.token && id) getSinglePost(auth?.token, id?.toString())(dispatch);
  }, [auth, id]);

  useEffect(() => {
    let result = localStorage.getItem("user");
    setUser(JSON.parse(result || "{}"));
  }, []);

  // take person to /knowledge if they are not in accessLevels
  useEffect(() => {
    if (knowledge?.singlePost && user) {
      if (
        knowledge?.singlePost.category.subCategory.filter(
          (subCategory: any) =>
            subCategory.name == knowledge?.singlePost.subCategory &&
            subCategory?.accessLevels?.includes(user?.role)
        ).length == 0
      ) {
        navigate("/knowledge");
      }
    }
  }, [knowledge.singlePost, user]);

  return (
    <div className={`flex flex-col ${isMobile ? "ml-5 mr-5" : "ml-80 mr-10"}`}>
      {knowledge.singlePost && (
        <div className="flex flex-col">
          <div>
            <h1 className="block mt-4 text-2xl font-semibold text-gray-800 py-6 md:text-3xl">
              {knowledge?.singlePost?.title}
            </h1>
            <div className="flex items-center mb-4">
              <img
                className="object-cover object-center w-10 h-10 rounded-full"
                src={knowledge?.singlePost?.createdBy?.picture}
                alt=""
              />

              <div className="mx-4">
                <h1 className="text-sm text-gray-700 ">
                  {knowledge?.singlePost?.createdBy?.names}
                </h1>
                <p className="text-sm text-gray-500 capitalize">
                  {knowledge?.singlePost?.createdBy?.role}
                </p>
              </div>
            </div>
            <div className="border-t-gray-300 border-t-[1px] border-b-gray-300 border-b-[1px] mb-6">
              <p className="text-gray-700 text-base py-2">
                {moment(knowledge?.singlePost?.createdAt).format(
                  "dddd, Do MMMM YYYY"
                )}
              </p>
            </div>
          </div>
          {knowledge?.singlePost?.picture && (
            <img
              className="object-cover lg:mx-6 rounded-xl h-[60vh]"
              src={knowledge?.singlePost?.picture || NoImageOnPost}
              alt=""
            />
          )}
          <p className="block mt-4 text-xl text-gray-800 pt-6">
            {knowledge?.singlePost?.description}
          </p>
          <div className="block mt-4 text-xl text-gray-800 py-5">
            {parse(knowledge?.singlePost?.content.toString())}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewKnowledgePost;
