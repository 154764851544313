import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { Select, Form, InputNumber, notification } from "antd";
import AddIcon from "@mui/icons-material/Add";
import PriceListFilter from "../../../../components/filters/PriceListFiltering";
import { getOneProductAction } from "../../../../store/product/actions";
import { myProductActions } from "../../../../store/product";
import {
  addPriceListItemAction,
  getPricelistItemAction,
} from "../../../../store/channel/actions";
import { getActivePrice } from "../../../../utils/converter";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PageView = (props: any) => {
  const [form] = Form.useForm();
  const { product, auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const selectedItemToUpdate = channel?.priceListItemToUpdate?.data;
  const initialValues = {
    product: channel?.priceListItemToUpdate?.data?._id,
    // specifications: ,
    // age: 30,
  };

  // console.log("[+] ", selectedItemToUpdate);

  const onFinish = async (values: any) => {
    console.log(values, channel?.priceList?.data[0]?._id);
    const val = {
      ...values,
      prices: [{ value: values.prices }],
      channel: channel?.selected?._id,
      specification: [
        ["Type", product?.selected?.type],
        ["Brand", product?.selected?.brand],
        ...Object.entries(values.specification),
      ],
    };

    if (selectedItemToUpdate) {
      console.log("update", values);
    } else {
      if (auth?.token && channel?.priceList?.data[0]?._id) {
        await addPriceListItemAction(
          auth?.token,
          channel?.priceList?.data[0]?._id,
          val,
          `?channel=${channel?.selected?._id}`
        )(dispatch);

        await getPricelistItemAction(
          auth?.token,
          `?channel=${channel?.selected?._id}`
        )(dispatch);
        props?.onCancel();
      } else {
        notification.error({ message: "Failed To Add!" });
      }
    }
  };

  const [selectedModelId, setSelectedModelId] = useState("");
  // console.log(
  //   "PLICe",
  //   selectedItemToUpdate?.prices,
  //   getActivePrice(selectedItemToUpdate?.prices)
  // );

  useEffect(() => {
    dispatch(myProductActions.setSelected(null));
    console.log(" after calling....");
    auth?.token &&
      selectedModelId &&
      getOneProductAction(auth?.token, selectedModelId)(dispatch);
    // }
  }, [auth?.token, dispatch, selectedModelId]);

  return (
    <Stack spacing={0}>
      <PriceListFilter />
      <Paper elevation={0}>
        <Stack spacing={0}>
          <Paper elevation={0} className="pl-4 pr-4 pt-2 pb-2">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={initialValues}
              style={{ maxWidth: "100%" }}
              scrollToFirstError
            >
              <Stack
                spacing={0}
                direction="row"
                justifyContent={"space-between"}
                alignItems={"flex-end"}
                // sx={{background:"yellow"}}
              >
                <Stack direction={"column"} spacing={0} alignItems="start">
                  <Form.Item
                    name="product"
                    label="Product Model"
                    tooltip="Please select modal of the product!"
                    rules={[
                      {
                        required: true,
                        message: "Model is required!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      allowClear
                      loading={product.isFetching}
                      disabled={product.isFetching}
                      style={{ width: 250 }}
                      defaultValue={
                        channel?.priceListItemToUpdate?.data?.product?.model
                      }
                      value={
                        (selectedModelId === "" ? null : selectedModelId) ??
                        channel?.priceListItemToUpdate?.data?.product?.model
                      }
                      placeholder="Select By Model"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").toString().includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toString()
                          .toLowerCase()
                          .localeCompare(
                            (optionB?.label ?? "").toString().toLowerCase()
                          )
                      }
                      // product?.all.data.map
                      options={product?.all.data.map((pro: any) => ({
                        label: `${pro?.model} ~  ${pro?.type}`,
                        value: pro._id,
                      }))}
                      onChange={(value: any) => {
                        console.log("::", value);
                        !value && setSelectedModelId("");
                        value && setSelectedModelId(value);
                        value && props.setSelectedModelId(value);
                      }}
                    />
                  </Form.Item>
                  <div className=" flex flex-col gap-1 ml-[7.5rem]">
                    {product?.selected &&
                      product?.selected?.specs.map(
                        (spec: any) =>
                          spec.value.length > 0 &&
                          !spec?.label?.toLowerCase().includes("color") && (
                            <Form.Item name={["specification", spec?.label]}>
                              {/* {console.log(spec)} */}
                              <Select
                                showSearch
                                allowClear
                                loading={product.isFetching}
                                disabled={product.isFetching}
                                value={spec?.value}
                                style={{ width: 200 }}
                                placeholder={`Select  ${spec?.label}`}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toString()
                                    .includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toString()
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "")
                                        .toString()
                                        .toLowerCase()
                                    )
                                }
                                options={spec?.value.map((pro: any) => ({
                                  label: pro,
                                  value: pro,
                                }))}
                                onChange={(value: any) => {
                                  console.log("::", value);
                                }}
                              />
                            </Form.Item>
                          )
                      )}
                    {/* </Form.List> */}
                  </div>
                  {channel?.priceListItemToUpdate && (
                    <div className=" flex flex-col gap-1 ml-[7.5rem]">
                      {product?.selected?.specs.map((spec: any) => (
                        // spec?.value?.length > 0 &&
                        <Form.Item name={["specification", spec[0]]}>
                          {/* {console.log(spec)} */}
                          <Select
                            showSearch
                            allowClear
                            loading={product.isFetching}
                            disabled={product.isFetching}
                            value={spec[1]}
                            defaultValue={spec[1]}
                            style={{ width: 200 }}
                            placeholder={`Select  ${spec[0]}`}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "").toString().includes(input)
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA?.label ?? "")
                                .toString()
                                .toLowerCase()
                                .localeCompare(
                                  (optionB?.label ?? "")
                                    .toString()
                                    .toLowerCase()
                                )
                            }
                            options={spec?.value?.map((pro: any) => ({
                              label: pro,
                              value: pro,
                            }))}
                            onChange={(value: any) => {
                              console.log("::", value);
                            }}
                          />
                        </Form.Item>
                      ))}
                      {/* </Form.List> */}
                    </div>
                  )}
                </Stack>
                <Stack direction={"column"} spacing={0}>
                  <Form.Item
                    name="prices"
                    label="Price"
                    tooltip="Please enter the price of the product you selected!"
                    initialValue={getActivePrice(selectedItemToUpdate?.prices)}
                    rules={[
                      {
                        required: true,
                        message: "Price is required!",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      // max={100000}
                      // value={getActivePrice(selectedItemToUpdate?.prices)}
                      style={{ width: "100%" }}
                      // onChange={onChange}
                    />
                  </Form.Item>
                  {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}

                  <LoadingButton
                    type="submit"
                    variant="contained"
                    // onClick={() => {
                    //   props?.onClickAdd();
                    // }}
                    startIcon={<AddIcon />}
                    sx={{ minWidth: "100%" }}
                    loading={channel?.isFetching}
                  >
                    {selectedItemToUpdate ? "Update" : "Save"}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
          </Paper>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default PageView;
