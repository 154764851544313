import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllServiceTransaction = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cartItem${query}`, token);
};

export const getAllServiceStock = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem/stock${query}`, token);
};
export const getShopCommission = async (token: string, query?: string) => {
  return await HttpRequest.get(
    `${SERVER_URL}/cartItem/commission/transaction/byShop${query}`,
    token
  );
};
export const getShopCommissionBalance = async (
  itemId: string,
  token: string
) => {
  return await HttpRequest.get(
    `${SERVER_URL}/commissionProfile/balance/${itemId}`,
    token
  );
};
export const getAllServiceStockByStatus = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem${query}`, token);
};

export const getAllServiceByTransactionId = async (
  itemId: any,
  token: string
) => {
  return HttpRequest.get(`${SERVER_URL}/cart/one/${itemId}`, token);
};
export const getAllCarts = async (token: any, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/cart${query}`, token);
};

export const checkoutCart = async (
  token: string,
  cartId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cart/checkout/${cartId}`,
    data,
    token
  );
};

export const deleteCart = async (token: any, cartId: string) => {
  return HttpRequest.delete(`${SERVER_URL}/cart/one/${cartId}`, token);
};

export const getPriceLists = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem${query}`, token);
};

export const getRecentPosService = async (token: string, query: any) => {
  return HttpRequest.get(`${SERVER_URL}/shopstock${query}`, token);
};

export const getRecentPosServicea = async (token: string) => {
  return HttpRequest.get(`${SERVER_URL}/purchase/ready/po`, token);
};

export const createNosaleService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/noSale`, data, token);
};

export const getAllNosaleService = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/noSale${query}`, token);
};

export const deleteNosales = async (
  token: string,
  itemId: string,
  data: any
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/noSale/one/${itemId}`,
    data,
    token
  );
};

export const updateReceivedProductStatus = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    data,
    token
  );
};

export const updateStockReceivedService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/stockreqitem/one/${itemId}`,
    data,
    token
  );
};

export const updateRRACodeService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/cartItem/one/${itemId}`,
    data,
    token
  );
};

export const getOnePriceListItem = async (token: any, itemId: string) => {
  return HttpRequest.get(`${SERVER_URL}/pricelistitem/one/${itemId}`, token);
};

export const createAccountService = async (cardId: string, token: string) => {
  return await HttpRequest.post(
    `${SERVER_URL}/account/cart/${cardId}`,
    cardId,
    token
  );
};

export const generateFirstInvoiceService = async (
  cardId: string,
  token: string
) => {
  return await HttpRequest.post(
    `${SERVER_URL}/invoice/cart/${cardId}`,
    cardId,
    token
  );
};

export const customerSignatureService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/product/upload`, data, token);
};

export const dataActivationService = async (data: any, token: string) => {
  return await HttpRequest.post(`${SERVER_URL}/data`, data, token);
};

export const uploadContractService = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(`${SERVER_URL}/account/one/${itemId}`, data, token);
};
export const payWithMomo = async (token: string, data: any) => {
  return HttpRequest.post(`${SERVER_URL}/pay/momo`, data, token);
};

export const checkMomoStatusService = async (token: string, cartId: any) => {
  return HttpRequest.get(`${SERVER_URL}/pay/momo/${cartId}`, token);
};

export const generateEBMInvoiceService = async (token: string, data: any) => {
  return await HttpRequest.post(`${SERVER_URL}/ebm/addSalesToEBM`, data, token);
};
