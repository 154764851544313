import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Modal, Select, Dropdown, Space } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  createCustomerAction,
  updateCustomerAction,
} from "../../../store/customer/actions";
import { handleNextStep } from "../../../store/layout/actions";
import UploadComponent from "../../forms/Upload";
import "./address.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";

import Webcam, { WebcamProps } from "react-webcam";
import { customerProfileAction } from "../../../store/pos/actions";
import { useMediaQuery } from "@mui/material";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const PersonnelDetailsForm = (props: any) => {
  const { wareHouse, customer, auth, pos } = useSelector((state: any) => state);
  const [customerProfile, setCustomerProfile] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState("250");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState<any | null>(null);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const NotTablet = useMediaQuery("(min-width: 1536px)");

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];
  const profile = wareHouse?.createdCart?.data?.customer?.picture;
  console.log("profile>>>>", profile);

  const onFinish = async (values: any) => {
    const name = `${values?.firstName}  ${values?.lastName}`;
    if (wareHouse?.createdCart?.data?.customer) {
      console.log("done");
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          ...values,
          name,
          picture: capturedImage
            ? pos?.customerProfile?.data?.secure_url ||
              (capturedImage && capturedImage[0]?.response?.data?.secure_url)
            : profile,
          subscriptionInfo: {
            address: address,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalId,
            MomoStatementDoc: [momoStatement],
          },
        }
      )(dispatch);
      await handleNextStep(0)(dispatch);
    } else {
      auth?.token &&
        (
          await createCustomerAction(auth?.token, {
            ...values,
            name,
            picture: capturedImage
              ? pos?.customerProfile?.data?.secure_url ||
                (capturedImage && capturedImage[0]?.response?.data?.secure_url)
              : profile,
          })
        )(dispatch);
      await handleNextStep(0)(dispatch);
      form.resetFields();
    }
  };
  console.log("capturedImage", capturedImage);
  console.log("customerProfile", pos?.customerProfile?.data?.secure_url);
  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer) {
      form.setFieldsValue(wareHouse?.createdCart?.data?.customer);
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer]);

  const initialData = {
    ...wareHouse?.createdCart?.data?.customer,
    firstName: wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0],
    lastName: wareHouse?.createdCart?.data?.customer?.name?.slice(
      wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]?.length
    ),
  };

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      if (imageSrc) {
        customerProfileAction(auth?.token, {
          image: imageSrc,
        })(dispatch);
      }
      setIsCameraOpen(false);
      closeCamera();
    }
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);

  const styles = {
    width: NotTablet ? "300px" : "200px",
  };

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("camera");
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handlerDropDown = (value: string) => {
    setSelectedOption(value);
    closeDropdown();
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        // style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="lg:max-2xl:pt-2 lg:max-2xl:h-56 h-full overflow-y-auto"
      >
        <div className=" rounded-lg ">
          <div className="grid grid-cols-3 ">
            <Form.Item
              name="firstName"
              className="lg:max-2xl:w-64"
              label={<span className="text-[#0F0F47] text-sm">First Name</span>}
              rules={[
                {
                  required: true,
                  message: "First Name is required!",
                  whitespace: true,
                },
              ]}
              getValueFromEvent={(e) =>
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
              }
              // className="text-[#0F0F47] "
            >
              <Input
                className="pl-4  h-[52px] w-[360px] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                placeholder="First Name"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              className="lg:max-2xl:w-64"
              label={<span className="text-[#0F0F47] text-sm">Last Name</span>}
              // initialValue={props?.dataToUpdate?.name
              //   ?.slice(firstNameToUpdate?.length)
              //   ?.trim()}
              rules={[
                {
                  required: true,
                  message: "Last Name is required!",
                  whitespace: true,
                },
              ]}
              getValueFromEvent={(e) =>
                e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
              }
            >
              <Input
                className="pl-4 h-[52px] w-[360px] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                placeholder="Last Name"
              />
            </Form.Item>
            <Form.Item
              name="maritalStatus"
              label={
                <span className="text-[#0F0F47] text-[12px] ">
                  Martial Status
                </span>
              }
              rules={[
                {
                  required: false,
                  message: "Marital Status is required!",
                  whitespace: true,
                },
              ]}
            >
              {/* <div className="w-full items-center flex-col space-y-2">
                <h1 className="text-[#0F0F47] text-sm">Marital Status</h1> */}
              <Select
                showSearch
                style={styles}
                // style={{NotTablet?"":""}}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toString().includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")

                    .toString()
                    .toLowerCase()
                    .localeCompare(
                      (optionB?.label ?? "").toString().toLowerCase()
                    )
                }
              >
                {["single", "married", "divorced", "widowed", "separated"].map(
                  (el: any) => (
                    <Option value={el} className="custom-option capitalize">
                      {el}
                    </Option>
                  )
                )}
              </Select>
              {/* </div> */}
            </Form.Item>
            <Form.Item
              name="phone"
              className="w-[600px] lg:max-2xl:w-72 lg:max-2xl:-mt-2"
              label={<span className="text-[#0F0F47] text-sm">Telephone</span>}
              initialValue={props?.dataToUpdate?.phone}
              rules={[
                {
                  validator: (_, value) => {
                    const phoneNumberLength = value
                      ? value.replace(/\D/g, "").length
                      : 0;
                    if (selectedCountry?.slice(0, 3) === "250") {
                      if (phoneNumberLength === 12) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Phone Number should be exactly 9 Digits.")
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <PhoneInput
                onChange={handleCountryChange}
                country={"rw"}
                buttonStyle={{
                  height: NotTablet ? "50px" : "35px",
                  border: "none",
                  borderRight: "3px solid #fff",
                  backgroundColor: "#EFF0F6",
                }}
                inputStyle={{
                  height: NotTablet ? "50px" : "35px",
                  width: NotTablet ? "90%" : "100%",
                  backgroundColor: "#EFF0F6",
                  border: "none",
                }}
                containerClass="phone-container"
                placeholder="+250 780 000 000"
              />
            </Form.Item>
            <Form.Item
              name="email"
              className="lg:max-2xl:-mt-2"
              label={<span className="text-[#0F0F47] text-sm">Email</span>}
              initialValue={props?.dataToUpdate?.email}
            >
              <Input
                className="pl-4 h-[52px] w-[360px] lg:max-2xl:w-[200px] lg:max-2xl:h-[35px] bg-[#EFF0F6] border-none"
                placeholder="Email"
              />
            </Form.Item>
            <div className="relative inline-block">
              <span
                onClick={toggleDropdown}
                className="text-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
              >
                Customer Profile
                <svg
                  className={`w-2.5 h-2.5 ml-2.5 ${
                    isDropdownOpen ? "transform rotate-180" : ""
                  }`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </span>
              {isDropdownOpen && (
                <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 border border-gray-200">
                  <ul
                    className="xpx-2 text-sm text-gray-700"
                    aria-labelledby="dropdownDefaultButton"
                  >
                    <li onClick={() => handlerDropDown("camera")}>
                      <span
                        className={`${
                          selectedOption === "camera" &&
                          "bg-[#352794] text-white"
                        } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
                      >
                        Camera
                      </span>
                    </li>
                    <li onClick={() => handlerDropDown("upload")}>
                      <span
                        className={`${
                          selectedOption === "upload" &&
                          "bg-[#352794] text-white"
                        } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
                      >
                        Upload
                      </span>
                    </li>
                  </ul>
                </div>
              )}
              {selectedOption === "camera" ? (
                <Form.Item
                  name="CustomerProfile"
                  className=" lg:max-2xl:h-24 lg:max-2xl:-mt-1"
                  rules={[
                    {
                      required:
                        profile || pos?.customerProfile?.data?.secure_url
                          ? false
                          : true,
                      message: "Profile is required",
                    },
                  ]}
                >
                  <div className="w-full">
                    <p
                      className="ml-5 pb-2 text-sm font-medium text-[#0F0F47]"
                      onClick={openCamera}
                    >
                      Open Camera <CameraAltIcon />
                    </p>
                    {(capturedImage || profile) && (
                      <div className="w-32 h-32 lg:max-2xl:w-20 lg:max-2xl:h-20 ml-10">
                        <img
                          src={
                            pos?.customerProfile?.data?.secure_url ?? profile
                          }
                          alt="Captured"
                        />
                      </div>
                    )}
                  </div>
                </Form.Item>
              ) : (
                <div className="w-full">
                  <p
                    className="ml-5 pb-2 text-sm font-medium text-[#0F0F47]"
                    onClick={openCamera}
                  >
                    Upload Profile
                  </p>
                  <UploadComponent
                    setPictures={setCapturedImage}
                    default={
                      (capturedImage || profile) && [
                        {
                          url:
                            (capturedImage &&
                              capturedImage[0]?.response?.data?.secure_url) ??
                            profile,
                        },
                      ]
                    }
                    isCard={true}
                    limit={1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {!wareHouse?.createdCart?.data?.isSubscriptionSubmitted && (
          <Form.Item {...tailFormItemLayout}>
            <div className=" float-right mr-3">
              <LoadingButton
                style={{
                  padding: "4.5px",
                  borderColor: "red",
                  color: "red",
                  width: "80px",
                }}
                variant="outlined"
                loading={customer?.isFetching}
                onClick={props?.removeCustomer}
              >
                {"Discard"}
              </LoadingButton>
              <LoadingButton
                type="submit"
                variant="contained"
                style={{
                  padding: "6px",
                  marginLeft: "4px",
                  width: "80px",
                }}
                loading={customer?.isFetching}
              >
                {"Next"}
              </LoadingButton>
            </div>
          </Form.Item>
        )}
      </Form>

      <Modal title="Customer Profile" open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default PersonnelDetailsForm;
