import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Select, Modal, Button } from "antd";
import { editCategory } from "../redux/actions";
import { useParams } from "react-router-dom";
import { getSingleCategory } from "../redux/actions";

const EditCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { auth, knowledge } = useSelector((state: any) => state);

  const [roles] = useState<Array<Object>>([
    {
      label: "Admin",
      value: "admin",
    },
    {
      label: "Finance",
      value: "finance",
    },
    {
      label: "Finance Manager",
      value: "finance-manager",
    },
    {
      label: "Inventory",
      value: "inventory",
    },
    {
      label: "Technician",
      value: "technician",
    },
    {
      label: "Agent",
      value: "agent",
    },
    {
      label: "Sales Manager",
      value: "sales-manager",
    },
    {
      label: "Sales Supervisor",
      value: "sales-supervisor",
    },
    {
      label: "Sales Agent",
      value: "sales-agent",
    },
    {
      label: "Human Resource",
      value: "hr",
    },
    {
      label: "Developers",
      value: "dev",
    },
  ]);
  const [pageTypes] = useState<Array<Object>>([
    {
      label: "Knowledge",
      value: "knowledge",
    },
    {
      label: "Pricelist",
      value: "pricelist",
    },
    {
      label: "Agents",
      value: "agents",
    },
  ]);

  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [selectedRoles, setSelectedRoles] = useState<Array<string>>([]);
  const [Subcategories, setSubCategories] = useState<Array<Object>>([]);
  const [subcategory, setSubcategory] = useState<string>("");
  const [selectedPageType, setSelectedPageType] = useState<string>("");

  useEffect(() => {
    if (auth?.token && id)
      getSingleCategory(auth?.token, id?.toString())(dispatch);
  }, [auth, id]);

  useEffect(() => {
    if (knowledge.singleCategory) {
      setName(knowledge.singleCategory.categoryName);
      setSubCategories(knowledge.singleCategory.subCategory);
    }
  }, [knowledge.singleCategory]);

  const showModal = () => {
    setSubcategory("");
    setSelectedRoles([]);
    setSelectedPageType("");
    setModalType("add");
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
    if (modalType == "edit") {
      setSubCategories(
        Subcategories.map((item: any) => {
          if (item.name == subcategory) {
            return {
              name: subcategory,
              accessLevels: selectedRoles,
              pageType: selectedPageType,
            };
          } else {
            return item;
          }
        })
      );
    } else {
      setSubCategories([
        ...Subcategories,
        {
          name: subcategory,
          accessLevels: selectedRoles,
          pageType: selectedPageType,
        },
      ]);
    }
    setSubcategory("");
    setSelectedRoles([]);
    setSelectedPageType("");
  };

  const handleCancel = () => {
    setOpen(false);
    setSubcategory("");
    setSelectedRoles([]);
    setSelectedPageType("");
  };

  const saveCategory = () => {
    if (auth?.token && id) {
      editCategory(auth?.token, id, {
        categoryName: name,
        subCategory: Subcategories,
      })(dispatch);
    }
    navigate(-1);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center w-[90%]">
        <div className="flex flex-col justify-between items-center w-[100%]">
          <h1 className="block text-[17px] md:text-2xl font-semibold text-gray-800 py-6 md:text-3x">
            Edit category
          </h1>
          <div className="flex flex-col justify-center items-center w-[100%] mt-6">
            <div className="flex flex-row w-[100%] justify-around">
              <div className="mb-6 w-[60%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Name
                </label>
                <input
                  type="text"
                  id="title"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Title of the post"
                />
              </div>
            </div>
            <div className="flex flex-row w-[60%] justify-between">
              <label className="text-m font-semibold text-gray-900 mb-4">
                Subcategories
              </label>
              <button
                onClick={showModal}
                className="flex text-[13px] md:text-[16px] items-center px-3 h-10 rounded-lg bg-gray-800 text-gray-200"
              >
                Add a new sub-category
              </button>
            </div>
            <div className="flex flex-col w-[60%] justify-between">
              {Subcategories.length == 0 && (
                <p className="text-sm text-gray-400 mb-4">
                  No subcategories added
                </p>
              )}
              {Subcategories.map((subcategory: any, index: number) => (
                <div
                  key={index}
                  className="flex flex-row justify-between items-center w-[100%] border-[1px] p-2 rounded-md mt-3 border-gray-400"
                >
                  <div className="flex flex-col w-[80%]">
                    <p className="text-m font-semibold text-gray-900">
                      {subcategory.name}
                    </p>
                    <p className="mb-4">({subcategory.pageType})</p>
                    <div className="flex flex-row gap-2 flex-wrap">
                      {subcategory.accessLevels.map((item: string) => {
                        return (
                          <p className="bg-gray-200 text-gray-800 rounded-md px-3">
                            {item.toUpperCase()}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center">
                    <button
                      onClick={() => {
                        setSelectedRoles(subcategory.accessLevels);
                        setSelectedPageType(subcategory.pageType);
                        setSubcategory(subcategory.name);
                        setModalType("edit");
                        setOpen(true);
                      }}
                      className="flex text-[13px] md:text-[16px] items-center px-3 h-10 rounded-lg bg-gray-800 text-gray-200"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => {
                        setSubCategories(
                          Subcategories.filter(
                            (item: any) => item.name !== subcategory.name
                          )
                        );
                      }}
                      className="flex text-[13px] md:text-[16px] items-center px-3 h-10 rounded-lg bg-gray-800 text-gray-200 ml-4"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <Button
              onClick={saveCategory}
              style={{
                color: "#fff",
              }}
              disabled={Subcategories.length == 0 || name == ""}
              className="text-[13px] md:text-[16px] items-center px-3 h-10 my-5 rounded-lg bg-gray-800 text-gray-200 hover:text-gray-200"
              loading={knowledge.isFetching}
            >
              Save category
            </Button>
            <Modal
              open={open}
              title="Title"
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <button
                  className="text-[13px] md:text-[16px] items-center px-3 mr-4 h-10 rounded-lg text-gray-800 bg-gray-200 hover:text-gray-800"
                  key="back"
                  onClick={handleCancel}
                >
                  Cancel
                </button>,
                <Button
                  key="submit"
                  style={{
                    color: "#fff",
                  }}
                  className="text-[13px] md:text-[16px] items-center px-3 h-10 rounded-lg bg-gray-800 text-gray-200 hover:text-gray-200"
                  loading={false}
                  onClick={handleOk}
                >
                  {modalType == "edit"
                    ? "Edit sub-category"
                    : "Add sub-category"}
                </Button>,
                ,
              ]}
            >
              <div className="mb-6 w-[100%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Name
                </label>
                <input
                  type="text"
                  id="title"
                  disabled={modalType == "edit"}
                  value={subcategory}
                  onChange={(e) => setSubcategory(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Name of the subcategory"
                  required
                />
              </div>
              <div className="mb-6 w-[100%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Roles
                </label>
                <Select
                  mode="multiple"
                  id="roles"
                  value={selectedRoles}
                  onChange={(e) => setSelectedRoles(e)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Select all roles to grant access to this page"
                  options={roles}
                />
              </div>
              <div className="mb-6 w-[100%]">
                <label className="text-m font-semibold text-gray-900 mb-4">
                  Type of page
                </label>
                <Select
                  id="pageType"
                  value={selectedPageType}
                  onChange={(e) => setSelectedPageType(e)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 focus:outline-none block w-full p-2.5"
                  placeholder="Select the type of page"
                  options={pageTypes}
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCategory;
