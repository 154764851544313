import React, { useEffect } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import MetricsCard from "../../../components/cards/purchase/MetricsCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import RepairCard from "../../../components/cards/pos/RepairCard";
import { getAllRepairsAction } from "../../../store/repair/actions";
import { myRepairActions } from "../../../store/repair";
import { getActiveShop } from "../../../utils/converter";

const RepairOverview = (props: any) => {
  const isMobile = useMediaQuery("(max-width: 850px)");
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {};
  const { auth, customer, repair, appSettings } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? auth?.user?.shop?.shopId;

  useEffect(() => {
    auth?.token &&
      getAllRepairsAction(auth?.token, `?shop=${shopId}&limit=15`)(dispatch);
    // getAllRepairsAction(auth?.token, `?shop=${auth?.user?._id}`)(dispatch);
  }, [auth?.token, auth?.user?._id, dispatch]);

  useEffect(() => {
    dispatch(myRepairActions.setRepairSearchResult(null));
    dispatch(myRepairActions.setRepairSearchError(null));
  }, [dispatch]);

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-screen">
      <Stack spacing={3}>
        <div className="flex justify-between">
          <DatePicker onChange={onChange} className={"w-48"} />
          <LoadingButton
            title="Repair"
            variant="contained"
            onClick={() => navigate("/repair")}
          >
            Repair
          </LoadingButton>
        </div>
        <div
          className={`grid ${
            isMobile ? "grid-cols-1" : isTablet ? "grid-cols-2" : "grid-cols-3"
          } justify-between gap-2 text-white`}
        >
          {/* Overview card */}
          <MetricsCard
            title={"All Repaired Devices"}
            number={customer?.all?.total}
            icon={<LocalAtmIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Being repaired"}
            number={
              customer?.all?.data?.filter((d: any) => d.isActive === true)
                ?.length
            }
            icon={<DoneAllIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"On Pending"}
            number={
              customer?.all?.data?.filter((d: any) => d.isActive === false)
                ?.length
            }
            icon={<AutoGraphOutlinedIcon style={{ color: "white" }} />}
          />
        </div>
        <div className="w-full bg-transparent p-3 rounded-lg space-y-4">
          <div className="flex flex-col">
            <div className="flex items-center justify-between">
              <p className="text-xl -ml-2 font-semibold">Recent Activities</p>
              <Link to={"/repair/devices"}>
                <p>See All</p>
              </Link>
            </div>
            <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-2">
              {repair?.all?.data
                ?.map((request: any) => <RepairCard repairRequest={request} />)
                ?.slice(0, 8)}
            </div>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default RepairOverview;
