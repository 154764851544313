import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideNav from "./SideNav";
import store from "store";
import { ReactComponent as ForceLogo } from "../../assets/icons/Force_Logo.svg";
// import { ReactComponent as ForceLogo } from "../../assets/icons/Force Favicon New.svg";
import { ReactComponent as MTNLogo } from "../../assets/icons/MTN Logo.svg";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { authActions } from "../../store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavigationBar from "./TopNavigationBar";
import { getActiveShop } from "../../utils/converter";
import { getOneShopAction } from "../../store/shop/actions";

const Layout = (props: any) => {
  const dispatch = useDispatch();
  const { auth, shop, appSettings } = useSelector((state: any) => state);
  const location = useLocation();
  const navigate = useNavigate();
  const ishome = ["/dashboard"].includes(location.pathname);
  const removeGlass =
    location.pathname.includes("pos/sale/") || location.pathname === "/repair";
  const shopInfo =
    getActiveShop(auth?.user?.shop?.assigned)[
      appSettings?.selectedShopNumber
    ] || shop?.myShop;

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? auth?.user?.shop?.shopId;

  useEffect(() => {
    dispatch(authActions.setIsFetching(true));
    dispatch(authActions.setUser(store.get("user")));
    dispatch(authActions.setIsFetching(false));
  }, [auth.token, dispatch]);

  useEffect(() => {
    auth?.token &&
      shopId &&
      getOneShopAction(shopId, auth?.token, true)(dispatch);
  }, [auth?.token, dispatch, shopId]);

  return (
    <div className={`w-full h-screen overflow-y-auto  text-[#0F0F47]`}>
      <div className="flex h-full">
        <div className={`w-full min-h-screen xpt-14`}>
          <div className={`w-full flex flex-col mt-3 pr-3 pl-1`}>
            {ishome && (
              <div className="flex items-center justify-between">
                <div>
                  <ForceLogo
                    width={100}
                    className="h-8 w-20"
                    onClick={() => navigate("/dashboard")}
                  />
                </div>
                <div className="flex flex-grow justify-between p-2 min-h-[40px] bg-white bg-opacity-50 rounded-md shadow text-semibold text-[#0F0F47] max-w-lg text-xs font-semibold">
                  {shopInfo && (
                    <>
                      <p>
                        {shopInfo?.shop?.name ||
                          shopInfo?.type?.split("-")?.join(" ")}
                      </p>
                      <div>
                        <MTNLogo className="h-4" />
                      </div>
                      <p>{shopInfo?.name}</p>
                    </>
                  )}
                </div>
                <div className="flex items-center gap-x-2">
                  <Tooltip title="Knowledge">
                    <IconButton
                      color="primary"
                      aria-label="Go to knowledge"
                      component="label"
                      onClick={() => {
                        navigate("/knowledge");
                      }}
                    >
                      <HelpIcon sx={{ color: "black" }} />
                    </IconButton>
                  </Tooltip>
                  <p className="font-medium text-xs">Kigali</p>
                  <div className="w-7">
                    <img
                      src={
                        "https://media.istockphoto.com/id/1425795893/vector/the-national-flag-of-the-world-rwanda.jpg?s=2048x2048&w=is&k=20&c=5DXybGpeRdCSu7PvTKNxrnB0ohYQvXJ-E_2leomiJd8="
                      }
                      width={"100%"}
                      alt=""
                      className="rounded-full"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className={`relative`}>
              {ishome ? null : (
                <>
                  <TopNavigationBar
                    pageId={3}
                    pages={props?.nav?.props?.pages}
                    // setOpenModal={setOpenShopSelectModal}
                  />
                  <div className="flex items-center gap-x-2 absolute right-0 top-0">
                    <Tooltip title="Knowledge">
                      <IconButton
                        color="primary"
                        aria-label="Go to knowledge"
                        component="label"
                        onClick={() => {
                          navigate("/knowledge");
                        }}
                      >
                        <HelpIcon sx={{ color: "black" }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="fixed top-[64px] flex h-[89vh] w-full gap-x-2">
            <SideNav />
            <div
              className={`h-full w-full ${
                ishome || removeGlass
                  ? ""
                  : "p-[1vw] bg-white rounded-lg bg-opacity-50 shadow"
              } mr-2`}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
