import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Radio,
  RadioChangeEvent,
  notification,
  Table,
  Typography,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { getAllTermsAndConditionsAction } from "../../../store/TermsAndCondirons/actions";
import "./address.css";
import TermsAndConditionContent from "./TermsAndConditionContent";
import { useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { customerSignatureAction } from "../../../store/pos/actions";
import { updateShopStockCartDetailsAction } from "../../../store/wareHouse/actions";
import { LoadingButton } from "@mui/lab";
import { Margin, usePDF } from "react-to-pdf";
import { getOneAccountAction } from "../../../store/account/actions";
const { confirm } = Modal;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const GeneralTermsAndConditions: React.FC<{
  templateRef: any;
  handlePrint: any;
  props?: any;
  cancel: any;
}> = ({ templateRef, handlePrint, props, cancel }) => {
  const { wareHouse, auth, termsAndCondions, pos, account } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const { cartId, accountId } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [customerSignature, setCustomerSignature] = useState<any>([]);
  const [acceptTerms, setAcceptTerms] = useState(
    wareHouse?.createdCart?.data?.acceptTermsAndConditions
  );
  const [sign, setSign] = useState<SignatureCanvas | null>(null);
  const [openPopOver, setOpenPopOver] = useState(false);
  const { toPDF, targetRef } = usePDF({
    filename: wareHouse?.createdCart?.data?.customer?.name
      ?.split(" ")
      ?.join("_"),
    page: { margin: Margin.MEDIUM },
  });

  const handleDownloadPDF = () => {
    toPDF({
      filename: wareHouse?.createdCart?.data?.customer?.name
        ?.split(" ")
        ?.join("_"),
      page: { margin: Margin.MEDIUM },
    });
  };
  const handleClear = () => {
    sign?.clear();
    setCustomerSignature("");
  };

  const handleSignature = async () => {
    const trimmedCanvas = sign?.getTrimmedCanvas();
    if (trimmedCanvas) {
      const dataUrl = trimmedCanvas.toDataURL("image/png");
      if (dataUrl) {
        setIsLoading(true);
        await customerSignatureAction(auth?.token, {
          image: dataUrl,
        })(dispatch);
        // await updateShopStockCartDetailsAction(auth?.token, cartId, {
        //   customerSignature: pos?.customerSignature?.data?.secure_url,
        //   acceptTermsAndConditions: true,
        // })(dispatch);
      }
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    const updateCartDetails = async () => {
      if (pos?.customerSignature !== null) {
        await updateShopStockCartDetailsAction(auth?.token, cartId, {
          customerSignature: pos?.customerSignature?.data?.secure_url,
          acceptTermsAndConditions: true,
        })(dispatch);
      }
    };

    updateCartDetails();
  }, [pos?.customerSignature]);

  const openNotification = () => {
    notification.open({
      message: "Customer signature is required",
      description:
        "please provide Customer signature to accept Terms and conditions",
    });
  };
  const checkSignature = () => {
    if (sign?.isEmpty()) {
      openNotification();
    } else {
      handleSignature();
      handleDownloadPDF();
      cancel();
    }
  };

  const onFinish = async (values: any) => {
    // if (customerSignature) {
    //   await updateShopStockCartDetailsAction(auth?.token, cartId, {
    //     customerSignature: customerSignature,
    //     acceptTermsAndConditions: true,
    //   })(dispatch);
    // } else {
    //   notification.info({ message: "Please Upload Customer Signature" });
    // }
  };

  const onActionChange = async (e: RadioChangeEvent) => {
    setAcceptTerms(e.target.value);
  };
  const newAction = () => {
    confirm({
      title: "Reject Terms and conditions?",
      content:
        "once you click ok you will not be able to proceed with the next steps, click cancel so that you can change your choice",
      onOk() {
        cancel();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const { Text } = Typography;

  const columns = [
    {
      title: "Brand",
      dataIndex: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "IMEI",
      dataIndex: "sirialNumber",
    },
    {
      title: "Installment Plan",
      dataIndex: "installmentPlan",
      render: (text: any, record: any) => (
        <p className="cursor-pointer">
          <span className="text-xs">{`${text} Months`}</span>
        </p>
      ),
    },
    {
      title: "Installment type",
      dataIndex: "installmentType",
      render: (text: any, record: any) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Recurring Payment",
      dataIndex: "recurringPayment",
    },
  ];
  React.useEffect(() => {
    getAllTermsAndConditionsAction(auth.token, "?")(dispatch);
  }, [dispatch, auth.token]);

  const priceListId =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list[0]?.priceProduct?.subscriptionPlan[0]
      ?.pricelistItem;

  const dataToDisplay = wareHouse?.createdCart?.data?.list?.map((el: any) => {
    return {
      key: el._id,
      brand: el?.warehouseProduct?.product?.brand,
      model: el?.warehouseProduct?.product?.model,
      price: el?.payment?.amount?.toLocaleString(),
      sirialNumber: el?.warehouseProduct?.serialNumber,
      recurringPayment: el?.payment?.installementPayment?.toLocaleString(),
      capacity: el?.shopStockItem?.requestedItem?.product?.specification
        .filter((item: any) => item[0] === "Capacity (ROM)")
        .map((item: any) => item[1]),
      installmentType: wareHouse?.createdCart?.data?.instalmentPlan,
      installmentPlan: wareHouse?.createdCart?.data?.subscriptionPlan,
      priceListId: priceListId,
      data: el,
    };
  });

  const amountToPay =
    wareHouse?.createdCart?.data?.list &&
    wareHouse?.createdCart?.data?.list[0]?.payment?.installementPayment;

  useEffect(() => {
    if (accountId && auth.token) {
      getOneAccountAction(accountId as string, auth.token)(dispatch);
    }
  }, [accountId, auth.token, dispatch]);

  return (
    <>
      {/* <button onClick={handleDownloadPDF}>Download PDF</button> */}
      <div className="px-10" ref={targetRef}>
        <h1 className=" text-[25px] font-bold text-center capitalize">
          Terms And Conditions
        </h1>
        <div className="flex justify-between  mt-5">
          <div>
            <h1 className="text-[#0F0F47] text-lg font-bold capitalize">
              Customer information
            </h1>
            <ul className="">
              <li className="text-base capitalize pt-4">
                <span> First Name:</span>{" "}
                <span className="pl-[46px]">
                  {wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]}
                </span>
              </li>
              <li className="text-base capitalize pt-4">
                <span>Last Name:</span>{" "}
                <span className="pl-[46px]">
                  {wareHouse?.createdCart?.data?.customer?.name?.slice(
                    wareHouse?.createdCart?.data?.customer?.name?.split(" ")[0]
                      ?.length
                  )}
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Financed by:</span>{" "}
                <span className="pl-10 capitalize">
                  {/* {wareHouse?.createdCart?.data?.initialPaymentType
                    ?.split("_")
                    ?.join(" ")} */}
                  Samphone Rwanda
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Shop:</span>{" "}
                <span className="pl-[85.9px] ">
                  {wareHouse?.createdCart?.data?.shop?.name}
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Account number:</span>{" "}
                <span className="pl-3">
                  {wareHouse?.createdCart?.data?.account?.accountID ?? "-"}
                </span>
              </li>
              <li className=" text-base pt-2">
                <span> Date:</span>{" "}
                <span className="pl-[91px] ">
                  {wareHouse?.createdCart?.data?.updatedAt?.substring(0, 10)}
                </span>
              </li>
            </ul>
          </div>
          <div className="mr-3 rounded-md mt-12">
            <img
              src={wareHouse?.createdCart?.data?.customer?.picture}
              alt="profile"
              width={200}
              className="rounded-md"
            />
          </div>
        </div>
        <div className="">
          <h1 className="text-[#0F0F47] text-lg font-bold capitalize py-10 ">
            Purchased Devices
          </h1>
          <Table
            columns={columns}
            dataSource={dataToDisplay}
            pagination={false}
            bordered
            summary={(pageData) => {
              console.log("page data", pageData[0]?.price);
              let totalPrice = pageData && pageData[0]?.price;
              //calculate total price......
              pageData.forEach((item: any) => {
                const p = item?.p;

                if (p && p.payment && p.payment.amount) {
                  totalPrice += p.payment.amount;
                }
              });

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      index={0}
                      colSpan={5}
                      className="font-bold"
                    >
                      Total Installment Payment Amount
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={3}>
                      <Text className="text-[18px] font-bold">
                        {totalPrice} Rfw
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />

          {/* {termsAndCondions?.all?.data ? ( */}
          <div className="divide-y divide-black ">
            <h1 className="text-[22px] text-center  font-bold uppercase p-10">
              {/* {termsAndCondions?.all?.data[0]?.title} */}
              TERMS AND CONDITIONS FOR (MTN) SERVICE BUNDLE SUBSCRIPTION AND
              INCLUDED SMARTPHONE
            </h1>
            <section className="text-base ">
              {/* {parse(
                termsAndCondions?.all?.data[0]?.descriptions?.toString() ?? ""
              )} */}
              <div>
                <h1 className="text-[#0F0F47] text-lg font-bold capitalize my-2">
                  General Terms and Conditions
                </h1>
                <p>
                  By signing this Subscription Agreement (the "Agreement")
                  between yourself, MTN RWANDACELL Limited ("MTN") and{" "}
                  <span className="text-lg font-[500]">
                    SAMPHONE RWANDA("Distributor ")
                  </span>{" "}
                  , you, the undersigned identified buyer
                  <span className="text-lg font-[500]">
                    {" "}
                    {wareHouse?.createdCart?.data?.customer?.name}
                  </span>
                  , agree to purchase the phone ("the Device"), where the full
                  repayment for the cost of the device is offset by a fixed
                  monthly subscription to MTN Service Bundle ("Bundle Price")
                  over a period of{" "}
                  <span className="text-lg font-[500]">
                    {`${wareHouse?.createdCart?.data?.subscriptionPlan} Months`}
                  </span>{" "}
                  (the "Tenor"), subject to the terms and conditions contained
                  herein. The Bundle of{" "}
                  <span className="text-lg font-[500]">
                    {`${wareHouse?.createdCart?.data?.other?.bundle ?? 0} GB`}
                  </span>{" "}
                  ,{" "}
                  <span className="text-lg font-[500]">
                    {wareHouse?.createdCart?.data?.other?.sms ?? 0}
                  </span>{" "}
                  text messages and{" "}
                  <span className="text-lg font-[500]">
                    {wareHouse?.createdCart?.data?.other?.call ?? 0}
                  </span>{" "}
                  minutes of talk time during period of{" "}
                  <span className="text-lg font-[500]">
                    {wareHouse?.createdCart?.data?.other?.period ?? 0}
                  </span>{" "}
                  Months. Monthly quoted will be the committed sum payable each
                  month for the duration of the Agreement and will not change
                  unless you Default on your monthly repayments.
                  <br />
                  <br /> The Bundle Price covers your monthly repayment towards
                  the cost of the Device, as well as a Data bundle service
                  provided monthly by MTN, your network provider. Each month,
                  you are required to make payment through MTN Mobile Money
                  ("Momo") Account deductions equal to the Bundle Price. The
                  deductions can be made daily, weekly or monthly and the full
                  monthly amount must be made prior to and/or on the required
                  due date but not later than 1 day prior to the repayment
                  anniversary date. With this agreement, you hereby authorize
                  MTN to deduct{" "}
                  <span className="text-lg font-[500]">{`${
                    amountToPay && amountToPay?.toLocaleString()
                  } RWF`}</span>{" "}
                  from your Momo Account towards your full monthly subscription.
                  On the subscription anniversary date and following full
                  subscription, MTN will provision the bundle for the following
                  month.
                  <br />
                  <br />
                  On the subscription anniversary date and following full
                  subscription, MTN will provision the bundle for the following
                  month. The terms "We" or "Us" shall refer to MTN and/or the
                  Distributor
                </p>
              </div>

              <TermsAndConditionContent />
            </section>
          </div>
          {/* ) : (
          <Spin />
        )} */}
          <div>
            <h1 className="text-lg font-bold capitalize py-5 ">
              Copy of National Id
            </h1>
            <div className="rounded-md">
              <img
                src={
                  wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                    ?.nationalIDDoc
                }
                alt="National Id"
                className="object-cover w-[320px] h-32 rounded-md"
              />
            </div>
          </div>
        </div>
        <div className="mt-8 ml-3">
          <Form
            {...formItemLayout}
            form={form}
            name="register"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{}}
            style={{ maxWidth: "100%" }}
            scrollToFirstError
            className=""
          >
            {!wareHouse?.createdCart?.data?.acceptTermsAndConditions && (
              <Radio.Group onChange={onActionChange} value={acceptTerms}>
                <Radio
                  value={"accepted"}
                  disabled={
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions
                  }
                >
                  <div className="">
                    <p>Accept </p>
                  </div>
                </Radio>

                <Radio
                  onClick={newAction}
                  value={"rejected"}
                  disabled={
                    wareHouse?.createdCart?.data?.acceptTermsAndConditions
                  }
                >
                  <div className="">
                    <p>Reject </p>
                  </div>
                </Radio>
              </Radio.Group>
            )}
          </Form>
          {(acceptTerms === "accepted" ||
            wareHouse?.createdCart?.data?.acceptTermsAndConditions) && (
            // <Signature
            //   setCustomerSignature={setCustomerSignature}
            //   onCancel={props?.onCancel}
            // />
            <div className="min-h-52 w-[22rem] ">
              <p className="text-[#0F0F47] pt-8 text-sm text-center font-normal drawText">
                Draw your Signature
              </p>
              <SignatureCanvas
                canvasProps={{
                  className:
                    "mt-4 h-[153px] w-[352px] border-dotted border-2 border-indigo-300 px-3 py-2 cursor-pointer",
                }}
                ref={(data: any) => setSign(data)}
                penColor="black"
              />
              <div className=" float-right mt-4 printBtn space-x-3">
                <LoadingButton
                  variant="outlined"
                  onClick={handleClear}
                  className="px-8 py-1 rounded-md bg-gray-200 m-2"
                >
                  Clear
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    checkSignature();
                  }}
                  disabled={wareHouse?.isFetching || pos?.isFetching}
                  loading={
                    wareHouse?.isFetching ||
                    pos?.isFetching ||
                    account?.isFetching ||
                    isLoading
                  }
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GeneralTermsAndConditions;
