import { Stack, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

import { useDispatch, useSelector } from "react-redux";
import PosProductGrid from "../../../components/grids/priceList/PosProductGrid";
import { getPricelistAction } from "../../../store/pos/actions";
// import ProductFilter from "../../../components/filters/ProductFiltering";
import PriceListFilter from "../../../components/filters/PriceListFiltering";
import ScrollableFrame from "../../../components/layout/ScrollableFrame";
import SearchInput from "../../../components/buttons/SearchButton";

const PageView = (props: any) => {
  const { auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = React.useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const channelId = auth?.user?.shop?.channel || auth?.user?.shop?.channelId;

  useEffect(() => {
    auth?.token &&
      channelId &&
      getPricelistAction(
        auth?.token,
        `?channel=${channelId}&page=${page - 1}&limit=${limit}`
      )(dispatch);
  }, [auth?.token, channelId, dispatch, limit, page]);

  const goBack = () => {
    navigate(-1);
  };

  const handleSearch = () => {
    getPricelistAction(
      auth?.token,
      `/searchPriceList?channel=${channelId}&sk=${searchKey}&page=${
        page - 1
      }&limit=${limit}`
    )(dispatch);
  };
  return (
    <div className="flex flex-col h-full">
      <div className="text-black pt-2 pb-2 justify-between mt-4 flex">
        <DetailsHeaderActionBar
          pageName="Price List"
          goBack={goBack}
          title=" "
        />
      </div>
      <div className="flex items-center justify-between pr-20">
        <PriceListFilter isModalOpen={true} />
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
      </div>
      {/*  */}
      <ScrollableFrame
        setPage={setPage}
        setLimit={setLimit}
        limit={limit}
        total={pos?.priceList?.total}
        count={Math.ceil(pos?.priceList?.total / limit)}
      >
        {pos?.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start">
            {Array.from({ length: 14 }).map((d: any) => (
              <div className="w-80">
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" height={150} />
              </div>
            ))}
          </div>
        )}
        {!pos.isFetching && (
          <div className="flex flex-wrap gap-4 justify-start items-start mt-5">
            {pos?.priceList?.status === 200 && (
              <PosProductGrid data={pos?.priceList?.data} />
            )}
          </div>
        )}
      </ScrollableFrame>
    </div>
  );
};

export default PageView;
