import { myShopActions } from ".";
import {
  getOneShop,
  createShopRequest,
  updateQntyApprovedRequest,
  deleteRequestStockItem,
} from "./services";

export const getOneShopAction = (
  itemId: string,
  token: string,
  isMyShop?: boolean
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await getOneShop(itemId, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelected(res?.data));
        isMyShop && dispatch(myShopActions.setMyShop(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const createRequestStockAction = (
  token: string,
  data: {},
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await createShopRequest(data, token);

      if (res?.status === 201) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const quantityApprovedAction = (
  id: string,
  data: any,
  token: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await updateQntyApprovedRequest(id, data, token);
      // console.log(">>>>>>>:: update>>", res);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteStockRequestItemAction = (id: string, token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myShopActions.setIsFetching(true));
      const res = await deleteRequestStockItem(id, token);
      if (res?.status === 200) {
        dispatch(myShopActions.setSelectedRequestedStock(res?.data));
        dispatch(myShopActions.setIsFetching(false));
        return true;
      }
      dispatch(myShopActions.setIsFetching(false));
      return false;
    } catch (err) {
      console.log(err);
    }
  };
};

export {};
