import React from "react";
import { Stack, useMediaQuery } from "@mui/material";
import MetricsCard from "../../../components/cards/purchase/MetricsCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";

const PageView = (props: any) => {
  const isMobile = useMediaQuery("(max-width: 850px)");
  const isTablet = useMediaQuery("(max-width: 1200px)");
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {};
  const { customer } = useSelector((state: any) => state);

  return (
    <div className="text-black py-6 scrollbar-hide overflow-y-auto h-screen">
      <Stack spacing={3}>
        <div>
          <DatePicker onChange={onChange} className={"w-48"} />
        </div>
        <div
          className={`grid ${
            isMobile ? "grid-cols-1" : isTablet ? "grid-cols-2" : "grid-cols-3"
          } justify-between gap-2 text-white`}
        >
          {/* Overview card */}
          <MetricsCard
            title={"All Customers"}
            number={customer?.all?.total}
            icon={<LocalAtmIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Active Customers"}
            number={
              customer?.all?.data?.filter((d: any) => d.isActive === true)
                ?.length
            }
            icon={<DoneAllIcon style={{ color: "white" }} />}
          />
          <MetricsCard
            title={"Inactive Customers"}
            number={
              customer?.all?.data?.filter((d: any) => d.isActive === false)
                ?.length
            }
            icon={<AutoGraphOutlinedIcon style={{ color: "white" }} />}
          />
        </div>
        <div className="w-full bg-transparent p-3 rounded-lg space-y-4">
          <div className="flex gap-x-10">
            <p className="text-xl -ml-2 font-semibold">Recent Activities</p>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default PageView;
