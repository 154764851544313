import BarcodeScannerModal from "../BarcodeScannerModal";

const BarcodeScanner = (props: any) => {
  const getCode = (code: string) => {
    props?.setScannedItem((prevScannedCodes: any) => {
      if (!prevScannedCodes.includes(code)) {
        return [...prevScannedCodes, code];
      } else {
        return prevScannedCodes;
      }
    });
    props?.setManualOption(false);
    props?.setItemArray([]);
  };

  return (
    <>
      <div
        className={` rounded-lg text-black 
          leading-none xbox-border`}
      >
        <div className="xrounded-lg">
          <BarcodeScannerModal getCode={getCode} />
        </div>
      </div>
    </>
  );
};

export default BarcodeScanner;
