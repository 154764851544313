import React, { useRef, useEffect } from "react";
import GrowingSpinner from "./Loaders/GrowingSpinner";

const SearchBar: React.FC<{
  value?: any;
  onChange?: (e: any) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  scannedCode?: string;
}> = ({ value, onChange, isDisabled, isLoading, scannedCode }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  return (
    <div className="flex justify-center mt-2">
      <div className="xmb-3 xl:w-96">
        <div className="relative xmb-4 flex w-full flex-wrap items-stretch bg-gray-100 ">
          <input
            type="search"
            disabled={isDisabled || false}
            className="relative m-0 block w-[1%] min-w-0 flex-auto rounded xborder xborder-solid xborder-neutral-300 bg-transparent bg-clip-padding px-3 py-1.5 text-base font-normal xtext-neutral-700 outline-none transition duration-300 ease-in-out focus:border-primary-600 focus:text-neutral-700 focus:shadow-te-primary focus:outline-none x:border-neutral-600 dark:text-neutral-200 dark:placeholder:text-neutral-200 placeholder:!text-gray-400"
            placeholder="Search"
            aria-label="Search"
            aria-describedby="button-addon2"
            value={value || scannedCode}
            ref={inputRef}
            onChange={onChange}
            autoFocus
          />
          <button
            type="submit"
            className="flex justify-center items-center w-16"
          >
            <span
              className={`input-group-text flex items-center whitespace-nowrap rounded px-3 py-1.5 text-center text-base font-normal ${
                isLoading ? "" : "text-neutral-200"
              }`}
              id="basic-addon2"
            >
              {!isLoading && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fill-rule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clip-rule="evenodd"
                    className="text-gray-400"
                  />
                </svg>
              )}
              {isLoading && (
                <div>
                  <GrowingSpinner />
                </div>
              )}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
