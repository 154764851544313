import { useState } from "react";
import { Stack, IconButton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Carousel, Modal, Tag } from "antd";
import { getColorByValue, searchValue } from "../../../utils/setColor";
import DeleteModal from "../../Modals/DeleteModal";
import { deletePriceListItemAction } from "../../../store/channel/actions";
import PricePopover from "../../Modals/PricePopover";
import UpdatePriceForm from "../../forms/UpdatePriceForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddItem from "../../../pages/dashboard/channels/priceList/AddItems";
import ViewSubscriptionForm from "../../forms/ViewSubscriptionForm";
// import AddItem from "../../../../pages/channels/priceList/AddItems";

const Component = (props: any) => {
  // console.log(props);
  const { auth, channel } = useSelector((state: any) => state);
  const [openUpdatePopover, setOpenUpdatePopover] = useState(false);

  const itemPrice = props?.data?.prices
    ?.find((price: any) => price.isActive)
    ?.value?.toLocaleString();

  const handleOpenUpdatePopover = (newOpen: boolean) => {
    setOpenUpdatePopover(newOpen);
  };

  const [visible, setVisible] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch();

  const handleClickDelete = async () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setShowEdit(false);
  };

  const handleDeleteModalCancels = () => {
    setVisible(false);
  };

  const deleteProduct = async () => {
    console.log("deleting....");
    auth?.token &&
      (await deletePriceListItemAction(
        auth?.token,
        props?.data._id,
        `?channel=${channel.priceList?.data[0]?.channel?._id}`
      )(dispatch));
    // navigate("/inventory/pr");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOnClickView = (value: any, e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="rounded-md relative border border-white hover:border-gray-300 duration-500">
        <Badge.Ribbon color="green" text={`${itemPrice} RWF`}>
          <div className="relative w-80 h-52 p-2 pt-10 bg-white text-black rounded-md">
            <div className="flex w-fit">
              <div style={{ width: "5rem" }}>
                <Carousel autoplay>
                  {props?.data?.product?.images?.map((im: any) => (
                    <div>
                      <img src={im} width={"100%"} alt="" />
                    </div>
                  ))}
                </Carousel>
              </div>
              <Badge
                className="site-badge-count-10 text-sm p-2 h-1"
                count={
                  props?.data?.extendedWarranty
                    ? `+ Ext-Warranty ${props?.data?.extendedWarranty}% `
                    : ""
                }
                style={{ backgroundColor: "#faad14" }}
              >
                <div className="flex flex-col pl-2 gap-1">
                  <h1 className="font-bold text-lg">
                    {props?.data?.product?.model}
                  </h1>
                  <div className=" gap-1">
                    {props?.data &&
                      props?.data?.specification?.map(
                        (data: any, _index: number) =>
                          data[1] && (
                            <Stack>
                              <p className="text-xs">
                                <b>{data[0]}:</b>
                                {` ${data[1]}`}
                              </p>
                            </Stack>
                          )
                      )}
                  </div>
                </div>
              </Badge>
            </div>
            <div className="absolute bottom-1 right-2 flex justify-end items-center w-full py-2 space-x-2">
              {["sales-agent"].includes(auth?.user?.role?.toLowerCase()) &&
                props?.data?.hasSubs && (
                  <Tag
                    color={searchValue("B2C")}
                    className="cursor-pointer"
                    onClick={(e: any) => handleOnClickView(props?.data, e)}
                  >
                    {"B2C"}
                  </Tag>
                )}
              <Tag
                color={getColorByValue(
                  props?.types,
                  props?.data?.product?.type
                )}
              >
                {props?.data?.product?.type}
              </Tag>

              <Tag
                color={getColorByValue(
                  props?.brands,
                  props?.data?.product?.brand
                )}
              >
                {props?.data?.product?.brand}
              </Tag>

              {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
                <>
                  <PricePopover
                    title="Update price"
                    handleOpen={handleOpenUpdatePopover}
                    open={openUpdatePopover}
                    disable={false}
                    content={
                      <UpdatePriceForm
                        handleOpenUpdatePopover={handleOpenUpdatePopover}
                        priceListItemId={props?.data?._id}
                        dataToUpdate={{
                          value: parseInt(itemPrice?.split(",")?.join("")),
                          extendedWarranty: props?.data?.extendedWarranty,
                        }}
                      />
                    }
                    icon={<AttachMoneyIcon fontSize="small" />}
                  />

                  <IconButton
                    aria-label="fingerprint"
                    color="error"
                    size="small"
                    onClick={handleClickDelete}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        </Badge.Ribbon>
      </div>

      <Modal
        title={"Edit Pricelist Item"}
        open={showEdit}
        onCancel={handleCancel}
        footer={null}
        className="min-w-min"
        // icon={props?.icon || <HomeIcon />}
      >
        <div>
          <div className="w-[100vh]">
            {
              <AddItem
                selectedModelId={props?.data?._id}
                onCancel={handleCancel}
              />
            }
          </div>
        </div>
      </Modal>
      {["admin", "dev"].includes(auth?.user?.role?.toLowerCase()) && (
        <DeleteModal
          visible={visible}
          onOk={deleteProduct}
          isLoading={channel.isFetching}
          onCancel={handleDeleteModalCancels}
          itemName="Product"
        />
      )}

      <Modal
        open={isModalOpen}
        onCancel={handleOnCancel}
        key={isModalOpen ? "modal-open" : "modal-closed"}
        footer={null}
        className="min-w-max"
      >
        <ViewSubscriptionForm data={props?.data} />
      </Modal>
    </>
  );
};

export default Component;
