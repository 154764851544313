import { ReactComponent as POSIcon } from "../icons/dashboard/pos_vector_icon.svg";
import { ReactComponent as CustomersIcon } from "../icons/dashboard/customrs_vector_icon.svg";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import HandymanIcon from "@mui/icons-material/Handyman";
import SchoolIcon from "@mui/icons-material/School";

export const listManages = [
  {
    title: "Sales",
    path: "/home",
    icon: <POSIcon className="SamIcon" />,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Customers",
    path: "/overview/customers",
    icon: <CustomersIcon className="SamIcon" />,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "hr",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Repair",
    path: "/repair/overview",
    icon: <HandymanIcon className="SamIcon" />,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
];

export const SystemRoutes = [
  {
    title: "Switch Users",
    path: "/login",
    icon: <PersonIcon className="SamIcon" />,
    roles: [
      "sales-agent",
      "sales-manager",
      "sales-supervisor",
      "admin",
      "dev",
      "mtn-sales-agent",
    ],
  },
  {
    title: "Logout",
    path: "/login",
    icon: <LogoutIcon className="SamIcon" />,
    roles: [
      "admin",
      "finance",
      "finance-manager",
      "inventory",
      "sales-manager",
      "sales-agent",
      "sales-supervisor",
      "hr",
      "dev",
      "mtn-sales-agent",
    ],
  },
];
