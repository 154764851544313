import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import { Stack } from "@mui/material";
import CustomerCard from "../../components/grids/CustomerGridCard";

import { useDispatch, useSelector } from "react-redux";

import AddNewCustomer from "../../components/forms/NewCustomer";
import { getAllCustomersAction } from "../../store/customer/actions";
import ScrollableFrame from "../../components/layout/ScrollableFrame";
import SearchInput from "../../components/buttons/SearchButton";

const PageView = () => {
  const { customer, auth } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchKey, setSearchKey] = React.useState();
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(25);
  const dispatch = useDispatch();

  const handleCancel = () => {
    console.log("Clossing.....");
    setIsModalOpen(false);
  };
  useEffect(() => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        customer?.query || `?page=${page}&limit=${limit}`
      )(dispatch);
  }, [
    auth,
    customer.new,
    customer?.query,
    customer.updated,
    dispatch,
    limit,
    page,
  ]);

  useEffect(() => {
    if (searchKey === "") {
      auth?.token &&
        getAllCustomersAction(
          auth?.token,
          customer?.query || `?page=${page}&limit=${limit}`
        )(dispatch);
    }
  }, [searchKey]);

  const handleSearch = () => {
    auth?.token &&
      getAllCustomersAction(
        auth?.token,
        `?page=${page}&limit=${limit}&sk=${searchKey}`
      )(dispatch);
  };
  return (
    <Stack spacing={1} style={{ marginTop: "1.5rem" }}>
      <div className="flex items-center justify-between px-6 mb-4 pr-12">
        <SearchInput
          onSearch={handleSearch}
          onChange={(e: any) => {
            e.preventDefault();
            setSearchKey(e.target.value);
          }}
        />
        <HeaderComponent
          // title="Manage Products"
          // overview="Overview"
          item="Customer"
          modelTitle="Add Customer"
          // listSummary={listSummary}
          ModelComponent={
            <AddNewCustomer onCancel={handleCancel} limit={limit} page={page} />
          }
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
        />
      </div>

      <Stack spacing={1}>
        <ScrollableFrame
          loading={customer?.isFetching}
          setPage={setPage}
          setLimit={setLimit}
          limit={limit}
          total={customer?.all?.total}
          count={Math.ceil(customer?.all?.total / limit)}
        >
          <div className="flex flex-wrap gap-4 py-2 rounded-lg">
            {customer?.all?.data?.map((cust: any) => (
              <CustomerCard data={cust} />
            ))}
          </div>
        </ScrollableFrame>
      </Stack>
    </Stack>
  );
};

export default PageView;
