import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Modal } from "antd";
import { getActiveShop } from "../../utils/converter";
import { myAppSettings } from "../../store/appSettings";

export default function SwitchShopButton(props: any) {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const [isModalOpen, setIsmodalOpen] = React.useState(false);

  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [selectedShop, setSelectedShop] = React.useState<String>();

  const [activeShops, setActiveShops] = React.useState([]);

  const onFinish = async () => {
    dispatch(myAppSettings.setSelectedShopNumber(selectedIndex));
    setIsmodalOpen(false);
    localStorage.setItem("selectedShopIndex", selectedIndex.toString());
  };

  React.useEffect(() => {
    setActiveShops(getActiveShop(auth?.user?.shop?.assigned));
  }, [auth?.user?.shop?.assigned]);

  return (
    <React.Fragment>
      <Modal
        title="Confirmation"
        open={isModalOpen}
        onCancel={() => {
          setIsmodalOpen(false);
        }}
        onOk={onFinish}
        okButtonProps={{ loading: auth?.isFetching, className: "bg-blue-500" }}
        okText="Switch"
        cancelButtonProps={{ loading: auth?.isFetching }}
      >
        <p className="text-xl">
          Are you sure you want to switch to <b>{selectedShop}</b>
        </p>
      </Modal>
      <div className="flex flex-col items-center">
        <Avatar.Group
          maxCount={1}
          maxPopoverTrigger="click"
          size="large"
          maxStyle={{
            color: "#f56a00",
            backgroundColor: "#fde3cf",
            cursor: "pointer",
          }}
        >
          {activeShops?.map(({ shop, channel }: any, index: number) => {
            if (index === selectedIndex) return null;
            return (
              <Tooltip title={shop?.name} placement="top">
                <Avatar
                  src={channel?.logo}
                  key={shop?._id}
                  onClick={(e: any) => {
                    setSelectedShop(shop?.name + "~" + shop?.type);
                    setIsmodalOpen(true);
                    setSelectedIndex(index);
                  }}
                >
                  {shop.name?.charAt(0)}
                </Avatar>
              </Tooltip>
            );
          })}
        </Avatar.Group>
        <p className="text-[10px]">Switch Shop</p>
      </div>
    </React.Fragment>
  );
}
