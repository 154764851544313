import React, { useEffect, useState, useRef } from "react";
import { Form, Input, Modal, notification } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import UploadComponent from "../../../components/forms/Upload";
import { updateCustomerAction } from "../../../store/customer/actions";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  handleNextStep,
  handlePreviousStep,
} from "../../../store/layout/actions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Webcam, { WebcamProps } from "react-webcam";
import { customerProfileAction } from "../../../store/pos/actions";
import { url } from "inspector";
import { myPosActions } from "../../../store/pos";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const UploadDocs = (props: any) => {
  const { wareHouse, customer, auth, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [nationalIDDoc, setNationalIDDoc] = useState<any | null>(null);
  const [MomoStatementDoc, setMomoStatementDoc] = useState<any>([]);
  const [moreDocuments, setMoreDocuments] = useState([{ document: "" }]);
  const [showOtherDocument, setShowOtherDocument] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("upload");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const webcamRef = useRef<Webcam | null>(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  console.log(`docs <<<>>>>>>>Id= ${nationalIDDoc}`);

  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const witnessInfo =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.witnessInfo;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];

  const onFinish = async (values: any) => {
    if (wareHouse?.createdCart?.data?.customer?._id && address && witnessInfo) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: address,
            witnessInfo: witnessInfo,
            nationalIDDoc: nationalIDDoc
              ? pos?.customerProfile?.data?.secure_url ||
                (nationalIDDoc && nationalIDDoc[0]?.response?.data?.secure_url)
              : nationalId,
            MomoStatementDoc:
              MomoStatementDoc.length === 0
                ? momoStatement
                : [MomoStatementDoc[0]?.response?.data?.secure_url],
          },
        }
      )(dispatch);
      await dispatch(myPosActions.setCustomerProfile(null));
      await handleNextStep(3)(dispatch);
    } else {
      notification.info({ message: "Missing Witness Details" });
    }
  };

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.customer?.subscriptionInfo) {
      form.setFieldsValue(
        wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      );
    } else {
      form.resetFields();
    }
  }, [form, wareHouse?.createdCart?.data?.customer?.subscriptionInfo]);

  const initialData = {
    nationalIDDoc: nationalId,
    MomoStatementDoc: momoStatement,
  };

  const handlePrevious = async () => {
    await handlePreviousStep(3)(dispatch);
  };
  const handleAddDocument = () => {
    setShowOtherDocument(true);
    if (moreDocuments.length === 0) {
      setMoreDocuments([...moreDocuments, { document: "" }]);
    }
  };
  const handleRemoveDocument = (index: number) => {
    const lists = [...moreDocuments];
    lists.splice(index, 1);
    setMoreDocuments(lists);
  };

  useEffect(() => {
    moreDocuments?.length === 0 && setShowOtherDocument(false);
    showOtherDocument === true && setHideButton(true);
  }, [moreDocuments]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handlerDropDown = (value: string) => {
    setSelectedOption(value);
    closeDropdown();
  };

  const videoConstraints: WebcamProps["videoConstraints"] = {
    facingMode: "user",
  };

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: videoConstraints,
      });
      setMediaStream(stream);
      setIsCameraOpen(true);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const closeCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => track.stop());
    }
    setIsCameraOpen(false);
  };

  const captureImage = async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setNationalIDDoc(imageSrc);
      if (imageSrc) {
        customerProfileAction(auth?.token, {
          image: imageSrc,
        })(dispatch);
      }
      setIsCameraOpen(false);
      closeCamera();
    }
  };

  useEffect(() => {
    !isCameraOpen && setIsModalOpen(false);
  }, [isCameraOpen]);

  useEffect(() => {
    !isModalOpen && setMediaStream(null);
  }, [isModalOpen]);

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={initialData || {}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="flex justify-center overflow-y-auto lg:max-2xl:h-56 h-full  flex-col"
      >
        <div className=" h-full p-4 ">
          <div className="flex justify-center gap-x-5">
            <div className="flex-1 rounded-lg space-y-2">
              <div>
                <div className="flex items-center space-x-4">
                  <div className="relative inline-block">
                    <span
                      onClick={toggleDropdown}
                      className="text-gray-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                    >
                      National ID
                      <svg
                        className={`w-2.5 h-2.5 ml-2.5 ${
                          isDropdownOpen ? "transform rotate-180" : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </span>
                    {isDropdownOpen && (
                      <div className="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 border border-gray-200">
                        <ul
                          className="xpx-2 text-sm text-gray-700"
                          aria-labelledby="dropdownDefaultButton"
                        >
                          <li onClick={() => handlerDropDown("camera")}>
                            <span
                              className={`${
                                selectedOption === "camera" &&
                                "bg-[#352794] text-white"
                              } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
                            >
                              Camera
                            </span>
                          </li>
                          <li onClick={() => handlerDropDown("upload")}>
                            <span
                              className={`${
                                selectedOption === "upload" &&
                                "bg-[#352794] text-white"
                              } block px-4 py-2 hover:bg-[#352794] hover:text-white cursor-pointer`}
                            >
                              Upload
                            </span>
                          </li>
                        </ul>
                      </div>
                    )}
                    {selectedOption === "camera" ? (
                      <Form.Item
                        name="CustomerProfile"
                        className=" lg:max-2xl:h-24 lg:max-2xl:-mt-1"
                        rules={[
                          {
                            required:
                              nationalId ||
                              pos?.customerProfile?.data?.secure_url
                                ? false
                                : true,
                            message: "National ID is required",
                          },
                        ]}
                      >
                        <div className="w-full">
                          <p
                            className="ml-5 pb-2 text-sm font-medium text-[#0F0F47] w-32"
                            onClick={openCamera}
                          >
                            Open Camera <CameraAltIcon />
                          </p>
                          {(nationalIDDoc || nationalId) && (
                            <div className="w-32 h-32 lg:max-2xl:w-20 lg:max-2xl:h-20 ml-10">
                              <img
                                src={
                                  pos?.customerProfile?.data?.secure_url ||
                                  nationalId
                                }
                                alt="Captured"
                              />
                            </div>
                          )}
                        </div>
                      </Form.Item>
                    ) : (
                      <div className="w-full">
                        <Form.Item
                          name="nationalIDDoc"
                          className=" w-[260px] lg:max-2xl:w-[220px]"
                          rules={[
                            {
                              required:
                                nationalId ||
                                pos?.customerProfile?.data?.secure_url
                                  ? false
                                  : true,
                              message: "National ID is required",
                            },
                          ]}
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "National ID is required",
                          //   },
                          // ]}
                        >
                          <div className="w-full">
                            <div className="">
                              <p className="px-3 text-sm font-medium text-[#0F0F47]">
                                Upload
                              </p>
                              <UploadComponent
                                setPictures={setNationalIDDoc}
                                default={
                                  nationalId && [
                                    {
                                      url: nationalId,
                                    },
                                  ]
                                }
                                isCard={true}
                                limit={1}
                              />
                            </div>
                          </div>
                        </Form.Item>
                      </div>
                    )}
                  </div>
                  {/* <div className="flex space-x-4 mt-7 lg:max-2xl:mt-0">
                    <Form.Item
                      name="nationalIDDoc"
                      className=" w-[260px] lg:max-2xl:w-[220px]"
                      rules={[
                        {
                          required: true,
                          message: "National ID is required",
                        },
                      ]}
                    >
                      <div className="w-full">
                        <div className="">
                          <p className="p-3 text-sm font-medium text-[#0F0F47]">
                            Upload National ID
                          </p>
                          <UploadComponent
                            setPictures={setNationalIDDoc}
                            default={
                              nationalId && [
                                {
                                  url: nationalId,
                                },
                              ]
                            }
                            isCard={true}
                            limit={1}
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </div> */}
                  <div className="mt-5">
                    <Form.Item name="momo">
                      <div className="w-full">
                        <div className="">
                          <p className="p-3 text-sm font-medium text-[#0F0F47]">
                            Momo Statement
                          </p>
                          <UploadComponent
                            setPictures={setMomoStatementDoc}
                            default={
                              momoStatement && [
                                {
                                  url: momoStatement,
                                },
                              ]
                            }
                            isCard={true}
                            limit={1}
                          />
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="mt-20">
                    {/* {!showOtherDocument && ( */}
                    {/* <LoadingButton onClick={handleAddDocument}>
                      add New Docs
                    </LoadingButton> */}
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showOtherDocument ? (
            <>
              {/* <h1 className="text-base pb-4 font-medium text-[#0F0F47]">
              Other Documents
            </h1> */}
              <div className="grid grid-cols-2">
                {moreDocuments.map((singleDocument, index) => {
                  return (
                    <div>
                      <Form.Item name="document" initialValue={""}>
                        <Input
                          className="pl-4 h-[52px] w-[320px]  ml-2 lg:max-2xl:w-[270px]  bg-[#EFF0F6] border-none"
                          placeholder="Document name"
                        />
                      </Form.Item>
                      <div key={index} className="flex">
                        <Form.Item
                          name="other"
                          className=" w-[260px]"
                          rules={[
                            {
                              required: true,
                              message: "National ID is required",
                            },
                          ]}
                        >
                          <div className="w-full">
                            <UploadComponent
                              setPictures={setNationalIDDoc}
                              isCard={true}
                              limit={1}
                            />
                          </div>
                        </Form.Item>
                        {moreDocuments.length - 1 === index && (
                          <button
                            className="mx-4"
                            onClick={() =>
                              setMoreDocuments([
                                ...moreDocuments,
                                { document: "" },
                              ])
                            }
                          >
                            <PlusOutlined style={{ color: "blue" }} />
                          </button>
                        )}
                        <button
                          className="mx-4"
                          onClick={
                            () => handleRemoveDocument(index)
                            // moreDocuments.length === 0 && setShowOtherDocument(false)
                          }
                        >
                          <MinusCircleOutlined style={{ color: "red" }} />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            ""
          )}
          <div className="flex justify-end gap-x-2 mr-16  lg:max-2xl:mr-20 mt-16 !h-4 box-border">
            <div className="flex items-center space-x-2 ">
              <Form.Item {...tailFormItemLayout}>
                <div className="flex items-center space-x-2 mb-16">
                  <LoadingButton
                    style={{
                      padding: "6px",
                      borderColor: "red",
                      color: "red",
                    }}
                    variant="outlined"
                    loading={customer?.isFetching}
                    onClick={props?.removeCustomer}
                  >
                    {"Discard"}
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    style={{
                      padding: "6px",
                      width: "80px",
                    }}
                    loading={customer?.isFetching}
                    onClick={handlePrevious}
                  >
                    {"Previous"}
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    style={{
                      padding: "6px",
                      width: "80px",
                    }}
                    loading={customer?.isFetching}
                  >
                    {"Next"}
                  </LoadingButton>
                </div>
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
      <Modal title="National ID" open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default UploadDocs;
