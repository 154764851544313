import { notification } from "antd";
import { myProductActions } from ".";
import {
  createService,
  getAllService,
  getAllAttributesService,
  getOneService,
  updateService,
  updateIsDeleteService,
  getOneProductOrder,
  updatePurchaseOrderStatus,
  createPoDeliveryNote,
  getPriceListItemByProduct,
  getOneStockProdService,
  stopStockInService,
} from "./services";

export const createProductAction = (token: string, data: {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await createService(data, token);
      const resAll = await getAllService(token, "");

      // console.log(">>>>>>>:: ", res);
      if (res?.status === 201) {
        dispatch(myProductActions.setNew(res));
        dispatch(myProductActions.setAll(resAll));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllProductsAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getAllService(token, query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setAll(res));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllAttributesAction = (token: string, query?: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getAllAttributesService(token, query);
      console.log(">>>>!!!>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setAttributes(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneProductAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getOneService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteProductsAction = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await updateIsDeleteService(data, token);

      console.log("dispatching the action.....");
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        const resAll = await getAllService(token, "");
        dispatch(myProductActions.setAll(resAll));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log("an error occured......");
      console.log(err);
    }
  };
};

export const updateProductsAction = (
  token: string,
  itemId: string,
  data: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await updateService(itemId, data, token);
      // console.log("dispatching the action.....")
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        const resAll = await getAllService(token, "");
        dispatch(myProductActions.setAll(resAll));
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      // console.log("an error occured......")
      console.log(err);
    }
  };
};

export const updatePoAction = (token: string, itemId: string, data: any) => {
  // console.log(">>>>Data + ID>>>::)) ", itemId + data);
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await updatePurchaseOrderStatus(itemId, data, token);
      // console.log("dispatching the action.....")
      // console.log(">>>>Result>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
        notification.success({ message: "P.O Accepted" });
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      // console.log("an error occured......")
      console.log(err);
    }
  };
};

export const createDeliveryNoteAction = (token: string, data: {}) => {
  // console.log(">>>>>>>:body:<<<< ", data);
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await createPoDeliveryNote(data, token);

      // console.log(">>>>>>>:Result: ", res);
      if (res?.status === 201) {
        dispatch(myProductActions.setNew(res));
        dispatch(myProductActions.setIsFetching(false));
        notification.success({
          message: "Thank You! Your Delivery Note has been sent to SAMPHONE!",
        });
      }
      // else{
      //   notification.warning({message:"We have received your Delivery Note Thank You!"})
      // }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneProductOrderAction = (id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getOneProductOrder(id);
      // console.log(">>>>result>>>::))", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelected(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getPricelistItemByProductAction = (
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getPriceListItemByProduct(token, query);
      // console.log(">>>>>>>:: product details", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setSelectedProduct(res));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOneProductStoreAction = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await getOneStockProdService(id, token);
      // console.log(">>>>!!!>>>::)) ", res);
      if (res?.status === 200) {
        dispatch(myProductActions.setProductStore(res?.data));
        dispatch(myProductActions.setIsFetching(false));
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const shopStockInAction = (id: string, token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(myProductActions.setIsFetching(true));
      const res = await stopStockInService(id, data, token);
      if (res?.status === 200) {
        dispatch(myProductActions.setStoredProduct(res?.data));
        dispatch(myProductActions.setIsFetching(false));
        if (res?.data && res?.data?.failedSerialNumbers?.length > 0) {
          notification.error({
            message: "Verified successfully but these IMEIs have failed",
            description: res?.data?.failedSerialNumbers
              ?.map((el: any) => el)
              ?.join(", "),
          });
        } else {
          notification.success({
            message: "Verified successfully",
          });
        }
      } else if (res?.response?.status === 400) {
        notification.error({
          message: "Failed",
          description: res?.response?.data?.error,
        });
      }
      dispatch(myProductActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
