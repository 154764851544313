import axios from "axios";
import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/login`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const loginByScanService = async (data: any) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${SERVER_URL}/user/loginwithnid`,
      data,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const profileService = async (token: String) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/profile`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};


export const userProfileBasicService = async (email: String,) => {
  try {
    const res = await axios({
      method: "GET",
      url: `${SERVER_URL}/user/userInfo/${email}`,
    });
    return res;
  } catch (err: any) {
    return err?.response;
  }
};

export const getAgentsAssignedToShopService = async (
  token: string,
  id: string
) => {
  return HttpRequest.get(`${SERVER_URL}/assign/agentToshop/${id}`, token);
};

export const updatePINService = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/user/pin/${itemId}`,
    data,
    token
  );
};
