import { Carousel, Form } from "antd";
import SearchBar from "../../../components/SearchBar";
import { LoadingButton } from "@mui/lab";
import { getAllShopStockAction } from "../../../store/wareHouse/actions";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice, getActiveShop } from "../../../utils/converter";
import BarcodeScannerModal from "../../../components/BarcodeScannerModal";
import { useEffect, useState } from "react";
import { myWareHouseActions } from "../../../store/wareHouse";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as SearchItemImage } from "../../../assets/images/Scan to sell Option 2.svg";
import PamentModeDrower from "../../../components/Modals/PamentModeDrower";
import "./style.css";
const POSItemSearch = (props: any) => {
  const { auth, wareHouse, appSettings } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const dispatch = useDispatch();
  const productData = wareHouse?.searchResult;

  const isFound =
    wareHouse?.searchResult?.status === 200 &&
    wareHouse?.searchResult?.data?.isInShop &&
    wareHouse?.searchResult?.data?.product?.status !== "sold";

  const onFinish = async (values: any) => {
    auth?.token &&
      (await getAllShopStockAction(
        auth?.token,
        `?serialNumber=${values?.query}&shopId=${
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId
        }`
      )(dispatch));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  const discardSearchResult = () => {
    dispatch(myWareHouseActions.setSearchResult(null));
  };

  const itemPrice = getActivePrice(
    productData?.data?.product?.requestedItem?.product?.prices
  );
  const hasSubscription =
    productData?.data?.product?.requestedItem?.product?.subscriptionPlan
      ?.length > 0;
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const isCartEmpty = wareHouse?.createdCart?.data?.list?.length < 1;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (wareHouse?.createdCart?.data?.isSubscription === "") {
      setOpen(true);
    } else {
      setOpen(true);
    }
  }, [wareHouse?.createdCart?.data?.isSubscription]);

  const channelId = auth?.user?.shop?.channelId || auth?.user?.shop?.channel;

  return (
    <>
      {wareHouse?.createdCart?.data?.isSubscription === "" && (
        <PamentModeDrower
          showModal={
            channelId === process.env.REACT_APP_Save_The_Children_Channel_ID
              ? true
              : false
          }
        />
      )}
      <div
        className={`relative h-[45%] bg-white rounded-lg text-black ${
          wareHouse?.createdCart?.data?.status === "inquiry"
            ? ""
            : "pl-4 pr-4 pt-2 pb-2"
        }  leading-none box-border`}
      >
        {wareHouse?.createdCart?.data?.status === "inquiry" ? (
          <div className="container rounded-lg">
            <div className="flex flex-col box box-inner justify-between h-[calc(100%-50px)]">
              {!isFound && (
                <div className="flex flex-col">
                  <div className="flex justify-center gap-x-2">
                    <Form onFinish={onFinish}>
                      <Form.Item
                        name="query"
                        className="rounded-lg"
                        // label="Enter serial number"
                        initialValue={scannedCode}
                      >
                        <SearchBar
                          isLoading={wareHouse.isFetching}
                          scannedCode={newSearch ? "" : scannedCode}
                          onChange={handleChange}
                          isDisabled={!isCartEmpty && isSubscripitonPage}
                        />
                      </Form.Item>
                      <div className="py-1">
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.status === 200 &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status !==
                            "sold" && (
                            <p className="font-semibold text-green-500">
                              Product found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchError?.status === 404 && (
                            <p className="font-semibold text-red-500">
                              Product not found
                            </p>
                          )}
                        {!wareHouse?.isFetching &&
                          !wareHouse?.searchResult?.data?.isInShop && (
                            <div className="font-semibold text-yellow-500">
                              {wareHouse?.searchResult?.message}
                            </div>
                          )}
                        {!wareHouse?.isFetching &&
                          wareHouse?.searchResult?.data?.isInShop &&
                          wareHouse?.searchResult?.data?.product?.status ===
                            "sold" && (
                            <div className="font-semibold text-yellow-500">
                              The product is sold
                            </div>
                          )}
                      </div>
                    </Form>
                    <BarcodeScannerModal getCode={getCode} />
                  </div>
                  <div className="flex justify-center">
                    <SearchItemImage className="h-44" />
                  </div>
                </div>
              )}

              {isFound && (
                <div className="relative flex-1 rounded-lg border">
                  <div className="flex gap-2 p-3 pb-4 30px text-[1vw]">
                    <div style={{ width: "7rem" }}>
                      <Carousel autoplay>
                        {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                          (im: any) => (
                            <div>
                              <img src={im} width={"100%"} alt="" />
                            </div>
                          )
                        )}
                      </Carousel>
                    </div>
                    <div className="flex gap-x-4">
                      <div className="flex flex-col gap-y-1">
                        <p className="text-[1.2vw] font-bold">
                          {
                            productData?.data?.product?.requestedItem?.product
                              ?.product?.model
                          }
                        </p>
                        <p>
                          {
                            productData?.data?.product?.requestedItem?.product
                              ?.product?.type
                          }
                        </p>

                        <div className="flex flex-col gap-2">
                          {productData?.data?.product?.requestedItem?.product?.specification?.map(
                            (item: string, _index: number) => (
                              <span className="text-[1vw]">
                                {item[0]}:<b>{item[1]}</b>
                              </span>
                            )
                          )}
                        </div>
                      </div>

                      <div className="border-t border-gray-200 mt-3 pt-2">
                        <p>
                          <b>Extended Warranty :</b>{" "}
                          {`${productData?.data?.product?.requestedItem?.product?.extendedWarranty}%`}
                        </p>
                        <p>
                          <b>In Shop :</b>
                          {productData?.data?.isInShop ? "Yes" : "No"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="absolute bottom-1 w-full flex gap-x-1 !box-border px-2">
                    <p className="flex items-center">
                      <InfoIcon /> This device is eligible for Extended
                      Warranty.
                    </p>
                    <p className="flex-1 text-right text-[1.4vw] font-bold">{`RWF ${itemPrice?.toLocaleString()}`}</p>
                  </div>
                </div>
              )}

              {isFound && (
                <div className="absolute bottom-2 right-2 flex items-center gap-4">
                  {!hasSubscription && isSubscripitonPage && (
                    <div className="text-yellow-500">
                      This model does not support subscription at them moment,
                      please try a different one!
                    </div>
                  )}
                  <div className="flex flex-wrap gap-x-2 ">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      onClick={props?.addToCart}
                      disabled={
                        !productData?.data?.isInShop ||
                        (!hasSubscription && isSubscripitonPage)
                      }
                      // className=" !text-[.6vw]"
                      // startIcon={<AddIcon />}
                      // sx={{ minWidth: "100%" }}
                      loading={wareHouse?.isFetching}
                    >
                      Add To Cart
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      onClick={discardSearchResult}
                      disabled={!productData?.data?.isInShop}
                      // startIcon={<AddIcon />}
                      // sx={{ minWidth: "100%" }}
                      // loading={channel?.isFetching}
                    >
                      Discard
                    </LoadingButton>
                  </div>
                </div>
              )}
            </div>
            <div className="box overlay rounded-sm ">
              <h1 className="text-red-500  text-center uppercase lg:max-xl:text-sm">
                {" "}
                Fill missing Information
              </h1>
              <p className="p-4 lg:max-xl:text-sm">
                {wareHouse?.createdCart?.data?.comment}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-between h-[calc(100%-50px)]">
            {!isFound && (
              <div className="flex flex-col">
                <div className="flex justify-center gap-x-2">
                  <Form onFinish={onFinish}>
                    <Form.Item
                      name="query"
                      className="rounded-lg"
                      // label="Enter serial number"
                      initialValue={scannedCode}
                    >
                      <SearchBar
                        isLoading={wareHouse.isFetching}
                        scannedCode={newSearch ? "" : scannedCode}
                        onChange={handleChange}
                        isDisabled={!isCartEmpty && isSubscripitonPage}
                      />
                    </Form.Item>
                    <div className="py-1">
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.status === 200 &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status !==
                          "sold" && (
                          <p className="font-semibold text-green-500">
                            Product found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchError?.status === 404 && (
                          <p className="font-semibold text-red-500">
                            Product not found
                          </p>
                        )}
                      {!wareHouse?.isFetching &&
                        !wareHouse?.searchResult?.data?.isInShop && (
                          <div className="font-semibold text-yellow-500">
                            {wareHouse?.searchResult?.message}
                          </div>
                        )}
                      {!wareHouse?.isFetching &&
                        wareHouse?.searchResult?.data?.isInShop &&
                        wareHouse?.searchResult?.data?.product?.status ===
                          "sold" && (
                          <div className="font-semibold text-yellow-500">
                            The product is sold
                          </div>
                        )}
                    </div>
                  </Form>
                  <BarcodeScannerModal getCode={getCode} />
                </div>
                <div className="flex justify-center">
                  <SearchItemImage className="h-44" />
                </div>
              </div>
            )}

            {isFound && (
              <div className="relative flex-1 rounded-lg border">
                <p className="text-[#030229] font-medium text-xl pt-2 text-center">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.model
                        }
                      </p>
                      {/* <p className="text-[#030229] text-base font-medium">
                        {
                          productData?.data?.product?.requestedItem?.product
                            ?.product?.type
                        }
                      </p> */}
                <div className="flex gap-20 p-3 pb-4 30px text-[1vw]">
                  <div style={{ width: "7rem" }}>
                    <Carousel autoplay>
                      {productData?.data?.product?.requestedItem?.product?.product?.images?.map(
                        (im: any) => (
                          <div>
                            <img src={im} width={"100%"} alt="" />
                          </div>
                        )
                      )}
                    </Carousel>
                  </div>
                  <div className="flex gap-x-20 mt-5">
                    <div className="flex flex-col gap-y-1">

                      <div className="flex flex-col gap-2">
                        {productData?.data?.product?.requestedItem?.product?.specification?.map(
                          (item: string, _index: number) => (
                            <span className="text-[#030229] text-base font-medium">
                              {item[0]}:<span className="text-[#030229e7] text-sm pl-3">{item[1]}</span>
                            </span>
                          )
                        )}
                      </div>
                    </div>

                    <div className="">
                      <p className="text-[#030229e7] text-sm  plc-3">
                        <span className="text-[#030229] text-base font-medium">Extended Warranty :</span>{" "}
                        {`${productData?.data?.product?.requestedItem?.product?.extendedWarranty}%`}
                      </p>
                      <p className="text-[#030229e7] text-sm  pl-3">
                        <span className="text-[#030229] text-base font-medium">In Shop :</span>
                        {productData?.data?.isInShop ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute bottom-1 w-full flex gap-x-1 !box-border px-2">
                  <p className="flex items-center">
                    <InfoIcon /> This device is eligible for Extended Warranty.
                  </p>
                  <p className="flex-1 text-right text-[1.4vw] text-[#030229] font-medium">{`RWF ${itemPrice?.toLocaleString()}`}</p>
                </div>
              </div>
            )}

            {isFound && (
              <div className="absolute bottom-2 right-2 flex items-center gap-4">
                {!hasSubscription && isSubscripitonPage && (
                  <div className="text-yellow-500">
                    This model does not support subscription at them moment,
                    please try a different one!
                  </div>
                )}
                <div className="flex flex-wrap gap-x-2 ">
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={props?.addToCart}
                    disabled={
                      !productData?.data?.isInShop ||
                      (!hasSubscription && isSubscripitonPage)
                    }
                    // className=" !text-[.6vw]"
                    // startIcon={<AddIcon />}
                    // sx={{ minWidth: "100%" }}
                    loading={wareHouse?.isFetching}
                  >
                    Add To Cart
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    onClick={discardSearchResult}
                    disabled={!productData?.data?.isInShop}
                    // startIcon={<AddIcon />}
                    // sx={{ minWidth: "100%" }}
                    // loading={channel?.isFetching}
                  >
                    Discard
                  </LoadingButton>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default POSItemSearch;
