// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   CartesianGrid,
//   Line,
//   LineChart,
//   Tooltip,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
// } from "recharts";

// const ShopLineChart = () => {
//   const { posOverView } = useSelector((state: any) => state);
//   const [hiddenKeys, setHiddenKeys] = useState<any>([]);
//   const [colors, setColors] = useState<any>({
//     SmartPhone: "#2943D6",
//     FeacturedPhone: "#DE2C2F",
//     Accessories: "#0FA958",
//   });

//   const toggleDataKey = (key: any) => {
//     if (hiddenKeys.includes(key)) {
//       setHiddenKeys(hiddenKeys.filter((k: any) => k !== key));
//     } else {
//       setHiddenKeys([...hiddenKeys, key]);
//     }
//   };
//   const getColor = (key: any) => {
//     return colors[key] || "#8884d8";
//   };

//   const revenueData = posOverView?.todayRevenueStats?.data?.revenue;

//   const data = revenueData?.currentDate?.revenueByHour.map(
//     (item: any, index: any) => {
//       const todayRevenue = item?.revenue;
//       const yesterdayRevenue =
//         revenueData?.comparedDate?.revenueByHour[index].revenue;
//       const hour = item.hour > 12 ? item.hour - 12 + " PM" : item.hour + " AM";
//       const name = hour;

//       return { name, Today: todayRevenue, Yesterday: yesterdayRevenue };
//     }
//   );

//   return (
//     <div className="flex items-center justify-center mx-cauto w-full">
//       <ResponsiveContainer width="100%" height="100%">
//         <LineChart
//           width={800}
//           height={200}
//           data={data}
//           margin={{
//             top: 5,
//             right: 30,
//             left: 20,
//             bottom: 5,
//           }}
//         >
//           <CartesianGrid strokeDasharray="3 3" />
//           <XAxis dataKey="name" fontSize={17}/>
//           <YAxis className="text-lg" fontSize={17}/>
//           <Tooltip />
//           <Line type="monotone" dataKey="Today" stroke="#82ca9d" />
//           <Line
//             type="monotone"
//             dataKey="Yesterday"
//             stroke="#8884d8"
//             activeDot={{ r: 10 }}
//           />
//         </LineChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default ShopLineChart;
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const ShopLineChart = () => {
  const { posOverView } = useSelector((state: any) => state);
  const [hiddenKeys, setHiddenKeys] = useState<any>([]);
  const [colors, setColors] = useState<any>({
    SmartPhone: "#2943D6",
    FeacturedPhone: "#DE2C2F",
    Accessories: "#0FA958",
  });

  const toggleDataKey = (key: any) => {
    if (hiddenKeys.includes(key)) {
      setHiddenKeys(hiddenKeys.filter((k: any) => k !== key));
    } else {
      setHiddenKeys([...hiddenKeys, key]);
    }
  };
  const getColor = (key: any) => {
    return colors[key] || "#8884d8";
  };

  const revenueData = posOverView?.todayRevenueStats?.data?.revenue;

  const data = revenueData?.currentDate?.revenueByHour.map(
    (item: any, index: any) => {
      const todayRevenue = item?.revenue;
      const yesterdayRevenue =
        revenueData?.comparedDate?.revenueByHour[index].revenue;
      const hour = item.hour > 12 ? item.hour - 12 + " PM" : item.hour + " AM";
      const name = hour;

      return { name, Today: todayRevenue, Yesterday: yesterdayRevenue };
    }
  );

  return (
    <div className="flex items-center justify-center mx-cauto w-full h-[30vh]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={300}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" fontSize={17}/>
          <YAxis fontSize={17}/>
          <Tooltip />
          <Line type="monotone" dataKey="Today" stroke="#82ca9d" />
          <Line
            type="monotone"
            dataKey="Yesterday"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShopLineChart;