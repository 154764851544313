import React from "react";
// import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { Select } from "antd";

const MetricsCard = (props: any) => {
  return (
    <div className="relative flex-grow shadow sm:min-w-[390px] bg-white rounded-lg p-4 text-black space-y-3 h-48 xmax-w-[400px]">
      <div className="flex justify-between">
        <span className="text-gray-400 text-lg font-[400]">{props?.title}</span>
        <Select
          defaultValue="All Brand"
          showSearch
          style={{ width: 100, alignItems: "center" }}
          options={[
            { value: "Nokia", label: "Nokia" },
            { value: "Samsung", label: "Samsung" },
          ]}
        />
      </div>
      <div className="flex justify-between">
        <div className="flex gap-x-2 space-x-2s">
          {props?.icon && (
            <div className="flex w-fit h-fit p-3 rounded-md bg-[#0000FF]">
              {props?.icon}
            </div>
          )}
          <div className="flex flex-col flex-1">
            <span className=" text-3xl font-bold">{props?.number}</span>
          </div>
        </div>
        {/* <div className="flex items-center w-fit min-w-[70px] min-h-[30px] text-lg text-[#0FA958] rounded-xl px-2">
          <ArrowUpwardOutlinedIcon className="" />
          {"12.4%"}
        </div> */}
      </div>
      {/* <p className="absolute bottom-2.5 right-6 text-[#6F767E]">
        Compared to 2023-05-20
      </p> */}
    </div>
  );
};

export default MetricsCard;
