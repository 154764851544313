import React from "react";
import { Checkbox, Form, Input, notification } from "antd";
import { Button, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { updatePINCodeAction } from "../../store/auth/authActions";
import { authActions } from "../../store/auth";
import { myShopActions } from "../../store/shop";

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const App = (props: any) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: any) => state);
  const onFinish = async (values: any) => {
    if (auth?.token) {
      if (values.pin !== values.confirmPin) {
        return notification.error({ message: "Password doesn't match!" });
      } else {
        // console.log(">>>>>>>>>>>>>>>>>>>>>>::Success:", values);
        const res = await updatePINCodeAction(
          props?._id,
          { pin: values.pin },
          auth?.token
        )(dispatch);
        if (res) {
          notification.success({ message: "PIN Code updated!" });
          dispatch(authActions.logout());
          dispatch(myShopActions.setMyShop(null));
        }
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="p-6 flex-col justify-center items-center">
      {/* <h1 className="capitalize text-xl text-center">Update PIN Code</h1> */}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input />
      </Form.Item> */}

        <Form.Item
          name="pin"
          label="PIN Code"
          rules={[
            {
              required: true,
              message: "Please input your PIN Code!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmPin"
          label="Confirm PIN Code"
          dependencies={["pin"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your PIN Code!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new PIN Code that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{ minWidth: "100%" }}
            loading={false}
          >
            {props?.data ? "update" : "Save"}
          </LoadingButton>
        </Form.Item>
      </Form>
    </div>
  );
};

export default App;
