import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PaymentsIcon from "@mui/icons-material/Payments";

const AgentSaleKPICard: React.FC<{
  title: string;
  amount: String | number;
  yesterdayAmount: string | number;
  percentage: any;
}> = ({ title, amount, yesterdayAmount, percentage }) => {
  return (
    <div className="relative flex flex-col bg-white rounded-md p-[0.5vw] text-[1vw] leading-[3vh]">
      <p className=" font-medium xl:font-thin mb-[0.2vw] text-[10px]">{title}</p>
      <p className="font-semibold md:text-sm">
        <PaymentsIcon className="!h-[2.5vh]" /> {amount}
      </p>
      <p className="flex text-sm items-center gap-x-2 font-semibold text-gray-400">
        <CompareArrowsIcon className="!h-[2.5vh]"/> {yesterdayAmount}
      </p>
      <div className="absolute top-2 right-2">
        <p
          className={`text-[10px] ${
            percentage?.type === "increase"
              ? "text-green-500"
              : percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          }`}
        >
          {percentage?.type === "increase" ? (
            <ArrowDropUpIcon />
          ) : percentage?.type === "decrease" ? (
            <ArrowDropDownIcon />
          ) : (
            ""
          )}{" "}
          {`${percentage?.percentage}%`}
        </p>
      </div>
    </div>
  );
};

export default AgentSaleKPICard;
