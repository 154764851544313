import React, { useState, useEffect } from "react";
import { UserCard } from "../components";
import { chatActions } from "../redux";
import { Input } from "antd";
import {
  getAllConversations,
  getSingleConversation,
  sendMessage,
  startConversation,
} from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { ProfileAvatar } from "../components";
import ChatImage from "../assets/images/start_chat.png";
import moment from "moment";

export const ChatTab = () => {
  const dispatch = useDispatch();
  const { chat, auth } = useSelector((state: any) => state);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    auth.token && getAllConversations(auth.token, `?sk=${searchKey}`)(dispatch);
  }, [auth.token, dispatch, searchKey]);

  return chat?.singleConversation ? (
    <Conversation />
  ) : (
    <UserList changeSearchKey={setSearchKey} />
  );
};

const UserList = ({
  changeSearchKey,
}: {
  changeSearchKey: (key: string) => void;
}) => {
  const dispatch = useDispatch();
  const { conversations, isFetching } = useSelector((state: any) => state.chat);
  const { user, token } = useSelector((state: any) => state.auth);

  return (
    <div className="flex flex-col flex-auto h-full px-4">
      <Input.Search
        onChange={(e) => {
          changeSearchKey(e.target.value);
        }}
        placeholder="Enter user's name"
        style={{
          width: "100%",
          margin: "0 0 10px 0",
        }}
      />
      <div
        style={{
          height: "48vh",
          overflowY: "auto",
        }}
      >
        {conversations?.length > 0 ? (
          conversations?.map((conversation: any, index: any) => (
            <UserCard
              key={index}
              picture={
                conversation?.picture ||
                conversation?.participants?.find(
                  (participant: any) => participant?._id != user?._id
                )?.picture
              }
              _id={
                conversation.initialised
                  ? conversation?.participants?.find(
                      (participant: any) => participant._id !== user?._id
                    )?._id
                  : conversation?._id
              }
              lastMessage={conversation.lastMessage?.body || null}
              names={
                conversation?.names ||
                conversation?.participants?.find(
                  (participant: any) => participant?._id != user?._id
                )?.names
              }
              role={
                conversation?.role ||
                conversation?.participants?.find(
                  (participant: any) => participant?._id != user?._id
                )?.role
              }
              list={true}
              isLoading={isFetching}
              rightButtonName={
                conversation.initialised ? "View chat" : "Start chat"
              }
              rightButtonAction={
                conversation.initialised
                  ? () => {
                      getSingleConversation(token, conversation?._id)(dispatch);
                    }
                  : () => {
                      startConversation(token, {
                        participants: [user?._id, conversation._id],
                      })(dispatch);
                    }
              }
            />
          ))
        ) : (
          <p className="text-center">No users or conversations found</p>
        )}
      </div>
    </div>
  );
};

const Conversation = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const { singleConversation } = useSelector((state: any) => state.chat);
  const { user, token } = useSelector((state: any) => state.auth);
  const { socket } = useSelector((state: any) => state.socket);
  let messages = [];
  if (singleConversation?.messages?.length > 0) {
    messages = [...singleConversation?.messages]?.reverse();
  }

  useEffect(() => {
    socket.emit("join_room", singleConversation?._id);
  }, [singleConversation?._id, socket]);

  useEffect(() => {
    socket.on("newMessage", (data: any) => {
      console.log(data, "new message");
      dispatch(chatActions.setSingleConversation(data));
    });

    return () => {
      socket.off("newMessage");
    };
  }, [dispatch, socket]);

  const handleSendMessage = () => {
    if (!message) return;
    sendMessage(token, singleConversation?._id, {
      body: message,
      sender: user?.names?.split(" ")[0],
      receiverId: singleConversation?.participants?.find(
        (participant: any) => participant?._id != user?._id
      )?._id,
    })(dispatch);
    setMessage("");
  };

  return (
    <div>
      <div
        className="px-4"
        style={{
          height: "8vh",
        }}
      >
        <UserCard
          list={false}
          leftButtonAction={() => {
            getAllConversations(token, "")(dispatch);
            dispatch(chatActions.setSingleConversation(null));
          }}
          _id={
            singleConversation?.participants?.find((participant: any) => {
              return participant?._id !== user?._id;
            })?._id
          }
          picture={
            singleConversation?.participants?.find((participant: any) => {
              return participant?._id !== user?._id;
            })?.picture
          }
          names={
            singleConversation?.participants?.find((participant: any) => {
              return participant?._id !== user?._id;
            })?.names
          }
          role={
            singleConversation?.participants?.find((participant: any) => {
              return participant?._id !== user?._id;
            })?.role
          }
        />
      </div>
      <div className="flex h-fit antialiased text-gray-800">
        <div className="flex flex-row h-full w-full overflow-x-hidden">
          <div className="flex flex-col flex-auto max-h-fit px-4">
            <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
              <div className="flex flex-col h-full overflow-x-auto mb-4">
                <div className="flex flex-col h-full">
                  <div
                    style={{
                      height: "35vh",
                      display: "flex",
                      flexDirection: "column-reverse",
                      overflowY: "scroll",
                    }}
                    className="flex flex-col-reverse"
                  >
                    {messages?.length > 0 ? (
                      messages?.map((message: any, index: any) => {
                        return message?.sender._id === user?._id ? (
                          <div
                            key={index}
                            className="col-start-6 col-end-13 p-3 rounded-lg"
                          >
                            <div className="flex items-center justify-start flex-row-reverse">
                              <ProfileAvatar
                                picture={message?.sender.picture}
                                name={message?.sender.names}
                              />
                              <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                                <div>{message?.body}</div>
                              </div>
                            </div>
                            <div className="flex text-[10px] self-end float-right mr-[3.5rem] text-gray-500">
                              {moment(message?.date).fromNow()}
                            </div>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="col-start-1 col-end-10 p-3 rounded-lg"
                          >
                            <div className="flex flex-row items-center">
                              <ProfileAvatar
                                picture={message?.sender.picture}
                                name={message?.sender.names}
                              />
                              <div className="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl">
                                <div>{message?.body}</div>
                              </div>
                            </div>
                            <div className="flex text-[10px] self-start float-left ml-[3.5rem] text-gray-500">
                              {moment(message?.date).fromNow()}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex justify-center items-center flex-col">
                        <img src={ChatImage} alt="Chat" width={"50%"} />
                        No messages, start the convesation by sending a message
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label className="sr-only">Your message</label>
                <div className="flex items-center py-2 px-3 bg-slate-50 rounded-lg">
                  <textarea
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    autoCapitalize="sentences"
                    id="chat"
                    style={{
                      outline: "none",
                    }}
                    rows={1}
                    className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Your message..."
                  ></textarea>
                  <button
                    onClick={handleSendMessage}
                    className="inline-flex justify-center p-2 text-blue-600 rounded-full cursor-pointer hover:bg-blue-100"
                  >
                    <svg
                      className="w-6 h-6 rotate-90"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
