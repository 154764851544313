import React, { useState } from "react";
import DangerButton from "../../../components/buttons/DangerButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import ProductDetailsModal from "../../../components/Modals/ProductDetailsModal";
import AddNewCustomer from "../../../components/forms/NewCustomer";
import { Carousel } from "antd";
import PhoneSvg from "../../../assets/icons/phone.svg";
import NidSvg from "../../../assets/icons/nid.svg";
import Emailvg from "../../../assets/icons/email.svg";

const SidePanelDetails = (props: any) => {
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  const handleOnCancel = () => {
    setShowCustomerModal(false);
  };

  const handleIsEdit = () => {
    setShowCustomerModal(true);
  };

  return (
    <div>
      <div className="flex flex-col justify-center items-center rounded-lg px-2 bg-white w-[300px] space-y-6">
        <div className="-mt-10">
          <div className="flex items-center justify-center bg-slate-200 w-[140px] h-[140px] rounded-full">
            <div className="flex items-center justify-center bg-slate-300 w-[125px] h-[125px] rounded-full">
              <Carousel
                autoplay
                className="bg-white p-4 w-[110px] h-[110px] rounded-full overflow-clip"
              >
                <div>
                  <img
                    className="h-auto w-[90px] rounded-full m-auto"
                    src={props?.simpleDetails?.picture}
                    alt="description"
                  />
                </div>
              </Carousel>
            </div>
          </div>
          <div className="text-center font-semibold mt-4 capitalize">
            <p>{props?.simpleDetails?.name}</p>
            {props?.simpleDetails?.tinNumber && (
              <p className="font-thin text-xs bg-gray-100 w-fit rounded-lg px-3 mx-auto border border-gray-300 shadow text-blue-400">
                {props?.simpleDetails?.tinNumber}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-wrap rounded-md border-t-2 p-4 w-full pl-5">
          {props?.simpleDetails && (
            <>
              <div className="flex flex-col">
                <div className="flex">
                  <img src={PhoneSvg} className="w-6 mr-2" alt="" />
                  <span className="px-2 text-[#0F0F47] rounded">
                    phone
                  </span>{" "}
                </div>
                <div>
                  <span className="py-0.5 px-2 ml-8 font-medium rounded text-[#0F0F47] space-x-2">
                    {props?.simpleDetails?.phone}
                  </span>
                </div>
                <p className=" pb-3"></p>
              </div>
              <div className="flex flex-col">
                <div className="flex">
                  <img src={NidSvg} className="w-6 mr-2" alt="" />
                  <span className="px-2 text-[#0F0F47] rounded">
                    National ID
                  </span>{" "}
                </div>
                <div>
                  <span className="py-0.5 px-2 ml-8 font-medium rounded text-[#0F0F47] space-x-2">
                    {props?.simpleDetails?.nid}
                  </span>
                </div>
                <p className=" pb-3"></p>
              </div>
              <div className="flex flex-col">
                <div className="flex">
                  <img src={Emailvg} className="w-6 mr-2" alt="" />
                  <span className="px-2 text-[#0F0F47] rounded">
                    Email
                  </span>{" "}
                </div>
                <div>
                  <span className="py-0.5 px-2 ml-8 font-medium rounded text-[#0F0F47] space-x-2">
                    {props?.simpleDetails?.email}
                  </span>
                </div>
                <p className=" pb-3"></p>
              </div>
              <div className="flex flex-col">
                <div className="flex">
                  <svg
                    viewBox="0 0 21 21"
                    fill="currentColor"
                    height="1.6em"
                    width="2em"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(4 2)"
                    >
                      <path d="M6.5 16.54l.631-.711c.716-.82 1.36-1.598 1.933-2.338l.473-.624c1.975-2.661 2.963-4.773 2.963-6.334C12.5 3.201 9.814.5 6.5.5s-6 2.701-6 6.033c0 1.561.988 3.673 2.963 6.334l.473.624a54.84 54.84 0 002.564 3.05z" />
                      <path d="M9 6.5 A2.5 2.5 0 0 1 6.5 9 A2.5 2.5 0 0 1 4 6.5 A2.5 2.5 0 0 1 9 6.5 z" />
                    </g>
                  </svg>
                  <span className="px-2 text-[#0F0F47] rounded">Address</span>{" "}
                </div>
                <div>
                  <span className="py-0.5 px-2 ml-8 font-medium rounded text-[#0F0F47] space-x-2 capitalize">
                    {props?.simpleDetails?.address}
                  </span>
                </div>
                <p className=" pb-3"></p>
              </div>
            </>
          )}
        </div>
        <div className="flex justify-center gap-2">
          <PrimaryButton name="Edit" onClick={handleIsEdit} />
          <DangerButton name="Delete" onClick={props?.handleClickDelete} />
        </div>
        <span className="text-center uppercase font-medium pb-4">
          {props?.simpleDetails?.type}
        </span>
      </div>
      {/* Customer modal */}
      {
        <ProductDetailsModal
          modalTitle={"Customer Infomation"}
          component={
            <AddNewCustomer
              action={"update"}
              dataToUpdate={props?.simpleDetails}
              onCancel={handleOnCancel}
            />
          }
          isModalOpen={showCustomerModal}
          handleOnCancel={handleOnCancel}
        />
      }
    </div>
  );
};

export default SidePanelDetails;
