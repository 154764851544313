import React, { useState, useEffect } from "react";
import { Radio, Modal, Alert } from "antd";
// import { handlePaymentMode } from "../../store/layout/actions";
import { useDispatch, useSelector } from "react-redux";
import type { RadioChangeEvent } from "antd";
import { useParams } from "react-router-dom";
import { updateShopStockCartDetailsAction } from "../../store/wareHouse/actions";

const PamentModeDrower: React.FC<{
  showModal: boolean;
}> = ({ showModal }) => {
  const { auth } = useSelector((state: any) => state);
  const [isActive, setIsActive] = useState("");

  const dispatch = useDispatch();
  const { cartId } = useParams();

  const onChange = async (e: RadioChangeEvent) => {
    await updateShopStockCartDetailsAction(auth?.token, cartId, {
      isSubscription: e.target.value,
    })(dispatch);
    setIsActive(e.target.value);
  };

  const channelId = auth?.user?.shop?.channelId || auth?.user?.shop?.channel;

  useEffect(() => {
    channelId !== process.env.REACT_APP_Save_The_Children_Channel_ID &&
      updateShopStockCartDetailsAction(auth?.token, cartId, {
        isSubscription: "pos",
      })(dispatch);
  }, [auth?.token, channelId, dispatch]);

  return (
    <>
      <Modal
        title="Select Buying Mode"
        footer={null}
        closable={false}
        open={showModal}
      >
        <div className="xmt-24">
          <div className="flex flex-col items-center justify-around w-full h-[15vh]">
            <Radio.Group
              onChange={onChange}
              value={isActive}
              className=" flex flex-row items-center justify-around w-full"
            >
              <Radio
                value={"pos"}
                className={`w-40 h-12 border p-4 rounded-md border-gray-400 ${
                  isActive && "bg-[#7461e0] text-white"
                } flex items-center justify-center gap-4`}
              >
                POS
              </Radio>
              <Radio
                value={"subscription"}
                className={`w-40 h-12 border p-4 rounded-md border-gray-400 ${
                  isActive && "bg-[#7461e0] text-white"
                } flex items-center justify-center gap-4`}
              >
                B2C
              </Radio>
            </Radio.Group>
            <Alert
              message="Selecting Buying mode is required! Kindly make choice Now!"
              type="info"
              showIcon
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PamentModeDrower;
