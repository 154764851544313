import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { Select } from "antd";

export function PaginationControlled(props: any) {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props?.setPage(value);
  };
  const handleChangePages = (value: string) => {
    props?.setLimit(value);
  };

  return (
    <div className="flex justify-end items-center text-black">
      <Select
        defaultValue={props?.limit}
        style={{ width: 120 }}
        onChange={handleChangePages}
        options={
          props?.pages || [
            { value: 15, label: "15 / Page" },
            { value: 20, label: "20 / Page" },
            { value: 25, label: "25 / Page" },
            { value: 30, label: "30 / Page" },
            { value: 35, label: "35 / Page" },
          ]
        }
      />
      <Pagination
        count={props?.count || 1}
        page={props?.page}
        onChange={handleChange}
      />
      <span>Results: {props?.total}</span>
    </div>
  );
}
