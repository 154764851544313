import React, { useCallback, useEffect, useRef, useState } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import { Modal } from "antd";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { CloseOutlined } from "@ant-design/icons";

const BarcodeScannerModal: React.FC<{
  isLogin?: boolean;
  getCode: (code: string) => void;
}> = ({ isLogin, getCode }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [code, setCode] = useState("");

  const scanner = useRef(new BrowserMultiFormatReader());

  const openModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, [scanner]);

  const handleScan = useCallback(
    (result: any) => {
      setCode(result.getText());
      getCode(result.getText());
      closeModal();
      scanner.current.reset();
    },
    [closeModal, getCode]
  );

  const handleError = useCallback(
    (error: any) => {
      console.error(error);
      closeModal();
    },
    [closeModal]
  );

  const startScanning = useCallback(() => {
    scanner.current
      .decodeOnceFromVideoDevice(undefined, "video")
      .then(handleScan)
      .catch(handleError);
  }, [handleError, handleScan, scanner]);

  useEffect(() => {
    if (modalIsOpen) {
      startScanning();
    } else {
      scanner.current.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalIsOpen]);

  useEffect(() => {
    scanner.current.stopAsyncDecode();
    scanner.current.reset();
  }, [code]);

  return (
    <div className="flex items-start mt-3">
      <button
        onClick={openModal}
        className="flex items-center text-lg xmt-2 gap-x-2"
      >
        {!isLogin && <QrCodeScannerIcon />} Scan
      </button>
      {modalIsOpen && false && (
        <div className="relative">
          <video
            id="video"
            height="70"
            className="w-28 rounded-lg"
            playsInline
            autoPlay
          />

          {/* <button
            className="absolute top-0 left-0 m-4 p-2 rounded-md bg-blue-500 text-white"
            onClick={startScanning}
          >
            Start Scan
          </button> */}
        </div>
      )}
      <Modal
        open={modalIsOpen}
        onCancel={closeModal}
        okButtonProps={{ style: { display: "none" } }}
        closeIcon={
          <CloseOutlined
            style={{
              position: "absolute",
              top: "0",
              color: "black",
              fontSize: "20px",
            }}
          />
        }
      >
        {modalIsOpen && (
          <div className="relative">
            <video
              id="video"
              height="300"
              className="w-full rounded-lg"
              playsInline
              autoPlay
            ></video>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default BarcodeScannerModal;
