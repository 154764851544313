import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Table } from "antd";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import "./samphonePayments.style.css";

type Payload = {
  OverAllPercentage: any;
  amount: number;
  yesterdayAmount: number;
  pieces?: number;
  yesterdayPieces?: number;
  title?: string;
  data: {
    name: string;
    value: number;
    yesterday: number;
    today: number;
    percentage: number;
  }[];
};

const colors = [
  "#0D0D77",
  "#4848FA",
  "#3786FD",
  "#6969EC",
  "#8884d8",
  "#8dd1e1",
  "#0000FF",
];

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (item: any) => {
      return (
        <div className="flex items-center w-full xtruncate gap-1 ">
          <span
            className={`h-2 w-2 bg-[${item?.color}] rounded-full`}
            style={{ backgroundColor: item?.color }}
          ></span>
          <span className=" text-sm truncate">{item?.value}</span>
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "today",
    key: "today",
    render: (text: { today: string; yesterday: string }) => (
      <div className="dashed-border-cell truncate">
        <p>{`RWF ${text.today}`}</p>
        <p className="text-gray-400">{`RWF ${text.yesterday}`}</p>
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "percentage",
    key: "percentage",
    render: (text: string, record: any) => {
      return (
        <span
          className={`flex ${
            record?.percentage?.type === "increase"
              ? "text-green-500"
              : record?.percentage?.type === "decrease"
              ? "text-red-500"
              : "text-blue-900"
          }  text-sm items-center`}
        >
          {" "}
          {record?.percentage?.type === "increase" ? (
            <ArrowUpwardIcon className="!w-[5px]" />
          ) : record?.percentage?.type === "increase" ? (
            <ArrowDownwardIcon className="!w-[5px]" />
          ) : (
            ""
          )}{" "}
          {`${record?.percentage?.percentage} %`}
        </span>
      );
    },
  },
];

const SamphonePayments: React.FC<Payload> = (props) => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel: React.FC<{
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    percent: any;
    index: any;
  }> = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const data = props.data[index];

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        className="text-[11px]"
      >
        {`${data.value.toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className=" p-4 relative !min-w-[450px]">
      <p className="text-gray-400 capitalize mb-2 text-base">{props.title}</p>

      <div className="h-[100px] xw-80 mt-10">
        <ResponsiveContainer width="100%" height="100%" className={""}>
          <PieChart width={600} height={600}>
            <Pie
              data={props?.data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={50}
              labelLine={false}
              label={renderCustomizedLabel}
              legendType="rect"
              fontSize={40}
            >
              {props?.data?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
            <Legend
              align="left"
              width={400}
              verticalAlign="middle"
              layout="vertical"
              className="w-fit"
              content={(props: any) => {
                const { payload } = props;

                const generateData = () => {
                  const data: any[] = [];
                  payload?.forEach((element: any) => {
                    data?.push({
                      name: { value: element?.value, color: element?.color },
                      today: {
                        today:
                          element?.payload?.payload?.today?.toLocaleString(),
                        yesterday:
                          element?.payload?.payload?.yesterday?.toLocaleString(),
                      },
                      percentage: element?.payload?.payload?.percentage,
                    });
                  });
                  return data;
                };
                const data = generateData();

                return (
                  <div className="w-[13rem] md:w-64 mt-12 pb-20 ">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      className="custom-table"
                      // scroll={{ y: 300 }}
                      rowClassName={(record: any, index: number) => {
                        if (index === data.length - 1) {
                          return "x!border-b !border-dashed !m-0 !p-0";
                        } else if (index === 0) {
                          return "dashed-top-border !m-0 !p-0";
                        }
                        return " !m-0 !p-0";
                      }}
                      showHeader={false}
                    />
                  </div>
                );
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SamphonePayments;
