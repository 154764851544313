import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import MomoPaymentForm from "../../../components/forms/MomoPaymentForm";
import MomoWaitingAndSuccessForm from "../../../components/forms/MomoWaitingAndSuccessForm";
import POSSteps from "./POSSteps";
import AddToCart from "./steps/AddToCart";
import { useParams } from "react-router";
import {
  getShopStockCartAction,
  updateShopStockCartAction,
  updateShopStockCartDetailsAction,
  updateWareHouseDetailsAction,
} from "../../../store/wareHouse/actions";
import ConfirmCheckout from "./steps/ConfirmCheckout";
import {
  getActivePlan,
  getActivePrice,
  getActiveShop,
  getLastPaymentStatus,
  getMomoPayments,
  getTotalPaidAmount,
  getTotalPrice,
  toIsoDate,
} from "../../../utils/converter";
import {
  checkoutCartAction,
  generateEBMInvoiceAction,
  generateFirstInvoiceAction,
} from "../../../store/pos/actions";
import { useNavigate } from "react-router-dom";
import { notification, Modal } from "antd";
import { myWareHouseActions } from "../../../store/wareHouse";
import { Box, Skeleton, Stack, useMediaQuery } from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { myCustomerActions } from "../../../store/customer";
import { Exception } from "@zxing/library";
import { myPosActions } from "../../../store/pos";
import RRAForm from "../../../components/forms/RRAForm";

dayjs.extend(utc);
dayjs.extend(timezone);

const SaleWithPOS = () => {
  const { auth, wareHouse, customer, pos, appSettings, layout } = useSelector(
    (state: any) => state
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRRAModalOpen, setIsRRAModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentMomoModalOpen] = useState(false);
  const isTablet = useMediaQuery("(max-width: 1200px)");

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNewItemAdded, setIsNewItemAdded] = useState<any>(false);
  const { cartId } = useParams();
  const [confirmationDate, setConfirmationDate] = useState<any>(
    dayjs().format("DD/MM/YYYY")
  );
  const [current, setCurrent] = useState(0);
  const isSubscripitonPage =
    wareHouse?.createdCart?.data?.isSubscription === "subscription";

  const subsPlans = wareHouse?.createdCart?.data?.list?.map(
    (plan: any, index: number) => plan?.priceProduct?.subscriptionPlan
  );

  const ActivePlan = getActivePlan(
    subsPlans,
    wareHouse?.createdCart?.data?.subscriptionPlan
  );

  const discountPerc = wareHouse?.createdCart?.data?.discount?.discountPerc;
  const totalPrice = getTotalPrice(wareHouse?.createdCart?.data?.list);
  const payment = getTotalPaidAmount(wareHouse?.createdCart?.data?.payment);
  const finalAmount = isSubscripitonPage
    ? `${
        wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
          ? ActivePlan && ActivePlan.at(0)?.initialPayment
          : `${
              wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
            }`
      }`
    : totalPrice - totalPrice * (discountPerc / 100);

  const isMacyeMacye = () => {
    const hasMacyeMacye = wareHouse?.createdCart?.data?.payment?.some(
      (payment: any) =>
        [
          "Macye_Macye",
          "Vuba_Vuba",
          "Intelligra_Insurance",
          "Corporate_Sales",
          "Yello_Payment",
          "Samphone_Staff_Handset_Requisition",
        ].includes(payment?.mode)
    );
    return hasMacyeMacye;
  };

  const onChangeDate: DatePickerProps["onChange"] = async (
    date,
    dateString
  ) => {
    setConfirmationDate(dateString);
    // setConfirmationDate(dateString);
  };

  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, dispatch]);

  useEffect(() => {
    if (customer?.searchResult?.length > 0) {
      auth?.token &&
        updateShopStockCartDetailsAction(auth?.token, cartId, {
          customer: customer?.searchResult[0]?._id,
        })(dispatch);
    }
  }, [customer?.searchResult, cartId, dispatch, auth?.token]);

  useEffect(() => {
    if (pos?.paymentInitiationStatus) {
      setIsModalOpen(false);
      setIsPaymentMomoModalOpen(true);
    } else {
      setIsPaymentMomoModalOpen(false);
    }
  }, [pos.paymentInitiationStatus]);

  const addToCart = async () => {
    const product = wareHouse?.searchResult?.data?.product;
    const payload = {
      cart: cartId,
      shopStockItem: product?._id,
      warehouseProduct: product?.wareHouseItem?._id,
      channel: product?.shop?.channel?._id,
      shop: product?.shop?._id,
      priceProduct: product?.requestedItem?.product?._id,
      payment: {
        amount: +getActivePrice(product?.requestedItem?.product?.prices),
        extendedWarranty: 0,
        installementPayment: `${
          wareHouse?.createdCart?.data?.initialPaymentType === "b2c_prepaid"
            ? ActivePlan && ActivePlan.at(0)?.initialPayment
            : `${
                wareHouse?.createdCart?.data?.instalmentPlan === "daily"
                  ? ActivePlan && ActivePlan.at(0)?.dailyInstalment
                  : wareHouse?.createdCart?.data?.instalmentPlan === "weekly"
                  ? ActivePlan && ActivePlan.at(0)?.weeklyInstalment
                  : (ActivePlan && ActivePlan.at(0)?.monthlyInstalment) || 0
              }`
        }`,
      },
    };

    auth?.token &&
      (await updateShopStockCartAction(auth?.token, cartId, payload)(dispatch));
    dispatch(myWareHouseActions.setSearchResult(null));
    await updateWareHouseDetailsAction(
      auth?.token,
      product?.wareHouseItem?._id,
      {
        dataActivated: false,
      }
    )(dispatch);
  };

  const handleCheckout = async () => {
    if (payment === undefined) {
      notification.warning({ message: "Please provide payments!" });
    } else if (!wareHouse?.createdCart?.data?.customer) {
      notification.warning({ message: "Please add a customer!" });
    } else if (+finalAmount === payment || isMacyeMacye()) {
      if (
        getMomoPayments(wareHouse).length > 0 &&
        process.env.REACT_APP_ShopId_with_Momo_Implementation?.includes(
          shopId
        ) &&
        layout?.isDisabledMomo
      ) {
        setIsModalOpen(true);
      } else {
        if (auth?.token) {
          const res = await checkoutCartAction(auth?.token, cartId as string, {
            status: "paid",
            paidOn: toIsoDate(confirmationDate),
          })(dispatch);
          if (false) {
            await generateEBMInvoiceAction(
              auth?.token,
              {
                cartId: cartId,
              },
              cartId as string
            )(dispatch);
          }
          if (process.env.REACT_APP_Save_The_Children_Channel_ID) {
            await generateFirstInvoiceAction(
              auth?.token,
              cartId as string
            )(dispatch);
          }
          if (res || pos?.checkoutResponseStatus === 200) {
            setCurrent(current + 1);
          }
        }
        // navigate("/");
        // setIsRRAModalOpen(true);
      }
    } else {
      notification.error({
        message: "Paid amount must be equal to total amount!",
      });
    }
  };

  useEffect(() => {
    if (
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "paid" ||
      wareHouse?.createdCart?.data?.status?.toLocaleLowerCase() === "cancelled"
    ) {
      setCurrent(1);
    }
  }, [wareHouse?.createdCart?.data?.status]);

  useEffect(() => {
    dispatch(myCustomerActions.setSelected(null));
  }, [dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePaymentCancel = () => {
    setIsPaymentMomoModalOpen(false);
    dispatch(myPosActions.setPaymentInitiationStatus(false));
  };

  const steps = [
    {
      title: "Add to cart",
      content: (
        <AddToCart
          isNewItemAdded={isNewItemAdded}
          setIsNewItemAdded={setIsNewItemAdded}
          addToCart={addToCart}
          confirmationDate={confirmationDate}
          onChangeDate={onChangeDate}
          handleCheckout={handleCheckout}
          disableCheckout={+finalAmount !== payment}
        />
      ),
    },
    // {
    //   title: "Customer and Payment",
    //   content: <UserInfoAndPaymentMethod cartId={cartId} />,
    // },
    {
      title: "Checkout",
      content: (
        <ConfirmCheckout
          confirmationDate={confirmationDate}
          onChangeDate={onChangeDate}
        />
      ),
    },
  ];

  const handleCloseRRAModel = () => {
    setIsRRAModalOpen(false);
  };

  useEffect(() => {
    layout?.isMissingRRAcode && setIsRRAModalOpen(true);
  }, [layout?.isMissingRRAcode]);

  return (
    <>
      <div className="flex flex-col h-full text-black">
        {wareHouse?.isFetching && !wareHouse?.createdCart && (
          <Box>
            <Skeleton sx={{ height: "200px", margin: 0 }} />
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
              <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
              <Skeleton sx={{ height: "15rem", width: "30%", margin: 0 }} />
            </Stack>
          </Box>
        )}
        {(!wareHouse?.isFetching || wareHouse?.createdCart) && (
          <>
            <Modal
              // title={"Pay with MoMo"}
              open={isModalOpen}
              // onCancel={handleCancel}
              footer={null}
              className="min-w-min"
              // icon={props?.icon || <HomeIcon />}
            >
              <div>
                <div className={`${isTablet ? "w-[100vh]" : "w-[50vh]"}`}>
                  {
                    <MomoPaymentForm
                      auth={auth}
                      warehouse={wareHouse}
                      onCancel={handleCancel}
                    />
                  }
                </div>
              </div>
            </Modal>
            <Modal
              title={""}
              open={isPaymentModalOpen}
              // onCancel={handlePaymentCancel}
              footer={null}
              className="min-w-min"
              // icon={props?.icon || <HomeIcon />}
            >
              <div className="relative">
                <div className="w-[100vh]">
                  {
                    <MomoWaitingAndSuccessForm
                      auth={auth}
                      warehouse={wareHouse}
                      onCancel={handlePaymentCancel}
                    />
                  }
                </div>
                <span
                  onClick={handlePaymentCancel}
                  className="absolute -top-2 right-3 cursor-pointer border px-2 bg-blue-500 text-white rounded-md"
                >
                  Back
                </span>
              </div>
            </Modal>
            <POSSteps
              steps={steps[current]}
              handleFinish={handleCheckout}
              status={wareHouse?.createdCart?.data?.status}
            />
          </>
        )}
        <div className=""></div>
      </div>

      <Modal
        // title={"RRA"}
        open={isRRAModalOpen}
        footer={null}
        width={350}
      >
        <div className="mt-5">
          <RRAForm
            onCancel={handleCloseRRAModel}
            formCart={true}
            itemId={
              wareHouse?.createdCart?.data?.list &&
              wareHouse?.createdCart?.data?.list?.at(0)?._id
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default SaleWithPOS;
