import { Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import LoginByScan from "../pages/LoginByScan";
import LoginProtection from "./LoginProtection";

// customers
import CustomerRoutes from "./CustomerRoutes";
import Customer from "../pages/dashboard/Customers";
import CustomerOverview from "../pages/dashboard/customer/OverviewPage";
import CustomerDetails from "../pages/dashboard/customer/CustomerDetails";

//POS
import PointOfSales from "../pages/dashboard/PointOfSales";
import PageNotFound from "../pages/PageNotFound";
import POSRoutes from "./POSRoutes";
import SaleWithPOS from "../pages/dashboard/POS/SaleWithPOS";
import POSTransactions from "../pages/dashboard/POS/POSTransactions";
import TransactionsDetails from "../components/cards/transactionDetails/ViewTransaction";
import POSStock from "../pages/dashboard/POS/POSStock";
import NewRequestView from "../pages/dashboard/channels/NewRequestView";
import POSSale from "../pages/dashboard/POS/POSSales";

import PosPriceList from "../pages/dashboard/POS/PriceList";
import Nosale from "../pages/dashboard/POS/nosale/Nosale";
import PosStockRequest from "../pages/dashboard/POS/requestStock/POSRequestStock";

//reset password
import ResetPassword from "../pages/ResetPassword";
import ViewSingleRecent from "../pages/dashboard/POS/viewRecent/ViewSingleRecent";

import { authActions } from "../store/auth";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import store from "store";
import POSOverview from "../pages/dashboard/POS/POSOverview";
import RepairRoutes from "./RepairRoutes";
import RepairOverview from "../pages/dashboard/repair/RepairOverview";
import Repair from "../pages/dashboard/repair/Repair";
import RepairDevices from "../pages/dashboard/repair/RepairDevices";
import RepairDetails from "../pages/RepairDetails";
import { Knowledge } from "../apps/knowledge";
import KnowledgeRoute from "../pages/dashboard/knowledge/KnowledgeRoute";
import POSTransferStock from "../pages/dashboard/POS/requestStock/POSTransferStock";
import WarehouseOutCreate from "../pages/dashboard/stockOut/WarehouseOutCreate";
import CreateStockOut from "../pages/dashboard/stockOut/CreateStockOut";
import { getActiveShop } from "../utils/converter";
import { getAllStockToTransferAction } from "../store/channel/actions";
import PosCommissionDevices from "../components/cards/dashboard/PosCommissionDevices";

// import TestPdf from "../components/forms/subscriptionCustomerForms/testPdf.tsx";
const MainRouter = () => {
  const { auth, appSettings, channel } = useSelector((state: any) => state);
  const shopId = getActiveShop(auth?.user?.shop?.assigned)[
    appSettings?.selectedShopNumber
  ]?.shop?._id;

  const dispatch = useDispatch();
  const token = store.get("authToken");
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
  }, [auth, dispatch, token]);

  useEffect(() => {
    auth?.token &&
      shopId &&
      getAllStockToTransferAction(
        auth?.token,
        `?shopToTransfer=${shopId}&isTransferred=true&transferStatus=pending&limit=${1}&page=${0}`
      )(dispatch);
  }, [auth?.token, dispatch, shopId]);

  return (
    <Routes>
      <Route path="/reset/password/" element={<ResetPassword />} />
      <Route element={<LoginProtection />}>
        <Route path="/" element={<LoginByScan />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/scan" element={<LoginByScan />} />
      </Route>
      <Route path="/repair/:repaidId" element={<RepairDetails />} />
      {/* ++++++++++++++++ Customers +++++++++++++++++++++++++++++ */}
      <Route element={<CustomerRoutes />}>
        <Route path="/knowledge" element={<KnowledgeRoute />} />
        <Route path="/overview/customers" element={<CustomerOverview />} />
        <Route path="/customers" element={<Customer />} />
        <Route path="/customers/:customerId" element={<CustomerDetails />} />
      </Route>

      <Route path="/knowledged" element={<Knowledge.Screens.Outlet />}>
        <Route path="" element={<Knowledge.Screens.Home />} />
        <Route path="view/:id" element={<Knowledge.Screens.ViewPost />} />
        <Route path="manage" element={<Knowledge.Screens.Management />} />
        <Route path="manage/addPost" element={<Knowledge.Screens.AddPost />} />
        <Route
          path="manage/editPost/:id"
          element={<Knowledge.Screens.EditPost />}
        />
        <Route
          path="manage/addCategory"
          element={<Knowledge.Screens.AddCategory />}
        />
        <Route
          path="manage/editCategory/:id"
          element={<Knowledge.Screens.EditCategory />}
        />
      </Route>

      {/* POS */}
      <Route element={<POSRoutes totalRequest={channel?.rfsTransfer?.total} />}>
        <Route path="/home" element={<POSOverview />} />
        <Route path="/dashboard" element={<PointOfSales />} />
        <Route path="/pos/sale" element={<POSSale />} />
        <Route path="/pos/sale/:cartId" element={<SaleWithPOS />} />
        <Route
          path="/pos/transactions"
          element={<POSTransactions route={"pos"} />}
        />
        <Route
          path="/pos/transactions/:cartId"
          element={<TransactionsDetails />}
        />
        <Route path="/pos/stock" element={<POSStock />} />
        <Route path="/pos/prices" element={<PosPriceList />} />
        <Route path="/pos/nosale" element={<Nosale />} />
        <Route path="/pos/commission" element={<PosCommissionDevices />} />
        <Route path="/pos/request" element={<PosStockRequest />} />
        <Route path="/pos/request/:rfsId" element={<NewRequestView />} />
        <Route path="/pos/:rfsId" element={<ViewSingleRecent />} />
        <Route path="/pos/transfer" element={<POSTransferStock />} />
        <Route
          path="/pos/transfer/:stockreqitemId/:rfsId"
          element={<WarehouseOutCreate />}
        />
        <Route
          path="/pos/transfer/stock/:stockreqitemId/:wOutId"
          element={<CreateStockOut />}
        />
        <Route path="/pos/nosale" element={<Nosale />} />
        {/* <Route path="/pos/pricelist" element={<PosPriceList />} /> */}
      </Route>

      {/* Repair */}
      <Route element={<RepairRoutes />}>
        <Route path="/repair/overview" element={<RepairOverview />} />
        <Route path="/repair" element={<Repair />} />
        <Route path="/repair/devices" element={<RepairDevices />} />
      </Route>

      <Route path="/*" element={<PageNotFound />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default MainRouter;
