import { Paper, Stack } from "@mui/material";
import { Table, Tag } from "antd";
import AddItem from "./AddReqItem";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { myQuotationActions } from "../../../store/quotation";
import { reqStockColumns } from "../../../components/reUsableTable/colmns";
import { useNavigate } from "react-router-dom";
import {
  deleteRequestStockAction,
  getProductsByReqItemAction,
  getSelectedRequestAction,
  sendRequestAction,
} from "../../../store/channel/actions";

import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import type { TableColumnsType } from "antd";
import { searchValue } from "../../../utils/setColor";
import { getActiveShop } from "../../../utils/converter";

interface ExpandedDataType {
  key: React.Key;
  specification: string;
  sn: string;
  imei: string;
  status: any;
  data: any;
  price: any;
}
interface DataType {
  data?: any;
  key: any;
  type: any;
  brand: any;
  model: any;
  auth: any;
  qtyApproved: any;
  specification: any;
  quantity: any;
  dispatch: any;
  status: any;
  visible: boolean;
  setVisible: (state: boolean) => void;
  token: string;
  isLoading: boolean;
  rowToDelete: string;
  setRowToDelete: (state: string) => void;
}

const UpdateSaveButtons = (props: any) => {
  const { auth, channel, shop, appSettings } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shopId } = useParams();

  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const handleSendRequest = async () => {
    if (auth?.token && channel?.selectedRfs?.list.length > 0) {
      const isEmailRequest = await sendRequestAction(
        channel.selectedRfs._id,
        {
          status: "review",
          approvedBy: auth?.user?._id ?? null,
          isNotify: true,
          notification: {
            action: "Stock Request",
            role: ["dev", "admin", "inventory"],
            message: `Stock Request from ${shopName}`,
            title: "Stock Request",
          },
        },
        auth.token
      )(dispatch);

      isEmailRequest && navigate(-1);
    }
  };

  const handleSendRequestAsTransfer = async () => {
    if (auth?.token && channel?.selectedRfs?.list.length > 0) {
      const isEmailRequest = await sendRequestAction(
        channel.selectedRfs._id,
        {
          status:
            channel?.selectedRfs?.status === "review" ? "approved" : "review",
          approvedBy:
            channel?.selectedRfs?.status === "review" ? auth?.user?._id : null,
          isTransferred: true,
        },
        auth.token
      )(dispatch);

      isEmailRequest && navigate(-1);
    }
  };

  const handleCancelQuotation = async () => {
    auth?.token &&
      (await deleteRequestStockAction(
        auth?.token,
        channel?.selectedRfs?._id,
        `?shopId=${shopId}`
      )(dispatch));
    navigate(-1);
  };

  return (
    <Stack direction={"row"} spacing={2}>
      {!["sales-agent"].includes(auth.user.role?.toLowerCase()) && (
        <div>
          {channel?.selectedRfs?.list.length !== 0 &&
            ["request", "review"].includes(
              channel?.selectedRfs?.status?.toLowerCase()
            ) && (
              <div className="space-x-4">
                <LoadingButton
                  loading={channel?.isFetching || shop?.isFetching}
                  loadingPosition="start"
                  color={
                    channel?.selectedRfs?.status?.toLowerCase() === "review"
                      ? "success"
                      : "primary"
                  }
                  variant="contained"
                  onClick={async () => {
                    await handleSendRequest();
                  }}
                >
                  {channel?.selectedRfs?.status?.toLowerCase() === "review"
                    ? "Confirm As Stock Out"
                    : "Send Stock Request"}
                </LoadingButton>
                {["review"].includes(
                  channel?.selectedRfs?.status?.toLowerCase()
                ) && (
                  <LoadingButton
                    loading={channel?.isFetching || shop?.isFetching}
                    loadingPosition="start"
                    color={
                      channel?.selectedRfs?.status?.toLowerCase() === "review"
                        ? "success"
                        : "primary"
                    }
                    variant="contained"
                    onClick={async () => {
                      await handleSendRequestAsTransfer();
                    }}
                  >
                    {channel?.selectedRfs?.status?.toLowerCase() === "review" &&
                      "Confirm As Transfer"}
                  </LoadingButton>
                )}
              </div>
            )}
        </div>
      )}
      {["sales-agent"].includes(auth.user.role?.toLowerCase()) && (
        <div>
          {channel?.selectedRfs?.list.length !== 0 &&
            ["request"].includes(
              channel?.selectedRfs?.status?.toLowerCase()
            ) && (
              <LoadingButton
                loading={channel?.isFetching || shop?.isFetching}
                loadingPosition="start"
                variant="contained"
                onClick={async () => {
                  await handleSendRequest();
                }}
              >
                {channel?.selectedRfs?.status?.toLowerCase() !== "review" &&
                  "Send Stock Request"}
              </LoadingButton>
            )}
        </div>
      )}
      <LoadingButton
        variant="contained"
        color="error"
        onClick={async () => await handleCancelQuotation()}
        loading={channel?.isFetching || shop?.isFetching}
      >
        Cancel
      </LoadingButton>
    </Stack>
  );
};

const PageView = (props: any) => {
  const { auth, quotation, channel, shop } = useSelector((state: any) => state);
  const [expandedRowKey, setExpandedRowKey] = React.useState<any>(null);
  const [isCancel, setIsCancel] = useState(false);
  const [prodId, setProdId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");

  const { rfsId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(myQuotationActions.setNew(null));
    dispatch(myQuotationActions.setSelected(null));

    auth?.token &&
      getSelectedRequestAction(auth?.token, `?requestId=${rfsId}`)(dispatch);
  }, [rfsId, auth?.token, shop?.selectedStockRequest, dispatch]);

  useEffect(() => {
    auth?.token &&
      prodId &&
      getProductsByReqItemAction(
        auth?.token,
        `requestedItem=${prodId}`
      )(dispatch);
  }, [auth?.token, prodId, dispatch]);

  const expandedRowRender = () => {
    const columns: TableColumnsType<ExpandedDataType> = [
      {
        title: "Descriptions",
        dataIndex: "specification",
        key: "specification",
      },
      { title: "Serial Number", dataIndex: "sn", key: "sn" },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        render: (text, record) => (
          <>
            <p>{record?.data?.wareHouseItem?.imei1 || "N/A"}</p>
            <p>{record?.data?.wareHouseItem?.imei2}</p>
          </>
        ),
      },
      { title: "Price(Frw)", dataIndex: "price", key: "price" },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => (
          <Tag
            color={searchValue(record?.data?.status)}
            className="capitalize min-w-[50px] text-center"
          >
            {record?.data?.status}{" "}
          </Tag>
        ),
      },
    ];

    const productData: ExpandedDataType[] = [];
    channel?.products?.data?.forEach((el: any) => {
      productData.push({
        key: el.id,
        sn: el?.wareHouseItem?.serialNumber,
        imei: el?.wareHouseItem?.imei,
        price: el?.requestedItem?.product?.prices
          ?.filter((d: any) => d.isActive === true)
          ?.map((d: any) => d.value)
          ?.toLocaleString(),
        specification: el?.wareHouseItem?.quotationItem?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        status: el?.status,
        data: el,
      });
    });
    if (channel?.selectedRfs?.status === "done") {
      return (
        <Table columns={columns} dataSource={productData} pagination={false} />
      );
    }
  };

  const data: DataType[] = [];
  const values = channel?.selectedRfs?.list;

  values &&
    values?.forEach((el: any) => {
      data.push({
        data: el,
        key: el._id,
        type: el?.product?.product?.type,
        brand: el?.product?.product?.brand,
        qtyApproved: el?.qtyApproved ? el?.qtyApproved : 0,
        dispatch: dispatch,
        auth: auth,
        model: el?.product?.product?.model,
        specification: el?.product?.specification
          ?.map((el: any) => el[1] && `${el[0]}: ${el[1]}`)
          .join(", "),
        quantity: el?.qtyRequested,
        status: channel?.selectedRfs?.status,
        visible: visible,
        setVisible: setVisible,
        token: auth?.token,
        isLoading: shop?.isFetching,
        rowToDelete: rowToDelete,
        setRowToDelete: setRowToDelete,
      });
    });

  const handleSendEmail = async () => {
    // sendEmailAction(auth.token, {
    //   quotationToSuppliers: suppliers,
    // })(dispatch);
    console.log(quotation?.allRfqSupplier?.data?.map((d: any) => d._id));
  };

  const handleRowExpand = (expanded: any, record: any) => {
    if (expanded) {
      setExpandedRowKey(record.key);
      setProdId(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };
  return (
    <Stack spacing={1}>
      <div className="mt-5 text-black">
        <DetailsHeaderActionBar
          pageName={channel?.selectedRfs?.shopId?.name}
          title={`RFS ~ ${rfsId}`}
        />
      </div>
      {channel?.selectedRfs?.status && (
        <Paper elevation={0} style={{ marginBottom: "20px" }}>
          <Stack spacing={0}>
            {["request"].includes(channel?.selectedRfs?.status) && (
              <AddItem
                onClickAdd={() => {
                  setIsCancel(true);
                }}
              />
            )}

            <Table
              columns={reqStockColumns}
              dataSource={data}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ["0"],
                onExpand: handleRowExpand,
                expandedRowKeys: [expandedRowKey],
              }}
              loading={channel?.isFetching || shop?.isFetching}
            />
          </Stack>
        </Paper>
      )}

      {channel?.selectedRfs?.status &&
        values?.length >= 1 &&
        ["request", "review"].includes(channel?.selectedRfs?.status) && (
          <UpdateSaveButtons
            //  isCancel={isCancel}
            isLoading={isLoading}
            sendButtonDefault={false} //this will depend on quotation status
            onClick={() => {
              setIsCancel(false);
            }}
            handleSendEmail={handleSendEmail}
          />
        )}
    </Stack>
  );
};

export default PageView;
