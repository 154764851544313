import { notification } from "antd";
import { myTermsAndConditionsActions } from ".";
import {getAllTermsAndConditionsService} from "./services";



export const getAllTermsAndConditionsAction = (token: string,query:string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myTermsAndConditionsActions.setIsFetching(true));
      const res = await getAllTermsAndConditionsService(token,query);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(myTermsAndConditionsActions.setAll(res));
        dispatch(myTermsAndConditionsActions.setIsFetching(false));
      }
      dispatch(myTermsAndConditionsActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
