import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, notification, Select, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import WarehouseOutTable from "../../../components/tables/WarehouseOutTable";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Stack } from "@mui/material";
import { Spin } from "antd";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getAllShopOutAction,
  getAllWarehouseOutItemsAction,
  updateOneStockOutDetailsAction,
  updateStockOutAction,
  updateStockReqItemAction,
} from "../../../store/channel/actions";
import BarcodeScanner from "../../../components/buttons/BarcodeScanner";
import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";
import ManuallyStockOut from "./ManuallyStockOut";
import SwitchButton from "../../../components/buttons/SwitchButton";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CreateStockOut = (props: any) => {
  const { auth, wareHouseOut, channel } = useSelector((state: any) => state);
  const [selectedProductId, setSelectedProductId] = React.useState<any>("");
  const [selectedProduct, setSelectedProduct] = React.useState<any>("");
  const [showDeliverer, setShowDeliverer] = React.useState<any>("");
  // const [serialNoString, setSerialNoString] = React.useState<any>([]);
  const [duplicateItems, setDuplicateItems] = React.useState<any>([]);
  const [scannedCode, setScannedItem] = React.useState<any>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { wOutId, stockreqitemId } = useParams();
  const deliverer = channel?.warehouseOut?.data[0]?.deliverer;
  const warehouseOutData = channel?.warehouseOut?.data[0];
  const [manualOption, setManualOption] = React.useState(false);
  const [itemsArr, setItemArray] = React.useState<any>([]);
  const [activeTab, setActiveTab] = React.useState(1);
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const handleDeliverer = async () => {
      if (auth?.token) {
        const res = await updateOneStockOutDetailsAction(
          auth?.token,
          channel?.warehouseOut?.data[0]?._id,
          selectedProduct?._id,
          {
            deliverer: {
              ...values,
            },
            status: "In-Transit",
          }
        )(dispatch);
        await updateStockReqItemAction(
          stockreqitemId,
          { transferStatus: "In-Transit" },
          auth?.token
        )(dispatch);
        res && navigate(-2);
      }
      form.resetFields();
    };
    handleDeliverer();
  };

  useEffect(() => {
    setShowDeliverer(
      channel?.warehouseOutSelected &&
        !channel?.warehouseOutSelected?.stockRequest?.list
          ?.map(
            (item: any, _index: number) =>
              item?.qtyApproved === item?.qtyRequested
          )
          ?.includes(false)
    );
  }, [channel?.warehouseOutSelected]);

  useEffect(() => {
    auth?.token &&
      getAllShopOutAction(auth?.token, `?warehouseOutId=${wOutId}`)(dispatch);
  }, [auth?.token, dispatch, wOutId]);

  const handleSetSelected = (product_id: string) => {
    setSelectedProductId(product_id);
    setSelectedProduct(
      channel?.warehouseOutSelected?.stockRequest?.list?.filter(
        (item: any) => item?._id === product_id
      )[0]
    );
    auth?.token &&
      getAllWarehouseOutItemsAction(
        auth?.token,
        `requestedItem=${product_id}`
      )(dispatch);
  };

  const handleAddProducts = async () => {
    if (
      (scannedCode?.length || itemsArr?.length) <=
      selectedProduct?.qtyApproved -
        (channel?.warehouseOutItem?.data?.length ?? 0)
    ) {
      if (new Set(scannedCode).size !== scannedCode?.length) {
        setDuplicateItems(
          scannedCode?.filter(
            (value: any, index: any) => scannedCode?.indexOf(value) !== index
          )
        );
        return notification.warning({
          message: "IMEI has been duplicated",
          description: `${scannedCode
            ?.split(" ")
            .filter(
              (value: any, index: any) =>
                scannedCode?.toString()?.indexOf(value) !== index
            )
            .join(" , ")}`,
        });
      }

      const res = await updateStockOutAction(
        channel?.warehouseOutSelected?._id,
        {
          action: "transfer",
          product: selectedProduct?.product?.product?._id,
          serialNumbers: manualOption ? itemsArr : scannedCode,
          productPriceList: selectedProduct?.product?._id,
          requestedItem: selectedProduct?._id,
          stockRequest: channel?.warehouseOutSelected?.stockRequest?._id,
          shop: channel?.warehouseOutSelected?.stockRequest?.shopId?._id,
        },
        auth?.token
      )(dispatch);
      if (res) {
        setScannedItem([]);
        setManualOption(false);
        setItemArray([]);
      }
    } else {
      notification.error({
        message: `Please Scan only ${
          selectedProduct?.qtyApproved -
          (channel?.warehouseOutItem?.data?.length ?? 0)
        } Devices`,
      });
    }
  };

  const handleRemoveScannedCode = (removedTag: any) => {
    const newScannedCode = scannedCode.filter((tag: any) => tag !== removedTag);
    setScannedItem(newScannedCode);
  };

  const handleRemoveManuallyItem = (removedTag: any) => {
    const newItem = itemsArr.filter((tag: any) => tag !== removedTag);
    setItemArray(newItem);
  };

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  useEffect(() => {
    setItemArray([]);
    setScannedItem([]);
  }, [activeTab]);

  return (
    <div className="text-black p-5 mt-3 space-y-2 h-[800px] overflow-y-auto">
      <div className="p-5 bg-white rounded-lg space-y-6">
        <DetailsHeaderActionBar pageName={"Stock Out"} title={wOutId} />
        <div className="flex relative gap-20 w-full justify-between items-end">
          <Stack
            direction={"column"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              className="w-full"
            >
              <div className="mb-5">
                <p className="text-xsx text-gray-400">
                  {warehouseOutData?.stockRequest?.shopId?.channel?.name}
                </p>
                <p className="text-xsx text-gray-400 capitalize">
                  {`${
                    warehouseOutData?.stockRequest?.shopId?.name
                  } ~ ${warehouseOutData?.stockRequest?.shopId?.type
                    ?.split("-")
                    ?.join(" ")}`}
                </p>
              </div>
            </Stack>

            {channel?.warehouseOutSelected?.status !== "In-Transit" && (
              <div className="w-max ">
                <p className="w-fit font-bold text-lg">Model name</p>
                <div className="flex gap-5 text-gray-300 items-center">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Choose Product Model"
                    onChange={(data: any) => {
                      handleSetSelected(data);
                    }}
                    options={channel?.warehouseOutSelected?.stockRequest?.list?.map(
                      (item: any, _index: number) => ({
                        label:
                          `${
                            item?.qtyApproved === item?.qtyRequested
                              ? "✔️ "
                              : ""
                          }  ` + item?.product?.product?.model,
                        value: item?._id,
                      })
                    )}
                  />

                  {channel.isFetching && (
                    <div className="flex h-fit ">
                      <Spin indicator={antIcon} />
                    </div>
                  )}

                  {!channel.isFetching && (
                    <span>{`${
                      channel?.warehouseOutSelected?.stockRequest?.list?.filter(
                        (item: any) => item?.qtyApproved === item?.qtyRequested
                      )?.length
                    }/${
                      channel?.warehouseOutSelected?.stockRequest?.list?.length
                    }`}</span>
                  )}
                </div>
                {
                  <div className="grid grid-cols-2 min-w-[288px] bg-slate-100 mt-2 rounded-md p-3">
                    <>
                      {selectedProduct?.product?.specification?.map(
                        (item: any, _index: number) =>
                          item[1] && (
                            <>
                              <span className="font-semibold">{item[0]}</span>
                              <span>{item[1]}</span>
                            </>
                          )
                      )}
                    </>
                  </div>
                }
              </div>
            )}
          </Stack>

          {selectedProduct?.qtyRequested && (
            <div className="flex flex-col space-y-2">
              <div className=" flex gap-0">
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ width: "100%" }}
                >
                  <div className="flex flex-col">
                    <div className="mb-6">
                      <SwitchButton
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                        label1={"Scan"}
                        label2={"Manually"}
                      />
                    </div>
                    {activeTab === 2 && (
                      <ManuallyStockOut
                        setDuplicateItems={setDuplicateItems}
                        itemsArr={itemsArr}
                        selectedProduct={selectedProduct}
                        setManualOption={setManualOption}
                        setScannedItem={setScannedItem}
                        setItemArray={setItemArray}
                      />
                    )}
                    <div className="flex">
                      <Stack marginTop={"10px"}>
                        {activeTab === 1 && (
                          <span>{`${
                            channel?.warehouseOutItem?.data?.length || 0
                          }/${selectedProduct?.qtyApproved}`}</span>
                        )}
                        <div
                          className={`flex w-[30rem] flex-wrap ${
                            scannedCode?.length > 0 && "border p-1"
                          }`}
                        >
                          {manualOption ? (
                            <div className="flex flex-wrap w-[40rem] mx-2 bg-[#F4F6FA] p-2.5 rounded gap-2">
                              {itemsArr?.map((item: any) => (
                                <Tag
                                  closable
                                  onClose={() => handleRemoveManuallyItem(item)}
                                >
                                  {item}
                                </Tag>
                              ))}
                            </div>
                          ) : (
                            <>
                              {scannedCode.map((el: any) => (
                                <Tag
                                  key={el}
                                  closable
                                  onClose={() => handleRemoveScannedCode(el)}
                                  className="mt-1"
                                >
                                  {el}
                                </Tag>
                              ))}
                            </>
                          )}
                        </div>
                      </Stack>
                      {activeTab === 1 && (
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="end"
                          sx={{ width: 80 }}
                        >
                          {!channel?.isFetching && (
                            <>
                              <Button
                                disabled={channel?.isFetching}
                                style={{
                                  backgroundColor: "white",
                                  color: "white",
                                  marginLeft: "4px",
                                }}
                              >
                                <BarcodeScanner
                                  setScannedItem={setScannedItem}
                                />
                              </Button>
                            </>
                          )}
                        </Stack>
                      )}
                    </div>
                  </div>
                </Stack>
              </div>
              {(scannedCode?.length > 0 || itemsArr?.length > 0) && (
                <div>
                  {" "}
                  <Button
                    disabled={channel?.isFetching}
                    style={{
                      backgroundColor: "blue",
                      color: "white",
                      marginLeft: "4px",
                    }}
                    onClick={() => handleAddProducts()}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>

        <WarehouseOutTable selectedProductId={selectedProductId} />

        {channel?.warehouseOutSelected?.status !== "In-Transit" &&
          channel?.warehouseOutItem?.data?.length ===
            selectedProduct?.qtyApproved && (
            <div className="space-y-4">
              <p>Deliverer Details</p>
              <Form onFinish={onFinish} form={form} className="w-96">
                <Form.Item
                  name="name"
                  label="Name"
                  initialValue={deliverer?.name}
                  rules={[
                    {
                      required: true,
                      message: "Name is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone"
                  label="Phone"
                  initialValue={deliverer?.phone}
                  rules={[
                    {
                      required: true,
                      message: "Phone is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="company"
                  label="Company"
                  initialValue={deliverer?.company}
                  rules={[
                    {
                      required: true,
                      message: "Company name is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  initialValue={deliverer?.email}
                  rules={[
                    {
                      required: true,
                      message: "Email is required!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ minWidth: "60%" }}
                  loading={wareHouseOut?.isFetching}
                >
                  Save
                </LoadingButton>
              </Form>
            </div>
          )}
      </div>
    </div>
  );
};

export default CreateStockOut;
