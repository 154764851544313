import React, { ReactInstance, useRef } from "react";
import { useSelector } from "react-redux";
import { getTotalPrice } from "../../../../utils/converter";
import { Divider } from "antd";
import { ReactComponent as SamphoneLogo } from "../../../../assets/icons/logo.svg";
import CartItemsTable from "../../../../components/tables/pos/CartItemsTable";
import RRAPaymentReceipt from "../RRAPaymentReceipt";
import { useReactToPrint } from "react-to-print";

const ConfirmCheckout = (props: any) => {
  const templateRef = useRef<ReactInstance>(null);
  const handlePrint = useReactToPrint({
    content: () => templateRef.current,
    // pageStyle: `
    // @media print {
    //   @page {
    //     size: 58mm auto;
    //     margin: 0;
    //   }
    // }
    // `,
  });

  const { wareHouse, shop } = useSelector((state: any) => state);
  const itemsList = wareHouse?.createdCart?.data?.list;
  const amount = wareHouse?.createdCart?.data?.payment?.reduce(
    (curr: any, item: any) => curr + item?.amount,
    0
  );
  const discount = wareHouse?.createdCart?.data?.discount;

  return (
    <div className="flex gap-x-2 rounded-lg text-white">
      <div className="flex flex-col w-[70%] gap-y-[0.7vw] py-[70px] px-5 rounded-lg text-[#0F0F47]">
        <div className="h-1/2 p-22">
          <div className="flex justify-between text-slate-800">
            <div className="py-2">
              <p className="uppercase font-semibold mb-2">Bill from:</p>
              <p className="font-semibold capitalize">Samphone rwanda ltd</p>
              <div>TCB BUILDING. 3rd FLOOR . NYARUGENGE DISTRICT</div>
              <div className=" text-slate-600">
                <span className="font-semibold text-slate-800">TEL: </span>
                0792573848
              </div>
              <div className=" text-slate-600">
                <span className="font-semibold">Email:</span>{" "}
                invoice@samphone.co
              </div>
              <div className=" text-slate-600">
                <span className="font-semibold">TIN:</span> 112620773
              </div>
              <div className=" text-slate-600">
                <span className="font-semibold">CASHIER:</span> SAMPHONE RWANDA
                Ltd(112620773)
              </div>
            </div>
            <div className="flex flex-1 justify-end xitems-center">
              <SamphoneLogo className="mr-5" />
            </div>
          </div>
          <Divider />
          <div className=" flex justify-between space-x-1">
            <div className="flex flex-col ">
              <p className="uppercase font-semibold mb-2">Bill to:</p>
              <span>{wareHouse?.createdCart?.data?.customer?.name}</span>
              <span>{wareHouse?.createdCart?.data?.customer?.phone}</span>
              <span>{wareHouse?.createdCart?.data?.customer?.email}</span>
            </div>
            <div>
              <div className="grid grid-cols-2 w-fit text-black">
                <p className="font-semibold text-gray-400">Invoice</p>
                <span>{wareHouse?.createdCart?.data?.cartId}</span>
                <p className="font-semibold text-gray-400 ">Date</p>
                <span>
                  {wareHouse?.createdCart?.data?.paidOn?.slice(0, 10)}
                </span>
                <p className="font-semibold text-gray-400 ">Shop</p>
                <span>{shop?.myShop?.name}</span>
                <p className="font-semibold text-gray-400">
                  Discount {discount?.discountPerc}%:
                </p>
                <p>{`${(
                  getTotalPrice(itemsList) *
                  (discount?.discountPerc / 100)
                )?.toLocaleString()} RWF`}</p>
                <p className="font-semibold bg-gray-300 p-1 ">Amount Due</p>
                <span className="font-bold bg-gray-300 p-1">{`${amount?.toLocaleString()} RWF`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="h-1/2 p-2 overflow-auto space-y-2">
          <CartItemsTable />
          <div className="flex justify-between space-x-2">
            <div>{/* here! date. */}</div>
            <div className="grid grid-cols-2 gap-x-2 text-right text-black font-semibold">
              {/* <p>Untaxed Amount:</p>
              <p>{`${getTotalPrice(itemsList)?.toLocaleString()} RWF`}</p> */}
              {/* <p>Discount {discount?.discountPerc}%:</p>
              <p>{`${(
                getTotalPrice(itemsList) *
                (discount?.discountPerc / 100)
              )?.toLocaleString()} RWF`}</p> */}
              {/* <p>VAT 0%:</p>
              <p>0.00 RWF</p>
              <p>Total:</p>07890
              <p>{`${(
                getTotalPrice(itemsList) -
                getTotalPrice(itemsList) * (discount?.discountPerc / 100)
              )?.toLocaleString()} RWF`}</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex-1">
        <div className="rounded-lg ">
          <RRAPaymentReceipt
            handlePrint={handlePrint}
            templateRef={templateRef}
          />
        </div>
        <button
          className="bg-blue-800 py-1 px-2 rounded-md text-white ml-12"
          onClick={handlePrint}
        >
          Print Receipt
        </button>
      </div>
    </div>
  );
};

export default ConfirmCheckout;
