import { Modal } from "antd";
import React from "react";
import Webcam from "react-webcam";
import LoadingButton from "@mui/lab/LoadingButton";

interface dataType {
  isModalOpen: boolean;
  isCameraOpen: boolean;
  title: string;
  captureImage: any;
  closeCamera: any;
  videoConstraints: any;
  webcamRef: any;
}

const WebCam: React.FC<dataType> = ({
  isModalOpen,
  isCameraOpen,
  title,
  captureImage,
  closeCamera,
  videoConstraints,
  webcamRef,
}) => {
  return (
    <div>
      <Modal title={title} open={isModalOpen} footer={null}>
        {isCameraOpen && (
          <>
            <div className="camera-container">
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
            </div>
            <div className="  items-center justify-between pt-2 space-x-4">
              <LoadingButton
                variant="contained"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderRadius: "2px",
                }}
                onClick={captureImage}
              >
                Save
              </LoadingButton>
              <LoadingButton
                variant="outlined"
                style={{
                  padding: "4px",
                  width: "26px",
                  borderColor: "red",
                  backgroundColor: "red",
                  color: "white",
                }}
                onClick={closeCamera}
              >
                Close
              </LoadingButton>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default WebCam;
