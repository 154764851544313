import { myPosOverViewActions } from ".";
import {
  getPaymentStats,
  getRevenueServices,
  overviewStatServices,
} from "./services";

export const getTodayRevenueActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosOverViewActions.setIsFetching(true));
      const res = await getRevenueServices(token, query);
      if (res?.status === 200) {
        dispatch(myPosOverViewActions.setTodayRevenueStats(res));
        dispatch(myPosOverViewActions.setIsFetching(false));
      }
      dispatch(myPosOverViewActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getOtherPaymentStatsActions = (
  data: any,
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosOverViewActions.setIsFetching(true));
      const res = await getPaymentStats(data, token, query);
      if (res?.status === 200) {
        dispatch(myPosOverViewActions.setOtherPaymentStats(res));
        dispatch(myPosOverViewActions.setIsFetching(false));
      }
      dispatch(myPosOverViewActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getSamphonePaymentStatsActions = (
  data: any,
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosOverViewActions.setIsFetching(true));
      const res = await getPaymentStats(data, token, query);
      if (res?.status === 200) {
        dispatch(myPosOverViewActions.setSamphonePaymentStats(res));
        dispatch(myPosOverViewActions.setIsFetching(false));
      }
      dispatch(myPosOverViewActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getMTNPaymentStatsActions = (
  data: any,
  token: string,
  query?: string
) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosOverViewActions.setIsFetching(true));
      const res = await getPaymentStats(data, token, query);
      if (res?.status === 200) {
        dispatch(myPosOverViewActions.setMTNPaymentStats(res));
        dispatch(myPosOverViewActions.setIsFetching(false));
      }
      dispatch(myPosOverViewActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const overViewStatActions = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(myPosOverViewActions.setIsFetching(true));
      const res = await overviewStatServices(token, query);
      if (res?.status === 200) {
        dispatch(myPosOverViewActions.setOverviewStats(res));
        dispatch(myPosOverViewActions.setIsFetching(false));
      }
      dispatch(myPosOverViewActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
