import { useState } from "react";
import POSTTranctionsTable from "../../../components/tables/POSTTranctionsTable";
import ProductFilter from "../../../components/filters/POSTransactionsFiltering";
// import DetailsHeaderActionBar from "../../../components/cards/DetailsPage/DetailsHeaderActionBar";

const POSTransactions = (props: any) => {
  const [selectedStatus, setSelectedStatus] = useState();
  const formattedDate = new Date().toISOString().slice(0, 10);
  const [selectedDate, setSelectedDate] = useState<any>(formattedDate);

  return (
    <div className="h-full text-black py-2 scrollbar-hide">
      {/* <DetailsHeaderActionBar pageName="POS" title="Transactions" /> */}
      <ProductFilter
        setSelectedStatus={setSelectedStatus}
        setSelectedDate={setSelectedDate}
        route={props?.route}
      />

      <POSTTranctionsTable
        selectedStatus={selectedStatus}
        selectedDate={selectedDate}
      />
    </div>
  );
};

export default POSTransactions;
