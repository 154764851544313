import React, { useState } from "react";
// import type { DatePickerProps } from "antd";
import UpdatePasswordForm from "../../components/forms/UpdatePassword";
import { useDispatch, useSelector } from "react-redux";
import { getRecentPurchaseOrdersAction } from "../../store/purchase/actions";
import { getRecentPosOrdersAction } from "../../store/pos/actions";
import { getActiveShop } from "../../utils/converter";
import SelectShopPosModal from "../../components/Modals/SelectShopPosModal";
import moment from "moment";
import TotalRevenueAndDataActivation from "../../components/cards/dashboard/Sections/TotalRevenueAndDataActivation";
import UserDetailPanel from "../../components/cards/dashboard/UserDetailPanel";
import {
  getMTNPaymentStatsActions,
  getOtherPaymentStatsActions,
  getSamphonePaymentStatsActions,
  getTodayRevenueActions,
} from "../../store/posOverView/actions";
import PaymentMode from "./sections/PaymentMode";
import { DatePicker, DatePickerProps, Modal } from "antd";
import dayjs from "dayjs";
import { getAllAgentRevenueAction } from "../../store/kpi/actions";
import { Chat } from "../../apps";
import NotificationModal from "../../components/Modals/NotificationModal";
import MissingRRACodeNotification from "../../components/notification/MissingRRACodeNotification";
import { getAllUnrecordedRRAcodeAction } from "../../store/notification/actions";

const PageView = (props: any) => {
  const [openShopSelectModal, setOpenShopSelectModal] =
    useState<boolean>(false);
  const { auth, shop, appSettings, notifications } = useSelector(
    (state: any) => state
  );
  const dispatch = useDispatch();
  const handleClose = () => setOpenShopSelectModal(false);
  const formattedDate = new Date().toISOString().slice(0, 10);
  let today = moment();
  let previousDateToCompare = today.subtract(1, "days");
  let formattedPreviousDate = previousDateToCompare.format("YYYY-MM-DD");

  const [enteredDate, setEnteredDate] = useState<any>(formattedDate);
  const [compareDate, setCompareDate] = useState<any>(formattedPreviousDate);
  const [openNotifyModel, setOpenNotifyModel] = useState(false);

  const onChangeDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setEnteredDate(dateString);
  };

  const onChangePreviousDate: DatePickerProps["onChange"] = async (
    date: any,
    dateString: any
  ) => {
    setCompareDate(dateString);
  };

  const otherPaymentMethos = ["Macye_Macye"];
  const samphonePaymentMethos = [
    "Samphone_Bank_Deposit",
    "Samphone_POS",
    "MoMo_Pay",
  ];
  const mtnPaymentMethos = [
    "MTN_POS",
    "MTN_Staff_Device_Requisition",
    "MTN_Bank_Deposit",
  ];
  const shopName =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?.name || shop?.myShop?.name;

  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? auth?.user?.shop?.shopId;

  React.useEffect(() => {
    console.log("Trying to fetch new data.........");
    if (auth?.token && enteredDate && shopId) {
      getTodayRevenueActions(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getOtherPaymentStatsActions(
        { modes: otherPaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getSamphonePaymentStatsActions(
        { modes: samphonePaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getMTNPaymentStatsActions(
        { modes: mtnPaymentMethos },
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}&shops=${shopId}`
      )(dispatch);
      getAllAgentRevenueAction(
        auth?.token,
        `?date1=${enteredDate}&date2=${compareDate}`
      )(dispatch);
    }
  }, [
    auth?.token,
    appSettings?.selectedShopNumber,
    dispatch,
    enteredDate,
    compareDate,
    shopId,
  ]);

  React.useEffect(() => {
    auth?.token && getRecentPurchaseOrdersAction(auth?.token)(dispatch);
  }, [auth?.token, dispatch]);

  React.useEffect(() => {
    auth?.token &&
      shopId &&
      getRecentPosOrdersAction(
        auth?.token,
        `?shop=${shopId}&status=In-Transit`
      )(dispatch);
  }, [auth?.token, dispatch, shopId]);

  // const onChangeDate: DatePickerProps["onChange"] = async 0789093340, 0785218128(
  //   _date: any,
  //   dateString: any
  // ) => {
  //   console.log("Selected date", dateString);
  //   // props?.setEnteredDate(dateString);
  //   // const dateBefore1Day = moment(dateString).subtract(1, "days");
  //   // setPreviousDate(dateBefore1Day.format("YYYY-MM-DD"));
  // };

  const disabledDate = (current: any) => {
    if (current && current > moment().endOf("day")) {
      return true;
    }
    return false;
  };

  const userShopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id || auth?.user?.shop?.shopId;

  React.useEffect(() => {
    ["sales-agent"].includes(auth.user.role?.toLowerCase()) &&
      getAllUnrecordedRRAcodeAction(
        auth?.token,
        `?shopId=${userShopId}`
      )(dispatch);
  }, [auth?.token, auth.user.role, userShopId, dispatch]);

  React.useEffect(() => {
    notifications?.missingRRACode?.total > 0 && setOpenNotifyModel(true);
  }, [notifications?.missingRRACode?.total]);

  const onCancel = () => {
    setOpenNotifyModel(false);
  };

  return (
    <>
      <div className="flex gap-x-2 text-black scrollbar-hide overflow-x-hidden overflowc-y-auto h-full">
        {/* Here */}
        <UserDetailPanel />
        <Chat />
        <div className="flex flex-col space-y-1 text-[#323067] w-full pl-[20.5vw]">
          <div className="flex justify-between py-2 px-3 bg-white rounded-lg bg-opacity-50 shadow">
            <p>Shop dashboard</p>
            <div className="flex space-x-4">
              <DatePicker
                onChange={onChangeDate}
                defaultValue={dayjs()}
                style={{ width: 120 }}
                disabledDate={disabledDate}
              />
              <div className="flex space-x-2">
                <p>Compared To</p>
                <DatePicker
                  onChange={onChangePreviousDate}
                  defaultValue={dayjs().subtract(1, "day")}
                  style={{ width: 120 }}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          </div>
          <SelectShopPosModal
            handleClose={handleClose}
            showModal={openShopSelectModal}
          />
          {/* Total Revenue and Data Action Section */}
          <TotalRevenueAndDataActivation />

          {/* Methods Of Payments */}
          <PaymentMode />
        </div>
        <Modal
          open={auth?.user?.isPswdDefault}
          footer={null}
          title="Update PIN Code"
        >
          <UpdatePasswordForm _id={auth?.user?._id} />
        </Modal>
      </div>
      <NotificationModal
        openModal={false}
        // openModal={false}
        // cancel={onCancel}
        title={`Unfinished Activities (${notifications?.missingRRACode?.total})`}
        content={<MissingRRACodeNotification />}
      />
    </>
  );
};

export default PageView;
