import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import { Table } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getStockStatsAction } from "../../store/wareHouse/actions";
import "../../styles/table.css";
import { useNavigate } from "react-router-dom";

const ShopOutTable = (props: any) => {
  const { auth, wareHouse, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    auth?.token && getStockStatsAction(auth?.token, "")(dispatch);
  }, [auth?.token, dispatch]);

  const dataToDisplay = channel?.pendingShopOut?.data?.flatMap((el: any) =>
    el?.stockRequest?.list?.map((d: any) => {
      return {
        type: d?.product?.product?.type,
        brand: d?.product?.product?.brand,
        model: d?.product?.product?.model,
        specs: d?.product?.specification
          ?.slice(2)
          ?.map((elem: any) => elem[1] && `${elem[0]} : ${elem[1]}`),
        qnty: d?.qtyApproved,
        status: el?.status,
        wOutId: el?.warehouseOutId,
      };
    })
  );

  const [pagination, setPagination] = useState({
    pageSize: 10,
    current: 1,
    total: dataToDisplay?.length,
    showSizeChanger: true,
  });

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Specifications",
      dataIndex: "specs",
      key: "specs",
    },
    {
      title: "Quantity",
      dataIndex: "qnty",
      key: "qnty",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const rowClassName = () => "clickable-row";

  return (
    <Paper elevation={0} style={{ marginBottom: "20px" }}>
      <Table
        columns={columns}
        dataSource={dataToDisplay}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => navigate(`/pos/transfer/stock/${record?.wOutId}`),
        })}
        rowClassName={rowClassName}
        loading={wareHouse?.isFetching || channel?.isFetching}
      />
    </Paper>
  );
};

export default ShopOutTable;
