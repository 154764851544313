import React from "react";

const TermsAndConditionContent = () => {
  return (
    <>
      <div>
        <h1 className="text-lg font-bold mb-2 mt-4">
          Customer/ Responsible Party{" "}
        </h1>
        <p>
          Reference to "you" or "Buyer" means you are the individual and the
          customer on record. You understand and agree to be primarily
          responsible for and guarantee payment of any sums that become due
          under this Agreement. You are not allowed to sign on behalf of another
          individual, an organization, a business entity or government body and
          will be personally responsible for performance under this Agreement,
          if we subsequently discover the contrary.
        </p>
        <h1 className="text-lg font-bold mb-2 mt-4">
          Initial Payment by Customer.{" "}
        </h1>
        <p>
          You shall make the first monthly subscription via Momo Account payment
          at the Point-of-Sale in MTN and Kumwe Hub point of distribution. This
          is a prerequisite to the application being approved. Where an upfront
          payment is required as a condition of the service plan selected, such
          payment will also be made via Momo Account at the Point-of-Sale.
        </p>
        <h1 className="text-lg font-bold mb-2 mt-4">Monthly Repayments.</h1>
        <p>
          Subsequent subscriptions will be made via the daily/weekly/monthly
          deductions from the Momo Account of the equivalent amount equal to the
          monthly Bundle Price, prior to the subscription anniversary date.
          <br />
          <br />
          On the monthly anniversary date, following full monthly subscription.
          MTN will provision the bundle offering – airtime, data, and others for
          the following month.
          <br />
          <br />
          Under no circumstance would we be liable for any loss, expense or
          damage of any kind incurred by you due to non-compliance with this
          requirement.
          <br />
          <br />
          Under no circumstance would we be liable for any loss, expense or
          damage of any kind incurred by you due to non-compliance with this
          requirement.
          <br />
          <br />
          Please note that the Distributor reserves the right to report failure
          to repay to Credit Bureaus and any regulatory bodies or government
          institutions as applicable. All outstanding repayments will be
          collected by our collection officers/agents and the Distributor
          reserves the right to prosecute any customer for breach of contract.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold mb-2 mt-4">E-signature</h1>
        <p>
          You hereby declare that the signature below is your original
          signature, which is to be relied upon for the purposes of verifying
          the authenticity of electronic versions of your signature in
          documents. You also hereby declare that this record of your signature
          may be used for the purposes of verifying the authenticity of
          electronic signatures that are set out in agreements or documents as
          being the true representation of your written signature. It is your
          duty to notify us immediately in the event that you change your
          signature. We shall not be liable for any liabilities, losses or
          damages as a result of your failure to comply with this requirement.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">Default</h1>
        <p>
          You will be in default of this Agreement if any one of the following
          events (referred to as an "Event of Default") occurs:
        </p>
        <div className="pl-10">
          <ul className="list-disc">
            <li>
              you fail to subscribe to the bundle by the applicable due date.
            </li>
            <li>
              you are in breach of any covenant, representation, or warranty in
              this Agreement;
            </li>
            <li>a bankruptcy petition is filed against you.</li>
            <li>
              Where you are unable to pay any other party in accordance with
              article 7 of the law No 075/2021 or 06 /12/2021 relating to
              insolvency in Rwanda.
            </li>
            <li>
              you fail to disclose or provide false, misleading, or incorrect
              information in your application for device finance or during the
              Tenor of this Agreement.
            </li>
            <li>
              where you default in the performance or observance of any other
              term, condition or covenant or performance of any other obligation
              hereunder and such breach or default continues un-remedied within
              ten days of the receipt of a notice of the default, and
            </li>
            <li>
              You port your phone number in respect of the Device to a network
              other than MTN prior to the settlement of all your repayments.
            </li>
          </ul>
        </div>
        <p>
          You will be in default of this Agreement if any one of the following
          events (referred to as an "Event of Default") occurs:
        </p>
        <h1 className="text-lg font-bold mb-2 mt-4">
          Remedies in the Event of Default.
        </h1>
        <p>
          Upon an event of default, we have the right to exercise any one or
          more of the following remedies:
        </p>
        <div className="pl-10">
          <ul className="list-disc">
            <li>
              Disable the Device which will prevent you from using any of the
              device functionality;
            </li>
            <li>
              Require you to immediately pay the outstanding unpaid amount
              financed.
            </li>
            <li>Require you to pay us the costs of collection; and</li>
            <li>
              deposit enough funds in your Momo Account equivalent to the
              monthly Bundle Price and then follow the instructions in the FAQ
              document provided to you by the sales representative.
            </li>
            <li>
              Deducting the outstanding unpaid amount from other phone numbers
              registered on the same national ID/Passport of your initial phone
              number used during MTN contractual agreement of the MTN device
              financing with Distributor.
            </li>
          </ul>
        </div>
      </div>
      <div>
        <h1 className="text-lg font-bold mb-2 mt-4">Ownership of Device.</h1>
        <p>
          The Distributor shall have legal ownership of the Devices until full
          and final payment by you to the Distributor by payment of the Bundle
          Price when due. Legal title to the Device shall pass to you only after
          making full and final payment to the Distributor including any charges
          in respect of the Device.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">
          Non-Portability of Device
        </h1>
        <p>
          You hereby agree that you shall not port the Device phone number to
          another telecommunications network operator until you have fully
          discharged all your obligations to the Distributor and MTN under this
          Agreement. Failure to comply will result in your phone being disabled
          by MTN.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">Indemnity.</h1>
        <p>
          You are responsible for any and all liabilities, losses or damages,
          whether imposed on you or us, which are directly or indirectly
          attributable to you or any of your acts or omissions while the Device
          is in your possession. This provision shall survive the termination of
          this Agreement. To the extent permitted by law, you will reimburse us
          for any expenses we incur in connection with the defense of any claim
          or action you are responsible for under this Agreement including but
          not limited to, actual and reasonable attorney fees.
        </p>
      </div>
      <div>
        <h1 className="text-lg font-bold mb-2 mt-4">
          Governing Law and Jurisdiction.
        </h1>
        <p>
          This Agreement and any claim, dispute or cause of action (whether in
          contract, tort or otherwise) based upon, arising out of or relating to
          this Agreement and the transactions contemplated hereby shall be
          governed by the laws of the Republic of Rwanda and referred to the
          courts of the Republic of Rwanda.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">Additional Terms.</h1>
        <p>
          The Distributor may, at any time, without your consent, assign or
          transfer this Agreement to an affiliate or third party. You may not
          assign this Agreement or any interest therein without the prior
          written consent of the Distributor. We will check your credit record
          with a credit reporting bureau and your customer data records with MTN
          and you hereby consent to such processing, and you may not be able to
          purchase a Device under this Agreement if you do not meet the
          Distributor applicable credit standards and criteria.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">
          Assignment and Disclosure of Information.
        </h1>
        <p>
          You consent irrevocably to any future transfer and assignment by us of
          the loan, whether as part of a loan transfer scheme or otherwise. You
          also authorize us, that in the event of default, to disclose the
          outstanding balance due on the Devices and means of contacting you to
          credit reference agencies and collection agencies. You also authorize
          us and our partners to process your payment transaction and other data
          as part of this agreement.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">
          Customer Representations and Warranties.
        </h1>
        <p>You represent and warrant as follows:</p>
        <div className="pl-10">
          <ul className="list-disc">
            <li>
              (i) The information given to us verbally, via email or other
              electronic medium or in other form of writing as well as documents
              submitted in support of the Device financing are true, accurate
              and complete. You are solely responsible for the correctness of
              such information. We shall not be liable for relying on any
              documentation provided by you and also accept no liability for
              consequences arising out of any erroneous, incorrect or incomplete
              information supplied by you. If you suspect that there is an error
              in the information you have provided to us, you shall promptly
              inform us accordingly without undue delay. We will endeavor to
              correct the error whenever and wherever possible on a 'best
              effort' basis but shall have no liability arising therefrom.
            </li>
          </ul>
        </div>
        <p>
          This offer is subject to verification checks. In the event that your
          application is unsuccessful, your documents shall be returned to you.
        </p>

        <h1 className="text-lg font-bold mb-2 mt-4">
          Memorandum of Acceptance
        </h1>
        <p>
          I hereby accept the terms and conditions contained in this
          Subscription Agreement for Device Financing.
          <br />
          <br />I have read and understood the statements above and my signature
          hereunder or the One Time Password (OTP) that I have given to the
          sales representative/agent represents my true and authentic consent
          and is evidence of my agreement to be bound by the terms of this
          Agreement between myself, MTN and the Distributor.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditionContent;
