import React, { useState, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  InputRef,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Stack, useMediaQuery, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerAction } from "../../../store/customer/actions";
import {
  handleNextStep,
  handlePreviousStep,
} from "../../../store/layout/actions";
import PhoneInput from "react-phone-input-2";
import "./address.css";
import { relationship } from "../../../assets/data/relationship";

const { Option } = Select;

const formItemLayout = {
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const WitnessForm = (props: any) => {
  const [form] = Form.useForm();
  const { wareHouse, customer, auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const address =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.address;
  const nationalId =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo?.nationalIDDoc;
  const momoStatement =
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc &&
    wareHouse?.createdCart?.data?.customer?.subscriptionInfo
      ?.MomoStatementDoc[0];

  const [selectedCountry, setSelectedCountry] = useState("250");

  let indexBrand = 0;

  const [items, setItems] = useState([...relationship]);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    setItems([...items, name || `New item ${indexBrand++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onFinish = async (values: any) => {
    if (!values?.witness) {
      notification.info({ message: "Add Other Witness" });
      return;
    }

    const others: Array<any> = values?.witness?.map((item: any) => {
      return {
        ...item,
      };
    });
    delete values.witness;
    // others.push(values);

    // Check if any of the witness relationships is "mudugudu"
    const hasMuduguduRelationship = others?.some(
      (witness: any) => witness?.relationship?.toLowerCase() === "mudugudu"
    );

    if (!hasMuduguduRelationship) {
      notification.info({
        message: "Please make sure one of the witnesses is Mudugudu.",
      });
      return;
    }

    if (wareHouse?.createdCart?.data?.customer?._id && address && auth?.token) {
      await updateCustomerAction(
        auth?.token,
        wareHouse?.createdCart?.data?.customer?._id,
        {
          subscriptionInfo: {
            address: address,
            witnessInfo: others,
            nationalIDDoc: nationalId,
            MomoStatementDoc: [momoStatement],
          },
        }
      )(dispatch);
      await handleNextStep(2)(dispatch);
    } else {
      notification.info({ message: "Missing Address Details" });
    }
  };

  const handlePrevious = async () => {
    await handlePreviousStep(2)(dispatch);
  };

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const NotTablet = useMediaQuery("(min-width: 1536px)");

  return (
    <div className="lg:max-2xl:h-56 h-full overflow-y-auto p-4">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{}}
        style={{ maxWidth: "100%" }}
        scrollToFirstError
        className="relative"
      >
        <Form.List
          name="witness"
          {...formItemLayout}
          initialValue={
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo &&
            wareHouse?.createdCart?.data?.customer?.subscriptionInfo
              ?.witnessInfo?.length !== 0
              ? [
                  ...wareHouse?.createdCart?.data?.customer?.subscriptionInfo
                    ?.witnessInfo,
                ]
              : [{}, {}]
          }
        >
          {(fields, { add, remove }) => {
            const addButtonDisabled = fields.length >= 4;
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <div className="ml-5 lg:max-2xl:ml-0 lg:max-2xl:-mt-4">
                    <p className="text-[#0F0F47] text-base font-normal">
                      Witness: {key + 1}
                    </p>
                    <div className="grid grid-cols-3 lg:max-2xl:gap-1 mt-2 lg:max-2xl:mt-0 ">
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        className="w-64"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            Full Name
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Names are required!",
                          },
                        ]}
                      >
                        <Input className="pl-4 h-[52px] w-[320px] lg:max-2xl:w-[190px] lg:max-2xl:h-[42px] bg-[#EFF0F6] border-none" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "relationship"]}
                        className="w-[300px] "
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            RelationShip
                          </span>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Relationsip is required!",
                          },
                        ]}
                      >
                        {/* <Select defaultValue={""}>
                          {relationship?.map((item: any, index: any) => (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select> */}
                        <Select
                          showSearch
                          //   style={{ width: 300 }}
                          placeholder="Select/Add Brand"
                          // defaultValue={dataToUpdate?.brand}
                          // value={dataToUpdate?.brand}
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space style={{ padding: "0 8px 4px" }}>
                                <Input
                                  placeholder="Add New"
                                  ref={inputRef}
                                  // value={name}
                                  onChange={onNameChange}
                                />
                                <Button variant="contained" onClick={addItem}>
                                  {" "}
                                  <PlusOutlined />
                                </Button>
                              </Space>
                            </>
                          )}
                          options={items.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "phone"]}
                        className="xw-[370px] xw-72 lg:max-2xl:w-72 lg:max-2xl:-mt-2"
                        label={
                          <span className="text-[#0F0F47] text-base font-medium">
                            Phone
                          </span>
                        }
                        initialValue={props?.dataToUpdate?.phone}
                        rules={[
                          {
                            validator: (_, value) => {
                              const phoneNumberLength = value
                                ? value.replace(/\D/g, "").length
                                : 0;
                              if (selectedCountry?.slice(0, 3) === "250") {
                                if (phoneNumberLength === 12) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Phone Number should be exactly 9 Digits."
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          onChange={handleCountryChange}
                          country={"rw"}
                          buttonStyle={{
                            height: NotTablet ? "50px" : "35px",
                            border: "none",
                            borderRight: "3px solid #fff",
                            backgroundColor: "#EFF0F6",
                          }}
                          inputStyle={{
                            height: NotTablet ? "50px" : "35px",
                            width: NotTablet ? "100%" : "100%",
                            backgroundColor: "#EFF0F6",
                            border: "none",
                          }}
                          containerClass="phone-container"
                          placeholder="+250 780 000 000"
                        />
                      </Form.Item>

                      {key >= 2 && (
                        <div>
                          <button
                            onClick={() => remove(name)}
                            className="  rounded-md mt-10 shadow gap-x-2 py-2 w-fit px-3 border text-red-500"
                          >
                            <MinusCircleOutlined className=" my-auto" />
                          </button>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="flex">
                  <Form.Item className=" flex">
                    <Button
                      onClick={() => !addButtonDisabled && add()}
                      className="shadow !m-0 justify-between !items-center text-[#2943D6]"
                      disabled={
                        wareHouse?.createdCart?.data?.isSubscriptionSubmitted
                      }
                    >
                      <PlusOutlined />
                    </Button>{" "}
                  </Form.Item>
                  <span className="font-normal pt-1 pl-4 text-base text-[#0F0F47]">
                    Add more witness
                  </span>
                </div>
              </>
            );
          }}
        </Form.List>
        <div className="flex gap-2 justify-end mr-8 -mt-16 ">
          <div className="flex items-center space-x-2">
            <LoadingButton
              style={{
                padding: "6px",
                borderColor: "red",
                color: "red",
              }}
              variant="outlined"
              loading={customer?.isFetching}
              onClick={props?.removeCustomer}
            >
              {"Discard"}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              style={{
                padding: "6px",
                width: "80px",
              }}
              loading={customer?.isFetching}
              onClick={handlePrevious}
            >
              {`Previous`}
            </LoadingButton>
            <LoadingButton
              type="submit"
              variant="contained"
              style={{
                padding: "6.5px",
                width: "80px",
              }}
              loading={customer?.isFetching}
            >
              {"Next"}
            </LoadingButton>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default WitnessForm;
