import React from "react";
import { Modal } from "antd";

const NotificationModal: React.FC<{
  openModal: boolean;
  // cancel: () => void;
  title: string;
  content: any;
}> = ({
  openModal,
  title,
  content,
  //  ,cancel
}) => {
  return (
    <Modal
      open={openModal}
      footer={null}
      // onCancel={cancel}
    >
      <div>
        <h2 className="text-xl font-medium pb-2">{title}</h2>
        <div>{content}</div>
      </div>
    </Modal>
  );
};

export default NotificationModal;
