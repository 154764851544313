import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "EmployeeLibrary",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    agentShop: null,
    agentTransaction: null,
    isFetchingShop: false,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state,action){
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setUpdated(state, action) {
      state.updated = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    // setDeleted(state, action) {
    //   state.updated = action.payload;
    // },
    
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsFetchingShop(state, action) {
      state.isFetchingShop = action.payload;
    },
    setAgentTransaction(state, action) {
      state.agentTransaction = action.payload;
    },
    setAgent(state, action) {
      state.agentShop = action.payload;
    },
    
  },
});

export const myEmployeeActions = mySlice.actions;

export default mySlice.reducer;
