import DataActivationAndTrafficCard from "../DataActivationAndTrafficCard";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShopLineChart from "../../../graphy/LineChart";
import { useSelector } from "react-redux";
import { calculatePercentageChange } from "../../../../utils/converter";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const TotalRevenueAndDataActivation = () => {
  const { posOverView } = useSelector((state: any) => state);
  const todayRevenue =
    posOverView?.todayRevenueStats?.data?.revenue?.currentDate?.revenueTotal;
  const yesterdayRevenue =
    posOverView?.todayRevenueStats?.data?.revenue?.comparedDate?.revenueTotal;

  const percentageChange = calculatePercentageChange(
    todayRevenue,
    yesterdayRevenue
  );

  return (
    <div className="flex flex-wrap gap-y-1 md:gap-x-1 xl:h-1/2">
      <div className="relative bg-white bg-opacity-50 shadow rounded-md p-3 space-y-1 w-full lg:!w-1/2 text-xs">
        <p className="font-semibold text-lg">Total revenue</p>
        <div className="absolute top-0 right-3">
          <p
            className={`font-medium text-base ${
              percentageChange?.type === "increase"
                ? "text-green-500"
                : percentageChange?.type === "decrease"
                ? "text-red-500"
                : "text-blue-900"
            }`}
          >
            {percentageChange?.type === "increase" ? (
              <ArrowDropUpIcon />
            ) : percentageChange?.type === "decrease" ? (
              <ArrowDropDownIcon sx={{fontSize:"30px"}}/>
            ) : (
              ""
            )}{" "}
            {`${percentageChange?.percentage}%`}
          </p>
        </div>
        <div className="xl:flex xflex-wrap gap-y-3 rounded-md px-3 py-1 h-[3d0vh]">
          <div className="xflex-1 flex  xl:flex-col justify-between xl:justify-evenly xpl-3 w-full xl:w-[200px] xl:space-y-3">
            <div className="text-[10px] flex xl:flex-col gap-2">
              <p className="font-medium text-base  text-[#030229]">Today <span className="xl:hidden">:</span></p>
              <p className="font-medium text-base text-[#030229d8]">{`RWF ${todayRevenue?.toLocaleString()}`}</p>
            </div>
            <div className="text-[10px] flex xl:flex-col gap-2">
              <p className="font-medium text-base text-[#030229]">Yesterday <span className="xl:hidden">:</span></p>
              <p className="font-medium text-base text-[#030229d8]">{`RWF ${yesterdayRevenue?.toLocaleString()}`}</p>
            </div>
          </div>
          <div className="w-[95%] mt-2">
            <ShopLineChart />
            {/* <LineChart
                  data={data}
                  // prefix="Rwf "
                  // xtitle="Time"
                  // ytitle="Amount"
                  // thousands=","
                /> */}
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-warp bg-white bg-opacity-50 shadow rounded-md p-5 text-xs">
        <div className="flex-1 min-w-[90px] space-y-3">
          <p className="font-semibold text-base">Data Activation</p>
          <div className="space-y-4">
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Smartphone</h1>}
              amount={74}
              chartData={data}
            />
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Smart feature phone</h1> }
              amount={2}
              chartData={data}
            />
          </div>
        </div>
        <div className="flex-1 min-w-[200px] space-y-3">
          <p className="font-semibold text-base capitalize">
            Traffic Real time statistic
          </p>
          <div className="space-y-4">
            <DataActivationAndTrafficCard
              chartData={data}
              title={<h1 className="font-medium text-base text-[#030229d8]">Completed purchase</h1> }
              amount={74}
            />
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Pending Leads</h1> }
              amount={2}
              chartData={data}
            />
            <DataActivationAndTrafficCard
              title={<h1 className="font-medium text-base text-[#030229d8]">Discarded Leads</h1> }
              amount={2}
              chartData={data}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalRevenueAndDataActivation;
