import React, { useEffect } from 'react';
import { Divider, Table, Tag, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getShopCommissionAction } from '../../store/pos/actions';
import { getActiveShop } from '../../utils/converter';
const CommissionDevicesTable = (props:any) => {
  const { auth, appSettings, pos } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Customer',
      dataIndex: 'customer',
    },
    {
      title: 'Agent',
      dataIndex: 'agent',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      render: (text:any, record:any) => (
        <Tooltip title={`${record.specification}`}>
          <div>
            <p>{text?.model}</p>{" "}
            {text?.discount ? (
              <Tag color="blue"> {`Disc: ${text?.discount}%`}</Tag>
            ) : null}
            {text?.extendedWarranty ? (
              <Tag color="cyan">{`Ext: ${text?.extendedWarranty}%`}</Tag>
            ) : null}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Commission Amount',
      dataIndex: 'commission',
      render:(text:any) => (
        <p>{text.toLocaleString()}</p>
      )
    },
    {
      title: 'Total Amount',
      dataIndex: 'amount',
      render:(text:any) => (
        <p>{text.toLocaleString()}</p>
      )
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'date Of Payment',
      dataIndex: 'paidOn',
    },
    {
      title: 'Extended Warranty',
      dataIndex: 'extendedWarranty',
      render:(text:any) => (
        <p>{text}%</p>
      )
    },
  ];
  useEffect(() => {
    if (props?.selectedDate !== "") {
      auth?.token &&
      getShopCommissionAction(
          auth?.token,
          `${`?shop=${
            getActiveShop(auth?.user?.shop?.assigned)[
              appSettings?.selectedShopNumber
            ]?.shop?._id ?? auth?.user?.shop?.shopId
          }&status=paid&date=${props?.selectedDate}`}`
        )(dispatch);
    } else {
    auth?.token && getShopCommissionAction(auth?.token, `${(auth.user?.shop?._id || auth?.user?.shop?.shopId) &&
      `?shop=${getActiveShop(auth?.user?.shop?.assigned)[
        appSettings?.selectedShopNumber
      ]?.shop?._id ?? auth?.user?.shop?.shopId
      }&status=paid&limit`
      }`)(dispatch)}
  }, [dispatch])
  const commissionData = pos?.shopCommission?.data?.map((el: any) => {
    return {
      id: el?._id,
      data: el,
      date: el?.createdAt?.slice(0, 10),
      agent: el?.createdBy?.names,
      model: {
        model:
          el?.shopStockItem?.requestedItem?.product?.product?.model +
          "~" +
          el?.shopStockItem?.requestedItem?.product?.product?.type,
        extendedWarranty: el?.payment?.extendedWarranty,
        discount: el?.cart?.discount?.discountPerc,
      },
      specification: `${el?.shopStockItem?.requestedItem?.product?.specification
        ?.map((d: any) => d[1] && `${d[0]}: ${d[1]}`)
        ?.join(", ")}`,
      amount: el?.payment?.amount,
      paidOn: el?.paidOn?.slice(0,10),
      commission: el?.commissionFee,
      status: el?.status,
      extendedWarranty: el?.payment?.extendedWarranty,
      transactionId: el?.cart?.cartId,
      customer: el?.cart?.customer?.name,
    }
  }
  )
  return (
    <>
      <Table columns={columns} dataSource={commissionData} size="middle" loading={pos?.isFetching} />
    </>
  );
}

export default CommissionDevicesTable;