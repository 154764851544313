import TopNav from "../navitems/TopNavItems";
import { listManages } from "../../assets/data/pages";
import { ReactComponent as ForceLogo } from "../../assets/icons/Force_Logo.svg";
import React from "react";
import { useNavigate } from "react-router-dom";

const TopNavigationBar: React.FC<{
  pageId: number;
  pages: any;
  setOpenModal?: (state: boolean) => void;
}> = ({ pageId, pages, setOpenModal }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between">
      <ForceLogo
        width={100}
        className="h-8 w-20"
        onClick={() => navigate("/dashboard")}
      />
      <div className="flex items-center justify-between bg-white px-2 py-1 w-[70%] min-h-[40px] rounded-md capitalize text-xs font-semibold">
        <p className="">Shop Dashboard</p>
        {/* <POSRoutes /> */}
        <TopNav nav={listManages[pageId]} pages={pages} />
        {/* {auth?.user?.role?.toLowerCase() !== "sales-agent" && (
          <LoadingButton
            type="submit"
            variant="contained"
            onClick={() => setOpenModal && setOpenModal(true)}
          >
            Change Shop
          </LoadingButton>
        )} */}
      </div>
      <div></div>
    </div>
  );
};

export default TopNavigationBar;
