import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import WifiIcon from "@mui/icons-material/Wifi";
import profilePic from "../../../assets/images/profile.jpeg";
import AgentSaleKPICard from "./AgentSaleKPICard";
import { Badge } from "@mui/material";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { createShopStockCartAction } from "../../../store/wareHouse/actions";
import {
  calculatePercentageChange,
  getActiveShop,
} from "../../../utils/converter";
import {
  getAllCartsAction,
  getShopCommissionBalanceAction,
} from "../../../store/pos/actions";
import { myWareHouseActions } from "../../../store/wareHouse";
import { myCustomerActions } from "../../../store/customer";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Notification from "../../notification/Notification";
import { updateNotificationAction } from "../../../store/notification/actions";
import CommissionCard from "./CommissionCard";
import LoadingButton from "@mui/lab/LoadingButton";

const notification = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const UserDetailPanel = () => {
  const { auth, wareHouse, notifications, kpi, appSettings, pos, shop } =
    useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const [anchorElSettings, setAnchorElSettings] =
    React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElSettings);
  const openNotification = Boolean(anchorElNotification);
  const shopId =
    getActiveShop(auth?.user?.shop?.assigned)[appSettings?.selectedShopNumber]
      ?.shop?._id ?? auth?.user?.shop?.shopId;

  const shopInfo =
    getActiveShop(auth?.user?.shop?.assigned)[
      appSettings?.selectedShopNumber
    ] || shop?.myShop;
  const handleCreateSale = async () => {
    console.log("creting a sale...");
    auth?.token &&
      (await createShopStockCartAction(auth?.token, {
        shop:
          getActiveShop(auth?.user?.shop?.assigned)[
            appSettings?.selectedShopNumber
          ]?.shop?._id ?? auth?.user?.shop?.shopId,
      })(dispatch));
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSettings(null);
    setAnchorElNotification(null);
  };

  const updateNotification = async () => {
    await updateNotificationAction(auth.token, {})(dispatch);
  };

  React.useEffect(() => {
    auth?.token &&
      shopId &&
      getAllCartsAction(
        auth?.token,
        `?shop=${shopId}&status=pending`
      )(dispatch);
  }, [auth?.token, auth?.user?.shop?.assigned, dispatch, shopId]);

  React.useEffect(() => {
    const activeShop = getActiveShop(auth?.user?.shop?.assigned);
    auth?.token &&
      shopId &&
      getShopCommissionBalanceAction(auth?.token, shopId)(dispatch);
  }, [
    appSettings?.selectedShopNumber,
    auth?.token,
    auth?.user?.shop?.assigned,
    auth?.user?.shop?.shopId,
    dispatch,
    shopId,
  ]);
  React.useEffect(() => {
    if (wareHouse?.createdCart?.data?._id) {
      if (wareHouse?.isCartCreated) {
        navigate(`/pos/sale/${wareHouse?.createdCart?.data?._id}`);
      }
    }
  }, [navigate, wareHouse?.createdCart?.data?._id, wareHouse?.isCartCreated]);

  React.useEffect(() => {
    dispatch(myWareHouseActions.setCreatedCart(null));
    dispatch(myCustomerActions.setSelected(null));
  }, [dispatch]);
  const activeUserShop = getActiveShop(auth?.user?.shop?.assigned);

  const todayRevenue = kpi?.all?.data[0]?.currentDateRevenue;
  const comparedRevenue = kpi?.all?.data[0]?.comparedDateRevenue;

  const todayQuantity = kpi?.all?.data[0]?.currentDateQuantity;
  const comparedQuantity = kpi?.all?.data[0]?.comparedDateQuantity;

  const todayCustomer = kpi?.all?.data[0]?.currentDateCustomer;
  const comparedCustomer = kpi?.all?.data[0]?.comparedDateCustomer;

  const revenuePercentage = calculatePercentageChange(
    todayRevenue,
    comparedRevenue
  );

  const quantityPercentage = calculatePercentageChange(
    todayQuantity,
    comparedQuantity
  );

  const customerPercentage = calculatePercentageChange(
    todayCustomer,
    comparedCustomer
  );

  return (
    <div className="absolute flex flex-col items-center h-full w-[20vw] bg-white bg-opacity-50 shadow rounded-lg p-2">
      <div className="flex justify-between w-full py-2">
        <WifiIcon fontSize="medium" />
        <Tooltip title="Notifications">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={handleClickNotification}
          >
            <Badge
              badgeContent={
                notifications?.unReadNotification?.data?.length > 0 &&
                notifications?.unReadNotification?.total
              }
              color={
                notifications?.unReadNotification?.data?.length > 0
                  ? "error"
                  : "default"
              }
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "2px",
              }}
              onClick={updateNotification}
            >
              <NotificationsOutlinedIcon fontSize="medium" />
            </Badge>
          </IconButton>
        </Tooltip>
        {/* {notifications?.all?.data?.length > 0 && ( */}
        <Menu
          anchorEl={anchorElNotification}
          id="account-menu"
          open={openNotification}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={notification}
          defaultValue={3}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <h1 className="pl-4 text-xl font-bold">Notifications</h1>
          <Divider />
          <div className="h-[35rem] overflow-y-auto">
            {notifications?.all?.data?.map((notification: any, index: any) => (
              <Notification data={notification} />
            ))}
          </div>
        </Menu>
        {/* )} */}
      </div>
      <div className="flex flex-col items-center w-full space-y-1">
        <img
          className="w-[8vw] h-[8vw] object-cover rounded-full mr-1"
          alt="Remy Sharp"
          src={auth?.user?.picture || profilePic}
        />
        <p className="font-semibol text-[1.5vw] capitalize">
          {auth?.user?.names}
        </p>
        <div className="flex h-[4vh] w-full">
          <LoadingButton
            variant="contained"
            className="w-full"
            onClick={handleCreateSale}
            loading={wareHouse?.isFetching}
          >
            Sell
          </LoadingButton>
        </div>
      </div>

      <div className="w-full mt-6 ">
        <h3 className="font-bold text-sm">Agent Sales KPI</h3>
      </div>
      <div className="w-full mt-2 overflow-auto space-y-1 text-xs">
        {/* Total Sales */}
        <AgentSaleKPICard
          title="Total Sales"
          amount={`RWF ${todayRevenue?.toLocaleString() ?? 0}`}
          yesterdayAmount={`RWF ${comparedRevenue?.toLocaleString() ?? 0}`}
          percentage={revenuePercentage}
        />

        {/* Total Sales */}
        <AgentSaleKPICard
          title="Total Quantity"
          amount={`${todayQuantity ?? 0}/pcs`}
          yesterdayAmount={`${comparedQuantity ?? 0}/pcs`}
          percentage={quantityPercentage}
        />

        {/* Total Sales */}
        <AgentSaleKPICard
          title="Total Customers"
          amount={`${todayCustomer ?? 0} People`}
          yesterdayAmount={`${comparedCustomer ?? 0} People`}
          percentage={customerPercentage}
        />
        {/* Total Commissions */}
        {shopInfo?.shop?.isCommissionEnabled ? (
          <CommissionCard
            title={"Commission"}
            amount={
              pos?.isFetching ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                />
              ) : (
                pos?.commissionBalance?.data?.paidAmount?.toLocaleString()
              )
            }
            balance={pos?.commissionBalance?.data?.balance?.toLocaleString()}
            total={pos?.commissionBalance?.data?.totalAmount?.toLocaleString()}
            btnName={"View more"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default UserDetailPanel;
