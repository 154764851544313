import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../Modals/DeleteModal";
import { removeProductAction } from "../../store/wareHouseOut/actions";

const WhCreatTable = (props: any) => {
  const { auth, channel } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [rowToDelete, setRowToDelete] = useState("");

  const data: {
    _id: any;
    no: number;
    shop: any;
    address: any;
    model: any;
    specification: any;
    snumber: any;
    imei1: any;
    imei2: any;
    price: any;
    extendedWarranty: any;
    color: any;
    isSelected: any;
    visible: boolean;
    setVisible: (state: boolean) => void;
    token: string;
    dispatch: any;
    auth: any;
    isLoading: boolean;
    rowToDelete: string;
    setRowToDelete: (state: string) => void;
    requestedItem: any;
  }[] = [];

  const columns: ColumnsType<any> = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
    },
    {
      title: "Shop",
      dataIndex: "shop",
      key: "shop",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: "Models",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "SN/BarCode",
      dataIndex: "snumber",
      key: "snumber",
    },
    {
      title: "IMEI1",
      dataIndex: "imei1",
      key: "imei1",
      render: (text: string) => <p>{text || "N/A"}</p>,
    },
    {
      title: "IMEI2",
      dataIndex: "imei2",
      key: "imei2",
      render: (text: string) => <p>{text || "N/A"}</p>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text: string, record: any) => (
        <p>
          {record?.price?.find((obj: any) => obj?.isActive === true)?.value ||
            "N/A"}
        </p>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => {
        const deleteModal = () => {
          record?.setVisible(true);
        };
        const handleCancel = () => {
          record?.setVisible(false);
        };
        const deleteRequest = async () => {
          record?.token &&
            (await removeProductAction(
              record?.token,
              record?.rowToDelete,
              record?.requestedItem
            )(record?.dispatch));
          record?.setVisible(false);
        };
        return (
          <>
            <IconButton aria-label="delete" size="small">
              <DeleteIcon
                fontSize="inherit"
                onClick={() => {
                  record?.setRowToDelete(record?._id);
                  deleteModal();
                }}
              />
            </IconButton>
            <DeleteModal
              visible={record?.visible}
              onOk={deleteRequest}
              isLoading={record?.isLoading}
              onCancel={handleCancel}
              itemName=" "
            />
          </>
        );
      },
    },
  ];

  channel?.warehouseOutItem?.data?.forEach((el: any, index: number) => {
    data?.push({
      _id: el?._id,
      no: index + 1,
      shop: el?.stockRequest?.shopId?.name,
      address: el?.shop?.address,
      model: el?.requestedItem?.product?.product?.model,
      specification: el?.requestedItem?.product?.specification,
      price: el?.requestedItem?.product?.prices,
      extendedWarranty: el?.requestedItem?.product?.extendedWarranty,
      snumber: el?.wareHouseItem?.serialNumber,
      imei1: el?.wareHouseItem?.imei1,
      imei2: el?.wareHouseItem?.imei2,
      color: "",
      isSelected: el?.isSelected,
      setVisible: setVisible,
      visible: visible,
      token: auth?.token,
      dispatch: dispatch,
      auth: auth,
      isLoading: channel?.isFetching,
      rowToDelete: rowToDelete,
      setRowToDelete: setRowToDelete,
      requestedItem: props?.selectedProductId,
    });
  });

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        style={{ width: "100%" }}
        loading={channel?.isFetching}
      />
    </div>
  );
};

export default WhCreatTable;
