import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

const DetailsTabs = (props: any) => {
    return (
        <Box className="bg-white w-full rounded-lg">
        <Box className="flex justify-center">
          <Tabs value={props?.activeTab} onChange={props?.handleChange}>
            {props?.tabs?.map((tab: any, index: number) => (
              <Tab label={tab?.title} />
            ))}
          </Tabs>
        </Box>
        {props?.tabs?.map((tab: any, index: number) => (
          <TabPanel value={props?.activeTab} index={index}>
            {tab?.component}
          </TabPanel>
        ))}
      </Box>
    );
}

export default DetailsTabs;