import { Carousel, Form } from "antd";
import SearchBar from "../SearchBar";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { getActivePrice } from "../../utils/converter";
import BarcodeScannerModal from "../BarcodeScannerModal";
import { useState } from "react";
import { ReactComponent as SearchItemImage } from "../../assets/images/Scan to sell Option 2.svg";
import { searchCartItemBySerialNumberAction } from "../../store/repair/actions";
import { myRepairActions } from "../../store/repair";

const RepairProductSearch: React.FC<{ isView: boolean }> = ({ isView }) => {
  const { auth, wareHouse, repair } = useSelector((state: any) => state);
  const [scannedCode, setScannedCode] = useState<string>();
  const [newSearch, setNewSearch] = useState<boolean>(false);
  const dispatch = useDispatch();
  const productData = isView
    ? repair?.repairSearchResult?.cartItemId
    : repair?.repairSearchResult;
  console.log("Product", productData);

  const isFound = repair?.repairSearchResult;
  // repair?.searchResult?.data?.product?.status !== "sold";

  const onFinish = async (values: any) => {
    auth?.token &&
      (await searchCartItemBySerialNumberAction(
        auth?.token,
        values?.query
      )(dispatch));
  };

  const getCode = (code: string) => {
    setNewSearch(false);
    onFinish({ query: code });
    setScannedCode(code);
  };
  const handleChange = (e: any) => {
    setNewSearch(true);
  };

  const discardSearchResult = () => {
    dispatch(myRepairActions.setRepairSearchResult(null));
  };

  const itemPrice = getActivePrice(
    productData?.shopStockItem?.requestedItem?.product?.prices
  );

  return (
    <div
      className={`relative bg-white rounded-lg text-black pl-4 pr-4 pt-2 pb-2 leading-none box-border`}
    >
      <div className="flex flex-col justify-between h-[calc(100%-50px)]">
        {!isFound && (
          <div className="flex flex-col">
            <div className="flex justify-center gap-x-2">
              <Form onFinish={onFinish}>
                <Form.Item
                  name="query"
                  className="rounded-lg"
                  // label="Enter serial number"
                  initialValue={scannedCode}
                >
                  <SearchBar
                    isLoading={wareHouse.isFetching}
                    scannedCode={newSearch ? "" : scannedCode}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
              <BarcodeScannerModal getCode={getCode} />
            </div>
            <div className="flex justify-center">
              <SearchItemImage className="h-44" />
            </div>
          </div>
        )}

        {isFound && (
          <div className="relative flex-1">
            <div className="flex gap-2 p-3 pb-4 30px text-[1vw]">
              <div style={{ width: "7rem" }}>
                <Carousel autoplay>
                  {productData?.shopStockItem?.requestedItem?.product?.product?.images?.map(
                    (im: any, index: number) => (
                      <div key={index}>
                        <img src={im} width={"100%"} alt="" />
                      </div>
                    )
                  )}
                </Carousel>
              </div>
              <div className="flex gap-x-4">
                <div className="flex flex-col gap-y-1">
                  <p className="text-[1.2vw] font-bold">
                    {
                      productData?.shopStockItem?.requestedItem?.product
                        ?.product?.model
                    }
                  </p>
                  <p>
                    {
                      productData?.shopStockItem?.requestedItem?.product
                        ?.product?.type
                    }
                  </p>

                  <div className="flex flex-col gap-2">
                    {productData?.shopStockItem?.requestedItem?.product?.specification?.map(
                      (item: string, _index: number) => (
                        <span className="text-[1vw]">
                          {item[0]}:<b>{item[1]}</b>
                        </span>
                      )
                    )}
                  </div>
                </div>

                <div className="border-t border-gray-200 mt-3 pt-2">
                  <p>
                    <b>Extended Warranty :</b>{" "}
                    {`${productData?.shopStockItem?.requestedItem?.product?.extendedWarranty}%`}
                  </p>
                  <p>
                    <b>In Shop :</b>
                    {productData?.data?.isInShop ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute top-1 w-full flex gap-x-1 !box-border px-2">
              <p className="flex-1 text-right text-[1.4vw] font-bold">{`RWF ${itemPrice?.toLocaleString()}`}</p>
            </div>
          </div>
        )}

        {isFound && (
          <div className="absolute bottom-2 right-2 gap-4">
            <div className="flex flex-wrap gap-x-2 ">
              <LoadingButton
                type="submit"
                variant="contained"
                onClick={discardSearchResult}
                // disabled={!productData?.data?.isInShop}
                // startIcon={<AddIcon />}
                // sx={{ minWidth: "100%" }}
                // loading={channel?.isFetching}
                disabled={isView}
              >
                Clear Search
              </LoadingButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairProductSearch;
