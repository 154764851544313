import { Checkbox, Divider, notification } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { loginAction } from "../store/auth/authActions";
import { useSelector, useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ForceLogoFull from "../assets/icons/Force_Logo.svg";
// import ForceLogoFull from "../assets/icons/Force_Logo_SVG.svg";

export default function App() {
  const navigate = useNavigate();
  const { auth } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e?: any) => {
    loginAction({ email, password })(dispatch);
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    auth?.token && navigate("/dashboard");

    !auth?.isAuth &&
      auth.error &&
      notification.warning({ message: auth?.error?.error });
  }, [auth, navigate]);
  return (
    <div className="h-full font-exo py-4 text-black flex justify-center flex-1 lg:flex-none flex-col">
      <div className=" m-auto w-[450px] flex flex-col justify-center space-y-4">
        <div className="bg-slate-50 bg-opacity-50 px-[10%] pt-[7%] space-y-4 shadow rounded-lg">
          {/* <img src={ForceLogoFull} alt="" className=" h-10 mx-auto" /> */}
          <h1 className="text-[28px] text-center text-black">Samphone</h1>
          <section>
            <div className="font-doublebold text-lg xl:text-xl">
              Hello Samphone heroes, 👋🏿
            </div>
            <div className="text-gray-300 text-sm xl:text-lg capitalize">
              Enter the information provided to you
            </div>
          </section>
          <div className="">
            <label className="block mb-1 text-md font-light ">Email</label>
            <input
              className="w-full border border-gray-200 bg-white rounded-xl px-3 py-1"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="">
            <label className="block mb-1 text-md font-light font-sans ">
              Password
            </label>
            <div className="relative">
              <input
                className="border w-full border-gray-200 bg-white rounded-xl px-3 py-1"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
              />
              <button
                className="absolute top-0 right-0 p-2 rounded-lg text-sm"
                onClick={handlePasswordToggle}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <Checkbox className="text-left font-bold">Remember me</Checkbox>
            <p className=" place-content-end font-bold text-sm cursor-pointer text-blue-400">
              Forgot password
            </p>
          </div>
          <div className="flex justify-between">
            <LoadingButton
              className="w-full h-8 !rounded-xl !bg-[#7F66FA] !text-white"
              onClick={() => {
                // navigate("/home");
                handleLogin();
              }}
              loading={auth?.isFetching}
            >
              Login
            </LoadingButton>
          </div>
          <div className="text-center text-gray-300 text-sm uppercase py-2">
            version 0.2.1
          </div>
        </div>
        <Divider children={"Or"} />
        <div className="">
          <LoadingButton
            className="w-full h-12 text-white !rounded-xl !bg-white !bg-opacity-50"
            onClick={() => {
              navigate("/login/scan");
            }}
            loading={false}
          >
            Login By Scan
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
