import React from "react";

function Notification({ data }: { data: any }) {
  console.log("Notification", data);
  return (
    <div
      className={`${
        data.read ? "bg-red-300 mx-2" : "bg-white"
      } w-full p-5 border-border-dark dark:border-white border-b-[0.5px] min-w-[392px]`}
      key={data.id}
    >
      <div className="flex flex-row justify-between align-center gap-x-[20px] ">
        <img
          src={
            data.profile ||
            "https://www.pci.uni-heidelberg.de/bpc2/images/faces/dummy.jpg"
          }
          alt="oldMan"
          className="rounded-[1000px] w-[40px] h-[40px] object-cover cursor-pointer"
        />

        <div className="flex flex-col w-full gap-[5px] cursor-pointer">
          <p className="font-bold">{data.message}</p>
          <p className="text-[#111827] text-[12px]">{data.action}</p>
          <p className="text-[12px]">{data.createdAt}</p>
        </div>
      </div>
    </div>
  );
}

export default Notification;
