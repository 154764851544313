import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllServiceRequestedOut = async (
  token: string,
  query?: string
) => {
  return HttpRequest.get(`${SERVER_URL}/stockreq${query}`, token);
};

export const getAllServiceStockOut = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstock${query}`, token);
};
export const getAllWarehouseOutItem = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/shopstockitem?${query}`, token);
};

export const getOneServiceRequestedOut = async (id: string, token: string) => {
  return HttpRequest.get(`${SERVER_URL}/stockreqitem/one/${id}`, token);
};

export const createServiceStockOut = async (data: any, token: string) => {
  return HttpRequest.post(`${SERVER_URL}/shopstock`, data, token);
};

export const updateStockRequestStatus = async (
  itemId: string,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/stockreq/one/${itemId}`,
    data,
    token
  );
};
export const getAllServiceWarehouse = async (token: string, query?: string) => {
  return HttpRequest.get(`${SERVER_URL}/warehouse${query}`, token);
};

export const updateStockOutService = async (
  itemId: any,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shopstock/additem/${itemId}`,
    data,
    token
  );
};

export const updateOneStockOutDetailsService = async (
  itemId: any,
  data: any,
  token: string
) => {
  return await HttpRequest.update(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    data,
    token
  );
};

export const removeProductService = async (token: string, id: string) => {
  return HttpRequest.delete(`${SERVER_URL}/shopstockitem/one/${id}`, token);
};

export const updateReceivedProductStatus = async (
  token: string,
  itemId: string,
  data: any
) => {
  return HttpRequest.update(
    `${SERVER_URL}/shopstock/one/${itemId}`,
    data,
    token
  );
};
