import { getOneCustomerByQueryAction } from "../../../store/customer/actions";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ReactComponent as AddCustomerPlaceholderImage } from "../../../assets/images/EnterCustomerInfoOption.svg";
import { myCustomerActions } from "../../../store/customer";
import SearchBarV2 from "../../elements/SearchBarV2";
import {
  getShopStockCartAction,
  updateShopStockCartDetailsAction,
} from "../../../store/wareHouse/actions";
import { useParams } from "react-router-dom";
import NewCustomerWithSubscription from "../../forms/NewCustomerWithSubscription";
import { myLayoutActions } from "../../../store/layout";

const AddCustomerWithSubscription = () => {
  const { auth, customer, wareHouse } = useSelector((state: any) => state);
  const [isModalOpen, setIsModalOpen] = useState(
    !customer?.isFetching && wareHouse?.createdCart?.data?.customer
  );

  const [customerQuery, setCustomerQuery] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  const dispatch = useDispatch();
  const { cartId } = useParams();

  useEffect(() => {
    wareHouse?.createdCart?.data?.customer && setIsModalOpen(true);
  }, [wareHouse?.createdCart?.data?.customer]);

  useEffect(() => {
    const addCustomer = async () => {
      customer?.new?.data &&
        (await getOneCustomerByQueryAction(
          auth?.token,
          `?phone=${customer?.new?.data?.phone}`
        )(dispatch));
      dispatch(myCustomerActions.setNew(null));
      dispatch(myCustomerActions.setUpdated(null));
      dispatch(myCustomerActions.setSearchResult(null));
    };
    addCustomer();
  }, [auth?.token, customer?.new, customer?.searchResult, dispatch]);

  const handleCancel = async () => {
    auth?.token &&
      (await updateShopStockCartDetailsAction(auth?.token, cartId, {
        customer: null,
      })(dispatch));
    setIsModalOpen(false);
  };

  useEffect(() => {
    auth?.token && getShopStockCartAction(auth?.token, cartId)(dispatch);
  }, [auth?.token, cartId, customer?.updated, dispatch]);

  const onSearch = async (e: any) => {
    e.preventDefault();
    auth?.token &&
      (await getOneCustomerByQueryAction(
        auth?.token,
        `?phone=${customerQuery}`
      )(dispatch));
  };

  const handleOnSearchChange = (e: any) => {
    setCustomerQuery(e.target.value?.replace("+", "")?.trim());
  };

  return (
    <>
      <div>
        {/* customer info */}
        {!isModalOpen && (
          <>
            <div className="flex items-center justify-center py-2 space-x-2">
              <div className="rounded-lg">
                <form method="get" onSubmit={onSearch}>
                  <SearchBarV2
                    placeholder="Input customer number"
                    onChange={handleOnSearchChange}
                    name="customer"
                    isLoading={customer.isFetching}
                  />
                </form>
              </div>
              <button
                onClick={() => {
                  setIsModalOpen(true);
                  dispatch(myLayoutActions.setIsNext(0));
                }}
                className="shadow py-2 px-4 border bg-slate-300 rounded-md"
              >
                <PlusOutlined className="m-0 p-0" />
              </button>
            </div>
            <div className="flex justify-center">
              <AddCustomerPlaceholderImage className="h-44" />
            </div>
          </>
        )}

        {isModalOpen && (
          <div className="w-full px-4 py-1">
            <div className="flex justify-between mb-2"></div>
            {
              <NewCustomerWithSubscription
                dataToUpdate={wareHouse?.createdCart?.data?.customer}
                isBusiness={activeTab !== 1}
                onCancel={handleCancel}
                action={"save"}
              />
            }
          </div>
        )}
      </div>
    </>
  );
};

export default AddCustomerWithSubscription;
