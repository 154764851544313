import KnowledgeOutlet from "./pages/outlet";
import KnowledgeHome from "./pages/home";
import KnowledgeManagement from "./pages/manage";
import AddKnowledgePost from "./pages/addPost";
import EditKnowledgePost from "./pages/editPost";
import ViewKnowledgePost from "./pages/viewPost";
import AddNewCategory from "./pages/addCategory";
import EditCategory from "./pages/editCategory";

import KnowledgeReducer from "./redux/";

export const Knowledge = {
  Screens: {
    Outlet: KnowledgeOutlet,
    Home: KnowledgeHome,
    Management: KnowledgeManagement,
    AddPost: AddKnowledgePost,
    EditPost: EditKnowledgePost,
    ViewPost: ViewKnowledgePost,
    AddCategory: AddNewCategory,
    EditCategory,
  },
  Redux: KnowledgeReducer,
};
