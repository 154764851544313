import { KnowledgeActions } from ".";
import {
  getAllPostsService,
  getSinglePostService,
  getCategoriesServices,
  getSingleCategoryService,
  createNewCategoryService,
  createNewPostService,
  editPostService,
  editCategoryService,
  getAllPricelistService,
  getAllAgentsService,
} from "./services";

export const getAllPosts = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getAllPostsService(token, query);
      if (res?.status === 200) {
        if (query?.includes("admin")) {
          dispatch(KnowledgeActions.setAdminPosts(res?.data));
        } else {
          dispatch(KnowledgeActions.setAllPosts(res?.data));
        }
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getSinglePost = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getSinglePostService(token, id);
      if (res?.status == 200) {
        dispatch(KnowledgeActions.setSinglePost(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getCategories = (token: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getCategoriesServices(token);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setCategories(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getSingleCategory = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getSingleCategoryService(token, id);
      if (res?.status == 200) {
        dispatch(KnowledgeActions.setSingleCategory(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const createNewCategory = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await createNewCategoryService(token, data);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setCategories(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const createNewPost = (token: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await createNewPostService(token, data);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const editPost = (token: string, id: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await editPostService(token, id, data);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setIsFetching(false));
        dispatch(KnowledgeActions.setDataChanged(Math.random()));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const editCategory = (token: string, id: string, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await editCategoryService(token, id, data);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setIsFetching(false));
        dispatch(KnowledgeActions.setDataChanged(Math.random()));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const deleteCategory = (token: string, id: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await editCategoryService(token, id, { isDeleted: true });
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setIsFetching(false));
        dispatch(KnowledgeActions.setDataChanged(Math.random()));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getPricelist = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getAllPricelistService(token, query);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setAllPricelist(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};

export const getAgents = (token: string, query: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(KnowledgeActions.setIsFetching(true));
      const res = await getAllAgentsService(token, query);
      if (res?.status === 200) {
        dispatch(KnowledgeActions.setAllAgents(res?.data));
        dispatch(KnowledgeActions.setIsFetching(false));
      }
      dispatch(KnowledgeActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
      return null;
    }
  };
};
