import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import PaymentsIcon from "@mui/icons-material/Payments";
import { useNavigate } from "react-router-dom";

const CommissionCard: React.FC<{
  title?: string;
  amount?: String | number;
  total?: string | number;
  balance?: any;
  btnName?:any;
}> = ({ title, amount, total, balance ,btnName}) => {
  const navigate=useNavigate();
  const handleNavigate=(()=>{
    navigate("/pos/commission")
  })
  return (
    <div className="relative flex flex-col bg-white rounded-md p-[0.5vw] text-[1vw] leading-[3vh]">
      <div>
       <div className="md:flex justify-between"><h2 className='text-[#030229] text-base font-medium'>{title}</h2> <button className="text-xs border border-gray-400 w-16 rounded-[3px] text-center text-[#4b39d4]" onClick={handleNavigate}>{btnName}</button></div> 
        <div className="divide-y">
          <div>
          <h2 className="text-center text-xs text-[#030229]">Balance</h2>
          <p className="text-center text-lg font-bold">RWF {balance} </p>
          </div>
          <div className="pt-2">
            <div className="flex justify-between">
              <p className="text-base ">Total Earned</p>
              <p className="text-center text-sm text-[#030229]"> RWF {total}</p>
            </div>
            <div className="flex justify-between">
              <p className=" text-base ">Paid amount</p>
              <p className="text-center text-sm text-[#030229]"> RWF {amount}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionCard;
