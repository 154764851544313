import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "inventory",
  initialState: {
    isFetching: false,
    all: { data: [] },
    selected: null,
    selectedDeliveryNote: null,
    attributes: null,
    new: null,
    updated: null,
    query: null,
    filters: null,
    allShopStock: null,
    stockItems: null,
    searchBySn: null,
  },
  reducers: {
    setAll(state, action) {
      state.all = action.payload;
    },
    setAttributes(state, action) {
      state.attributes = action.payload;
    },
    setQuery(state, action) {
      state.query = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setAllShopStock(state, action) {
      state.allShopStock = action.payload;
    },
    setStockItems(state, action) {
      state.stockItems = action.payload;
    },
    setSearchBySn(state, action) {
      state.searchBySn = action.payload;
    },
  },
});

export const myInventoryActions = mySlice.actions;

export default mySlice.reducer;
